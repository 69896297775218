import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { User, UniModel, PAManagingOrgan } from '../../types/types';
import { MessageService, MessageType } from '../../services/message.service';
import { PermissionsService, Permission, PERMISSION_DELIMITER } from '../../services/permissions.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isUserManagedByMe } from '../../../app/utils/utils';


@Component({
  selector: 'app-user-list-tile',
  templateUrl: './user-list-tile.component.html',
  styleUrls: ['./user-list-tile.component.css']
})
export class UserListTileComponent implements OnInit {
  @Input() user: User;
  @Input() checked: boolean;
  @Output() onCheckClick: EventEmitter<any> = new EventEmitter();
  @Input() showCheckbox: boolean = false;
  loadingActivation = false;
  loadingPermission = false;
  newPermission: string;
  isDetailLoaded: boolean = undefined;
  userPermissions;
  isLoadingUpdateDistricts = false;
  isLoadingEditManagedBy = false;
  pams: PAManagingOrgan[] = [];
  autoCompletePams: UniModel[] = [];
  selectedPam: PAManagingOrgan;
  isEditMode = false;

  constructor(private messageService: MessageService,
    private permissionsService: PermissionsService,
    public dialog: MatDialog,
    private permService: PermissionsService,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  get activationToolTipText() { return this.user.activated ? 'Deaktitovať' : 'Aktivovať' }
  get hasUserAdminPermission() { return this.permissionsService.hasUserPermission(Permission.Admin); }
  get hasUserOfficerPermission() { return this.permissionsService.hasUserPermission(Permission.Officer); }
  get hasUserAuthAmbasPermission() { return this.permissionsService.hasUserPermission(Permission.AuthorityAmbassador); }
  get isRanger() { return this.permissionsService.hasUserPermission(Permission.Ranger, this.user) };
  get canEditTerritory() { return this.hasUserAdminPermission || this.hasUserAuthAmbasPermission || isUserManagedByMe(this.user, this.dataService.user) }
  allDistricts: UniModel[] = [];

  ngOnInit() {
    this.dataService.getDistrictOptions().then((data) => {
      this.allDistricts = data;
    });
  }

  onCheckedChange(state) {
    this.onCheckClick.emit({id: this.user.id, newState: state});
  }

  onSubmitNewDistricts(newDistricts: UniModel[]) {
    this.isLoadingUpdateDistricts = true;
    this.dataService.updateTerritoryScopeForUser(
      this.user.id,
      newDistricts.map(district => district.id)
    ).then(() => {
      this.isLoadingUpdateDistricts = false;
      this.user.territory_disctricts = newDistricts;
      this.messageService.replaceFirstMessage('Úspešne zmenené.', MessageType.SUCCESS, 0);
    });
  }

  onActivationIconClick() {
    this.loadingActivation = true;
    this.dataService.updateActivationStatusOfUser(this.user.id, !this.user.activated).then((success) => {
      this.loadingActivation = false;
      if (success) {
        this.user.activated = !this.user.activated;
        // this.messageService.clearMessages();
        this.messageService.replaceFirstMessage('Úspešne zmenené.', MessageType.SUCCESS, 0);
      }
    });
  }

  onOpen() {
    if (!this.isDetailLoaded) {
      this.isDetailLoaded = false;
      this.dataService.getUserById(this.user.id).then((userDetail: User) => {
        this.user = userDetail;
        this.userPermissions = this.permService.getAllPermissinosForUser(this.user).filter(perm => perm.checked);
        this.isDetailLoaded = true;
      });
    }
  }

  getClasses() {
    if (this.user.activated == false) {
      return 'disabled-bg';
    }
    if (isUserManagedByMe(this.user, this.dataService.user)) {
      return 'enabled-bg'
    } else {
      return 'not-managed-by-me-bg';
    }
  }

  onManagePermissionClick() {
    this.openDialog();
  }

  openDialog(): void {
    const options = this.permissionsService.getAllPermissinosForUser(this.user)
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '400px',
      data: {
        rangerName: this.user.name + ' ' + this.user.surname,
        options: options,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.loadingPermission = true;
        let arr = result.filter(perm => perm.checked);
        arr = arr.map(perm => perm.id);
        const newPerm = arr.join(PERMISSION_DELIMITER);
        this.dataService.updatePermissionOfUser(this.user.id, newPerm).then((response) => {
          this.loadingPermission = false;
          if (response == true) {
            this.user.authorization_rank = (newPerm);
            // this.messageService.clearMessages();
            this.messageService.replaceFirstMessage('Úspešne zmenené.', MessageType.SUCCESS, 0);
          }
        });
      }
    });
  }
  onEditManagedBy() {
    this.selectedPam = null;
    this.dataService.getAllPAMOrgans().then(pams => {
      this.pams = pams;
      this.autoCompletePams = this.pams.map((pam: PAManagingOrgan) => {
        return {
          id: pam.id,
          name: `[${pam.id}] - ${pam.name}`
        }
      })
    });
    this.isEditMode = true;
  }

  onPamChange(newVal: UniModel) {
    this.selectedPam = this.pams.find(pam => pam.id == newVal.id);
  }

  onSubmitEditClick() {
    this.isLoadingEditManagedBy = true;
    this.dataService.updateMultiUserPam(this.selectedPam.id, [this.user.id])
    .then(() => {
      this.isLoadingEditManagedBy = false;
      this.isEditMode = false;
      this.user.managed_by = this.selectedPam.id;
      this.messageService.replaceFirstMessage('Úspešne zmenené.', MessageType.SUCCESS, 0);
    });
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
