import { Component, OnInit, Input, SimpleChanges, OnChanges, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StreetValidator, personalIdValidator } from '../../../validators/validators';
import { Record, Person } from '../../../types/types';
import { Router } from '../../../../../node_modules/@angular/router';
import { MessageService, MessageType } from '../../../services/message.service';
import { IDataService, IDataserviceToken } from '../../../services/i-data-service';

@Component({
  selector: 'app-add-record-form',
  templateUrl: './add-record-form.component.html',
  styleUrls: ['./add-record-form.component.css'],
})
export class AddRecordFormComponent implements OnInit {
  @Input() prefillPerson: Person;

  addRecordForm: FormGroup;
  isLoadingData: boolean;
  public get shouldDisableButton() { return !this.addRecordForm.valid || this.isLoadingData === true; }
  constructor(
    private fb: FormBuilder, 
    private router: Router, 
    private messageService: MessageService,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  ngOnInit() {
    if (this.prefillPerson == null) {
      this.prefillPerson = new Person();
    }
    this.createForm();
  }

  onSubmit() {
    this.isLoadingData = true;
    this.dataService.storeRecord(this.createRecordModelFromForm()).then((success) => {
      setTimeout(() => {
        this.isLoadingData = false;
        this.messageService.addMessage('Záznam o priestupku bol úspešne pridaný.', MessageType.SUCCESS);
        this.router.navigate(['home']);
      });
    });
  }

  private createForm() {
    this.addRecordForm = this.fb.group({
      personForm: this.fb.group({
        personalId: [this.prefillPerson.personalId, [Validators.required, personalIdValidator]],
        birthDate: [this.prefillPerson.birthDate, Validators.required],
        gender: [this.prefillPerson.gender, Validators.required],
        firstName: [this.prefillPerson.firstName, [Validators.required]],
        lastName: [this.prefillPerson.lastName, [Validators.required, Validators.minLength(2)]],
        nationality: [this.prefillPerson.nationality, [Validators.required, Validators.maxLength(2)]],
        idCardType: [this.prefillPerson.idCardType, Validators.required],
        idCardNumber: [this.prefillPerson.idCardNumber, Validators.required],
        addressForm: this.fb.group({
          street: [this.prefillPerson.address.street, [Validators.required, StreetValidator]],
          city: [this.prefillPerson.address.city, Validators.required],
          zip: [this.prefillPerson.address.zip],
          country: [this.prefillPerson.address.country, Validators.required],
        })
      }),
      recordForm: this.fb.group({
        date: [new Date(), [Validators.required]],
        region: [null, [Validators.required]],
        district: [null, [Validators.required]],
        protectedArea: [null, [Validators.required]],
        protectionLevel: [null, [Validators.required]],
        deliquencyType: [null, [Validators.required]],
        solvingWay: [null, [Validators.required]],
        description: [null, [Validators.required]],
        sanctionAmount: [null, [Validators.required]],
        feeTicketNumber: [null, [Validators.required]],
        vehicleForm: this.fb.group({
          vehicleType: [null, [Validators.required]],
          licenceNumber: [null, [Validators.required]],
        })
      })
    });
  }

  private createRecordModelFromForm(): Record {
    const pf = this.addRecordForm.getRawValue().personForm;
    const rf = this.addRecordForm.getRawValue().recordForm;
    const person: Person = pf;
    person.address = pf.addressForm;
    const record = rf;
    rf.person = person;

    const vehicleForm = rf.vehicleForm;
    if (vehicleForm.licenceNumber != null) {
      record.carInfo = vehicleForm;
    }

    return record;
  }

}
