<mat-card class="check-result">
  <mat-card-content class="content">
    <div>
      <h2 class="name-label clickable" routerLink="/records/{{ record.id }}">{{ record.person.firstName }} {{ record.person.lastName}}</h2>
      <p>{{ record.person.personalId }}</p>
      <p>{{ record.person.address.street }}, {{ record.person.address.city }}</p>
      <p class="bold">{{ record.date | date:'d.M.y o H:mm' }}, {{ record.deliquencyType | recordEnumsPipe: 'deliquencyType' | async }}</p>
    </div>
    
    <div class="icons-container">
      <app-app-icon tooltip="Zobraziť detail priestupku" linkTo="/records/{{ record.id }}" name="open_in_new"></app-app-icon>
      <app-app-icon [disabled]="true" tooltip="Pridať priestupok" name="playlist_add"></app-app-icon>
    </div>
  </mat-card-content>
</mat-card>