<app-back-button></app-back-button>

<mat-card>
<mat-card-content>
  <h1>Vytvorenie nového používateľského účtu</h1>
  <h3>Zadajte email nového používateľa:</h3>
  <div class="check-input-wrapper">
    <app-smart-input [control]="inputControl" name="email" placeholder="Email"></app-smart-input>
    <div class="officer-checkbox-wrapper">
      <mat-checkbox [(ngModel)]="isOfficer">Úradník</mat-checkbox>    
    </div>
  </div>

  <app-smart-autocomplete (changeEvent)="onPamChange($event)" [allOptions]="autocompletePams" placeholder="Domovská správa" class="full-width"></app-smart-autocomplete>

  <div class="col-3 col-sm-6  col-xs-12">
    <button mat-raised-button color="primary" [disabled]="btnDisabledState" (click)="onClick()" class="full-width"><i class="material-icons app-btn-icon">mail_outline</i>Odoslať pozvánku na email</button>
    <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
  </div>
</mat-card-content>
</mat-card>
