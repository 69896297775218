import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-app-icon',
  templateUrl: './app-icon.component.html',
  styleUrls: ['./app-icon.component.css']
})
export class AppIconComponent implements OnInit {
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() linkTo: string;
  @Input() tooltip: string;
  @Input() clickable: boolean = true;
  @Input() stopPropagation: boolean = false;
  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
  constructor(private router: Router) { }

  ngOnInit() {
  }

  onClick(event) {
    if (this.disabled) {
      event.preventDefault();
      return false;
    }

    if (this.linkTo != null) {
      this.router.navigate([this.linkTo]);
    }

    this.clickEvent.emit();

    if (this.stopPropagation) {
      event.stopPropagation();
    }
    
    return !this.stopPropagation;
  }

}
