import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { RecordsListingPageComponent } from './pages/records-listing-page/records-listing-page.component';
import { AuthGuard } from './services/auth-guard.service';
import { CreateRecordPageComponent } from './pages/create-record-page/create-record-page.component';
import { CheckPersonPageComponent } from './pages/check-person-page/check-person-page.component';
import { RecordDetailPageComponent } from './pages/record-detail-page/record-detail-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { NotLoggedGuard } from './services/not-logged-guard';
import { CreateVisitPageComponent } from './pages/create-visit-page/create-visit-page.component';
import { InviteNewUserPageComponent } from './pages/invite-new-user-page/invite-new-user-page.component';
import { ManagePendingRegistrationsPageComponent } from './pages/manage-pending-registrations-page/manage-pending-registrations-page.component';
import { FillRegistrationFormPageComponent } from './pages/fill-registration-form-page/fill-registration-form-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { CreatePasswordPageComponent } from './pages/create-password-page/create-password-page.component';
import { VisitsPageComponent } from './pages/visits-page/visits-page.component';
import { VisitDetailPageComponent } from './pages/visit-detail-page/visit-detail-page.component';
import { ManageUsersPageComponent } from './pages/manage-users/manage-users-page.component';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { Permission } from './services/permissions.service';
import { ManageAreasPageComponent } from './pages/manage-areas-page/manage-areas-page.component';
import { ProtectedAreaDetailPageComponent } from './pages/protected-area-detail-page/protected-area-detail-page.component';
import { SentInvitationsPageComponent } from './pages/sent-invitations-page/sent-invitations-page.component';
import { ShowLogsPageComponent } from './components/show-logs-page/show-logs-page.component';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';
import { ValuesOverTimeComponent } from './components/stats/values-over-time/values-over-time.component';
import { AdminStatsPageComponent } from './pages/admin-stats-page/admin-stats-page.component';
import { ShowEnumsPageComponent } from './pages/show-enums-page/show-enums-page.component';
import { EnumDetailPageComponent } from './pages/enum-detail-page/enum-detail-page.component';
import { ManageCoordinatorsPageComponent } from './pages/manage-coordinators-page/manage-coordinators-page.component';
import { CreateNewUserPageComponent } from './pages/create-new-user-page/create-new-user-page.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [NotLoggedGuard]
    },
    {
        path: 'fill-registration-form',
        component: FillRegistrationFormPageComponent,
        canActivate: [NotLoggedGuard]
    },
    {
        path: 'reset-password',
        component: ResetPasswordPageComponent,
        canActivate: [NotLoggedGuard]
    },
    {
        path: 'create-password',
        component: CreatePasswordPageComponent,
        canActivate: [NotLoggedGuard]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'unauthorized',
                component: UnauthorizedPageComponent
            },
            {
                path: 'home',
                component: HomePageComponent
            },
            {
                path: 'check-person',
                component: CheckPersonPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Ranger, Permission.Officer]
                }
            },
            {
                path: 'create-record/:personId',
                component: CreateRecordPageComponent,
                data: {
                    permNeeded: Permission.Ranger
                }
            },
            {
                path: 'create-record',
                component: CreateRecordPageComponent,
                data: {
                    permNeeded: Permission.Ranger
                }
            },
            {
                path: 'records',
                component: RecordsListingPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Ranger, Permission.Officer]
                }
            },
            {
                path: 'records/:id',
                component: RecordDetailPageComponent
            },
            {
                path: 'create-visit',
                component: CreateVisitPageComponent,
                data: {
                    permNeeded: Permission.Ranger
                }
            },
            {
                path: 'visits',
                component: VisitsPageComponent,
                data: {
                    permNeeded: Permission.Ranger
                }
            }, 
            {
                path: 'visits/:userId',
                component: VisitsPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Admin, Permission.Officer]
                }
            }, 
            {
                path: 'invite-user',
                component: InviteNewUserPageComponent,
                data: {
                    permNeeded: Permission.Admin
                }
            },
            {
                path: 'sent-invitations',
                component: SentInvitationsPageComponent,
                data: {
                    permNeeded: Permission.Admin
                }
            },
            {
                path: 'manage-pending-registrations',
                component: ManagePendingRegistrationsPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Admin, Permission.AuthorityAmbassador, Permission.Officer]
                }
            },
            {
                path: 'manage-users',
                component: ManageUsersPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Admin, Permission.Officer, Permission.AuthorityAmbassador]
                }
            }, {
                path: 'manage-coordinators',
                component: ManageCoordinatorsPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Admin, Permission.AuthorityAmbassador]
                }
            },{
                path: 'create-user',
                component: CreateNewUserPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.AuthorityAmbassador]
                }
            },
            {
                path: 'manage-users/visits/:userId',
                component: VisitsPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Admin, Permission.Officer]
                }
            },
            {
                path: 'manage-protected-areas',
                component: ManageAreasPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Admin, Permission.Officer]
                }
            }, 
            {
                path: 'protected-areas/:id',
                component: ProtectedAreaDetailPageComponent,
                data: {
                    oneOfPermNeeded: [Permission.Admin, Permission.Officer]
                }
            },
            {
                path: 'show-logs',
                component: ShowLogsPageComponent,
                data: {
                    permNeeded: Permission.Admin
                }
            },
            {
                path: 'usage-stats',
                component: AdminStatsPageComponent,
                data: {
                    permNeeded: Permission.Admin
                }
            },
            {
                path: 'user-profile',
                component: UserProfilePageComponent,
            },
            {
                path: 'show-enums',
                component: ShowEnumsPageComponent
            },
            {
                path: 'enum-detail/:enumType',
                component: EnumDetailPageComponent
            },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard, NotLoggedGuard]
})
export class AppRoutingModule { }