<mat-card>
  <mat-card-content>
    <button mat-raised-button color="primary" (click)="backButtonClick()"><i class="material-icons app-btn-icon">arrow_back</i>{{ backButtonText }}</button>
    <button [disabled]="isLoadingPdf || record == null" mat-raised-button color="primary" (click)="reportNoticeClick()" style="margin-top: 10px"><i class="material-icons app-btn-icon ">cloud_download</i>Generovať podnet na OÚ</button>
    <app-app-spinner size="50" *ngIf="isLoadingPdf"></app-app-spinner>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content *ngIf="!showSpinner">
      <mat-tab-group>
        <mat-tab label="Detail priestupku">
            <mat-card>
              <mat-card-content *ngIf="record">
                <h2>Detail priestupku</h2>
                <div class="pl-15">
                  <p><span class="bold">Dátum a čas:</span> {{ this.record.date | date:'d.M.y o H:mm'}}</p>
                  <p><span class="bold">Kraj:</span> {{ this.record.region | recordEnumsPipe: 'region' | async }}</p>
                  <p><span class="bold">Okres:</span> {{ this.record.district | recordEnumsPipe: 'district' | async }}</p>
                  <p><span class="bold">Chránené územie:</span> {{ this.record.protectedArea | recordEnumsPipe: 'protectedArea' | async }}</p>
                  <p><span class="bold">Stupeň ochrany:</span> {{ this.record.protectionLevel }}</p>
                  <p><span class="bold">Typ priestupku:</span> {{ this.record.deliquencyType | recordEnumsPipe: 'deliquencyType' | async }}</p>
                  <p><span class="bold">Popis:</span> {{ this.record.description }}</p>
                  <p><span class="bold">Spôsob vyriešenia:</span> {{ this.record.solvingWay | recordEnumsPipe: 'solvingWay' | async }}</p>
                  <p><span class="bold">Výška uloženej pokuty:</span> {{ this.record.sanctionAmount }}</p>
                  <p *ngIf="record.feeTicketNumber != null"><span class="bold">Séria a číslo pokutového bloku:</span> {{ this.record.feeTicketNumber }}</p>
                  <div *ngIf="record.carInfo != null" class="pl-15">
                    <h3>Údaje o vozidle</h3>
                    <p><span class="bold">Typ vozidla:</span> {{ this.record.carInfo.vehicleType | recordEnumsPipe: 'vehicleType' | async }}</p>
                    <p><span class="bold">ŠPZ:</span> {{ this.record.carInfo.licenceNumber }}</p>
                    <p><span class="bold">Farba:</span> {{ this.record.carInfo.color }}</p>
                  </div >

                  <p style="padding-top: 20px;"><span class="bold">Priestupok zaznamenal:</span> {{ rangerName }}</p>
                </div>
              </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab label="Detail osoby" *ngIf="record">
            <mat-card>
              <mat-card-content>
                  <h2>Detail osoby</h2>
                  <div class="pl-15">
                    <p><span class="bold">Meno:</span> {{ this.record.person.firstName }}</p>
                    <p><span class="bold">Priezvisko:</span> {{ this.record.person.lastName }}</p>
                    <p><span class="bold">Rodné číslo:</span> {{ this.record.person.personalId }}</p>
                    <p><span class="bold">Dátum narodenia:</span> {{ this.record.person.birthDate | date:'d.M.y'}}</p>
                    <p><span class="bold">Pohlavie:</span> {{ this.record.person.gender | recordEnumsPipe: 'gender' | async }}</p>
                    <p><span class="bold">Štátna príslušnosť:</span> {{ this.record.person.nationality }}</p>
                    <p><span class="bold">Typ dokladu totožnosti:</span> {{ this.record.person.idCardType | recordEnumsPipe: 'idCardType' | async }}</p>
                    <p><span class="bold">Číslo dokladu totožnosti:</span> {{ this.record.person.idCardNumber }}</p>
                    <div class="pl-15">
                      <h3>Adresa</h3>
                      <p><span class="bold">Ulica a číslo:</span> {{ this.record.person.address.street }}</p>
                      <p><span class="bold">Obec:</span> {{ this.record.person.address.city }}</p>
                      <p *ngIf="record.person.address.zip != null"><span class="bold">PSČ:</span> {{ this.record.person.address.zip}}</p>
                      <p><span class="bold">Krajina:</span> {{ this.record.person.address.country}}</p>
                    </div >
                  </div>
              </mat-card-content>
            </mat-card>
        </mat-tab>
      </mat-tab-group>
  </mat-card-content>

  <mat-card-content *ngIf="showSpinner">
    <app-app-spinner></app-app-spinner>
  </mat-card-content>
</mat-card> 