<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Logy za 30 dní dozadu</h1>
    <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    <div *ngIf="!isLoadingData">
      <!-- <mat-form-field class="date-filter">
        <input matInput [matDatepicker]="picker" placeholder="Dátum od" (dateInput)="onDateInputChange()" (click)="picker.open()" [formControl]="dateFrom" id="dateFrom" name="dateFrom" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi="true"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="date-filter">
        <input matInput [matDatepicker]="picker2" placeholder="Dátum do" (dateInput)="onDateInput2Change()" (click)="picker2.open()" [formControl]="dateTo" id="dateTo" name="dateTo" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 touchUi="true"></mat-datepicker>
      </mat-form-field> -->
      <!-- <hr /> -->
      <div class="check-input-wrapper">
        <app-smart-input [control]="inputControl" name="filter" placeholder="Filter"></app-smart-input>
      </div>
      <mat-expansion-panel *ngFor="let log of filteredLogs; let i=index">
        <mat-expansion-panel-header class="header-wrapper">
          <mat-panel-title class="header-wrapper">
              {{ log.created_at | date:'d.M.y o H:mm:ss' }}
          </mat-panel-title>
          <mat-panel-description class="header-wrapper">
              {{ log.type }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>Message: {{ log.message }}</p>
        <p>User: {{ log.user != null ? log.user.name + ' ' + log.user.surname : '' }}</p>
        <pre>Data: {{ log.data | json }}</pre> 
      </mat-expansion-panel>
    </div>
  </mat-card-content>
</mat-card>


