<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Správa používateľov <span *ngIf="hasUserAdminPermission"> - Admin</span></h1>
    <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    <div *ngIf="!isLoadingData">
      <div class="check-input-wrapper">
        <app-smart-input [control]="inputControl" name="filter" [placeholder]="filterPlaceholder"></app-smart-input>
      </div>
    
      <ng-container *ngIf="hasUserAdminPermission || hasUserAuthAmbPermission">
        <button mat-raised-button [color]="allowSelection ? 'warn' : 'primary'" (click)="onMultiClick()"><i class="material-icons app-btn-icon">filter_none</i><span *ngIf="allowSelection">Zrušiť </span>Hromadný proces</button>
        <button *ngIf="allowSelection" [disabled]="isSomeMultiOperationOnGoing || isSelectionEmpty" (click)="isChangingParentPams=true; inputControl.setValue('');" style="margin-left: 10px;" mat-raised-button color="primary"><i class="material-icons app-btn-icon">maps_home_work</i>Zmeniť domovskú správu </button>
      </ng-container>

      <ng-container *ngIf="isChangingParentPams">
        <div>
          <h3>Hromadná úprava domovskej správy</h3>
          <div >
            <app-smart-autocomplete (changeEvent)="selectedPam=$event" [allOptions]="allPams" placeholder="Domovská správa"></app-smart-autocomplete>
          </div>
          <div class="btn-wrapper">
            <button mat-raised-button color="warn" (click)="isChangingParentPams=false" class="dashboard-btn"><i class="material-icons app-btn-icon">cancel</i>Zrušiť</button> 
            <button [disabled]="selectedPam==null || isLoadingMultiPam" (click)="onSubmitMultiManagedBy()" mat-raised-button color="primary" class="dashboard-btn"><i class="material-icons app-btn-icon">save</i>Uložiť</button>
            <app-app-spinner *ngIf="isLoadingMultiPam" [size]="30"></app-app-spinner>
          </div>
        </div>
      </ng-container>
      <mat-accordion>
        <ng-container *ngFor="let user of filteredUsers">
          <ng-container *ngIf="(!isSomeMultiOperationOnGoing) || (isSomeMultiOperationOnGoing && checkedStates[user.id]==true)">
            <app-user-list-tile [user]="user" [showCheckbox]="allowSelection" [checked]="checkedStates[user.id]" (onCheckClick)="onCheckboxClick($event)"></app-user-list-tile>
          </ng-container>
        </ng-container>
      </mat-accordion>
    </div>
  </mat-card-content>
</mat-card>