import { Injectable } from '@angular/core';
import { Record } from '../types/types';

export interface TableConfig {
  filterCriteria: string;
  pageIndex: number;
  
}

export enum RecordDetailOrigin {
    HomePage,
    CheckResultPage,
    ProtectedAreaDetailPage
}

@Injectable()
export class HistoryService {

  constructor() { 
    this.clearHistory();
  }

  clearHistory() {
    this.checkResults = [];
    this.checkCriteria = ''
    this.records = [];

    this.listingFilterCriteria = '';
    this.recordsListingPageConfig = {
      filterCriteria: '',
      pageIndex: 0
    };
    this.manageUsersFilter = '';
    this.manageProtectedAreasFilter = '';

    this.protectedAreaDetailId = '';
    this.recordDetailOrigin = RecordDetailOrigin.HomePage;
  }

  checkResults: Record[];
  checkCriteria: string;
  records: Record[];

  listingFilterCriteria: string ;
  recordsListingPageConfig: TableConfig;

  manageUsersFilter: string;

  manageProtectedAreasFilter: string;

  protectedAreaDetailId: string;
  recordDetailOrigin: RecordDetailOrigin

}
