import { Component, OnInit, Inject } from '@angular/core';
import { UniModel, Record } from '../../types/types';
import { mapBERecord } from '../../utils/utils';
import { HistoryService } from '../../services/history.service';
import { PermissionsService } from '../../services/permissions.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-records-listing-page',
  templateUrl: './records-listing-page.component.html',
  styleUrls: ['./records-listing-page.component.css']
})
export class RecordsListingPageComponent implements OnInit {

  constructor(
    private historyService: HistoryService, 
    private ps: PermissionsService,
    @Inject(IDataserviceToken) private dataService: IDataService  
  ) { }
  protectedAreas: UniModel[];
  records: Record[];
  deliquencyTypes: UniModel[];
  get user() { return this.dataService.user};
  get isUserActiveCoordinator() { 
    return this.hasUserOfficerPermission() 
      && this.user.coordinators_parent_p_a_organ != null 
      && this.user.coordinators_parent_p_a_organ.length > 0
  };

  get historyTableConfig() { return this.historyService.recordsListingPageConfig; }

  ngOnInit() {
    this.dataService.getDeliquencyTypelOptions().then((data) => {
      this.deliquencyTypes = data;
    });
    this.dataService.getProtectedAreaOptions().then((data) => {
      this.protectedAreas = data;
    });
    if (this.historyService.records.length == 0) {
      this.dataService.getRecords().subscribe((beRecords) => {
        this.records = beRecords.map(beRecord => mapBERecord(beRecord));
        this.historyService.records = this.records;
      });
    } else {
      this.records = this.historyService.records;
    }
  }

  hasUserOfficerPermission() {
    return this.ps.hasUserOfficerPermission();
  }
}
