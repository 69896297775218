import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '../../../node_modules/@angular/router';
import { MessageService, MessageType } from './message.service';
import { IDataserviceToken, IDataService } from './i-data-service';

@Injectable()
export class AuthErrorHandlerService {

  constructor(private injector: Injector, private zone: NgZone) { }

  handleError(error) {
    const router: Router = this.injector.get(Router);
    const dataService: IDataService = this.injector.get(IDataserviceToken);
    const messageService: MessageService = this.injector.get(MessageService);
    if (error.status === 401 || error.status === 403) {
      dataService.doLogout();
      messageService.addMessage('Vaše prihlásenie vypršalo, je potrebné sa znovu prihlásiť.', MessageType.INFO, 1, null);
      this.zone.run(()=>router.navigate(['login']));
    };
    
    throw error;
  }

}
