import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '../../../../node_modules/@angular/forms';
import { TransformTypes } from '../../components/inputs/smart-input/smart-input.component';
import { MessageService, MessageType } from '../../services/message.service';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { ILoggingServiceToken, ILoggingService } from '../../services/i-logging-service';

@Component({
  selector: 'app-fill-registration-form-page',
  templateUrl: './fill-registration-form-page.component.html',
  styleUrls: ['./fill-registration-form-page.component.css']
})
export class FillRegistrationFormPageComponent implements OnInit {
  form: FormGroup;
  email: string;
  transformTypes = TransformTypes;
  isLoadingData = false;
  isSuccess = undefined;
  forceDisable = false;

  isTokenValid = null;
  isOfficer;

  constructor(private fb: FormBuilder, 
    private messageService: MessageService, 
    private activatedRoute: ActivatedRoute,
    @Inject(ILoggingServiceToken) private ls: ILoggingService,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  get shouldDisableButton() { return this.form.invalid || this.isLoadingData || this.isSuccess || this.forceDisable; }
  get shouldShowSucessConfirmation() { return this.isSuccess === true; }

  ngOnInit() {
    const token = this.activatedRoute.snapshot.queryParams['token'] || null;
    const msg = 'Nie je možné podať žiadosť o registráciu, kontaktujte administrátora.';
    this.dataService.getInvitationEmailForToken(token).then(
      (successResponse) => {
        this.isTokenValid = successResponse.success;
        this.isOfficer = successResponse.isOfficer;
        if (!successResponse.success) {
          this.ls.logError('Server responded success == false, - ' + msg);
          this.messageService.addMessage(msg, MessageType.ERROR);
        }
        this.email = successResponse.email;
        this.createForm();
      },
      (error) => {
        this.isTokenValid = false;
        this.ls.logError(msg, error);
        this.messageService.addMessage(msg, MessageType.ERROR);
      }
    )
  }

  onSubmit() {
    this.isLoadingData = true;
    const data = Object.assign({email: this.email, id: null}, this.form.value); 
    this.dataService.sendRegistrationApplication(data).then(
      (success) => {
        this.isLoadingData = false;
        this.isSuccess = success == true;
        if (!success) {
          const msg = 'Odoslanie žiadosti zlyhalo, kontaktujte administrátora.';
          this.ls.logError('Server responded success == false - ' + msg);
          this.messageService.addMessage(msg, MessageType.ERROR);
        }
      }, 
      ((error) => {
        this.isLoadingData = false;
        this.isSuccess = false;
        this.forceDisable = true;
        const msg = 'Odoslanie žiadosti zlyhalo, skúste to, prosím, neskôr.';
        this.ls.logError(msg, error);
        this.messageService.addMessage(msg, MessageType.ERROR);
      })
    );
  }

  private createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      mobile: ['', Validators.required],
      street: ['', this.isOfficer ? null : Validators.required],
      city: ['', this.isOfficer ? null : Validators.required],
      zip: ['', this.isOfficer ? null : Validators.required],
      document_number: ['', this.isOfficer ? null : Validators.required],
    });
  }

}
