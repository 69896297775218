<div class="form-wrapper">
    <mat-card>
        <mat-card-content>
            <h2>Údaje o osobe</h2>
            <div [formGroup]="parentFormGroup">
                <app-date-and-personal-input [personalControl]="parentFormGroup.controls.personalId" [genderControl]="parentFormGroup.controls.gender" [dateControl]="parentFormGroup.controls.birthDate"></app-date-and-personal-input>
                <app-smart-input [control]="parentFormGroup.controls.firstName" placeholder="Meno" [transformFunc]="transformTypes.capitalize" name="firstName"></app-smart-input>
                <app-smart-input [control]="parentFormGroup.controls.lastName" placeholder="Priezvisko" [transformFunc]="transformTypes.capitalize" name="lastName"></app-smart-input>
                <app-smart-input [control]="parentFormGroup.controls.nationality" placeholder="Štátna príslušnosť" [transformFunc]="transformTypes.uppercase" maxLength="2" name="nationality"></app-smart-input>
                <app-smart-select [control]="parentFormGroup.controls.idCardType" placeholder="Typ dokladu totožnosti" [options]="options"></app-smart-select>
                <app-smart-input [control]="parentFormGroup.controls.idCardNumber" placeholder="Číslo dokladu totožnosti" [transformFunc]="transformTypes.uppercase" name="idCardNumber"></app-smart-input>
            </div>
            <app-address-form [parentFormGroup]="parentFormGroup.controls.addressForm"></app-address-form>
        </mat-card-content>
    </mat-card>
</div>

