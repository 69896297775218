<app-back-button></app-back-button>

<h1>Osobné informácie</h1>
<mat-card>
  <mat-card-content>
    <p><span class="bold">Meno a priezvisko:</span> {{ user.name }} {{ user.surname }}</p>
    <p><span class="bold">Email:</span> {{ user.email }}</p>
    <p><span class="bold">Telefón:</span> {{ user.mobile }}</p>

    <ng-container *ngIf="!isOfficer">
        <br />
        <p><span class="bold">Ulica:</span> {{ user.street }}</p>
        <p><span class="bold">Obec:</span> {{ user.city }}</p>
        <p><span class="bold">PSČ:</span> {{ user.zip }}</p>
    
        <br />
        <p><span class="bold">Číslo preukazu:</span> {{ user.document_number }}</p>   
        <p><span class="bold">Domovská správa:</span> {{ user.managed_by | recordEnumsPipe: 'pams' | async}}</p>  
    </ng-container>
    
    <!-- <br />

    <p class="bold">Moje oprávnenia:</p>

    <div *ngFor="let perm of userPermissions ">
      <p 
        style="padding-left: 15px; margin-block-start: 0px; margin-block-end: 0px;"
      >{{ perm.name }}</p>
    </div> -->
  </mat-card-content>
</mat-card>
