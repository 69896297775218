import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl } from '../../../../node_modules/@angular/forms';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { Token } from '../../../app/types/types';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  passControl = new FormControl('', [Validators.required]);
  loginControl = new FormControl('', [Validators.required]); // Validators.email
  checked = false;
  returnUrl: string;
  lastLoginName:string;
  get loadingData() { return this.dataService.isLoggingIn; }

  public get disableButton() { return !this.checked || !this.loginControl.valid || !this.passControl.valid || this.loadingData}

  constructor(private route: ActivatedRoute, @Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    this.lastLoginName = localStorage.getItem('lastLogin') || '';
    const token: Token = JSON.parse(localStorage.getItem('tokenObj'));
    if (token == null) {
      return;
    }
    const now = new Date();
    const expirationDate = new Date(token.expirationDate);
    if (expirationDate.getTime() > now.getTime()) {
      // not yet expired, do login
      this.dataService.doTokenLogin(token, this.returnUrl);
    } else {
      localStorage.removeItem('tokenObj');
    }
  }

  onFocus() {
    this.dataService.clearLoggingError();
  }

  async onClick() {
    this.dataService.doLogin(this.loginControl.value, this.passControl.value, (error) => {
      this.passControl.setValue(null);
      this.checked = false;
    }, this.returnUrl);
  }

}
