import { Component, OnInit, Inject } from '@angular/core';
import { SentUserInvitation } from '../../types/types';
import { MessageService, MessageType } from '../../services/message.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-sent-invitations-page',
  templateUrl: './sent-invitations-page.component.html',
  styleUrls: ['./sent-invitations-page.component.css']
})
export class SentInvitationsPageComponent implements OnInit {
  isLoadingData;
  sentInvitations: SentUserInvitation[];
  cancelBtnDisabled = false;
  refreshBtnDisabled = false;
  proccessingId;
  constructor(private ms: MessageService, @Inject(IDataserviceToken) private ds: IDataService) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.ds.getSentUserInvitations().then((data) => {
      this.sentInvitations = data;
      this.isLoadingData = false;
    });
  }

  onCancelClick(id) {
    this.proccessingId = id;
    this.cancelBtnDisabled = true;
    this.ds.cancelRegistrationInvitation(id).then((success) => {
      this.cancelBtnDisabled = false;
      this.proccessingId = null;
      if (success) {
        this.sentInvitations = this.sentInvitations.filter(inv => inv.id != id);
        this.ms.replaceFirstMessage('Odstránené.', MessageType.SUCCESS);
      }
    });
  }

  onRefreshClick(id) {
    this.refreshBtnDisabled = true;
    this.proccessingId = id;
    this.ds.refreshRegistrationInvitation(id).then((response) => {
      this.refreshBtnDisabled = false;
      this.proccessingId = null;
      if (response.success) {
        this.sentInvitations.forEach((inv) => {
          if (inv.id == id) {
            inv.validHoursRemaining = response.validHours;
          }
        });
        this.ms.replaceFirstMessage('Obnovené.', MessageType.SUCCESS);
      }
    });
  }
}
