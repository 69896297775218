<h1 mat-dialog-title>Úprava môjho popisu návštevy územia</h1>
<div mat-dialog-content>
  <div style="overflow: hidden;">
    <mat-form-field>
      <textarea matInput placeholder="Popis návštevy" [(ngModel)]="data.description"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Zrušiť </button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>