<app-back-button [backRouterLink]="backRouterLink" [backButtonText]="backButtonText"></app-back-button>
<mat-card>
  <mat-card-content>
    <h1>Prehľad<span *ngIf="forUser == null && allVisits == false"> mojich</span><span *ngIf="allVisits == true || isCoordinatingUser"> všetkých</span> návštev území<span *ngIf="forUser != null"> pre používateľa '{{forUser.name}} {{forUser.surname}}'</span></h1>
    <div *ngIf="user.coordinators_parent_p_a_organ != null && user.coordinators_parent_p_a_organ.length > 0 && !isCoordinatingUser && allVisits != false" class="pam-list"> v správach:
      <ng-container *ngFor="let pam of user.coordinators_parent_p_a_organ">
        <br>
        <span class="bold"> - {{ pam.name }}</span>
      </ng-container>  
    </div>
    <button *ngIf="hasUserPdfPerm()" [disabled]="disablePdfBtn" mat-raised-button color="primary" (click)="pdfClick()" ><i class="material-icons app-btn-icon">cloud_download</i>Exportovať do PDF</button>
    <app-app-spinner *ngIf="isLoadingData || loadingPdf"></app-app-spinner>
    <div *ngIf="!isLoadingData">
      <div *ngIf="visits.length > 0">
        <h4>Zobraziť návštevy v rozsahu:</h4>
        <mat-form-field class="date-filter">
          <input matInput [matDatepicker]="picker" placeholder="Dátum od" (dateInput)="onDateInputChange()" (click)="picker.open()" [formControl]="dateFrom" id="dateFrom" name="dateFrom" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi="true"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-filter">
          <input matInput [matDatepicker]="picker2" placeholder="Dátum do" (dateInput)="onDateInput2Change()" (click)="picker2.open()" [formControl]="dateTo" id="dateTo" name="dateTo" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 touchUi="true"></mat-datepicker>
        </mat-form-field>
        <hr />
        <div class="check-input-wrapper">
          <app-smart-input [control]="inputControl" name="filter" placeholder="Filter"></app-smart-input>
        </div>
        <mat-accordion>
          <div *ngIf="filterRunning" class="dimming">
            <app-app-spinner></app-app-spinner>
          </div>
          <mat-expansion-panel *ngFor="let visit of filteredVisits; let i=index" class="visit-item" (opened)="onPanelOpened(i)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                  {{ visit.date | date:'d.M.y' }}
              </mat-panel-title>
              <mat-panel-description>
                  {{ visit.protectedArea }} <span class="mobile-hidden"> - {{visit.district | recordEnumsPipe: 'district' | async}}</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <h3>Akcie:</h3>
            <app-app-icon name="edit" tooltip="Upraviť popis" [disabled]="userId != null" (clickEvent)="onEditDescriptionClick(visit)"></app-app-icon>
            <app-app-icon name="delete" tooltip="Vymazať návštevu" [disabled]="userId != null" (clickEvent)="onDeleteVisitClick(visit, i)"></app-app-icon>
            <hr>
            <app-visit [visit]="visit"></app-visit>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="visits.length == 0">
        <h3>Zatiaľ neboli pridané žiadne návštevy územia.</h3>
        <button *ngIf="forUser == null" mat-raised-button color="primary" routerLink="/create-visit" class="dashboard-btn"><i class="material-icons app-btn-icon">location_on</i>Pridať návštevu územia</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
