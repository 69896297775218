<mat-card class="item" [ngClass]="{'bg-success': isVerified && isVerificationFinished, 'bg-decline': isVerified === false && isVerificationFinished}">
  <mat-card-content>
    <h3>{{ item.name }} {{ item.surname }}</h3>
    <p><span class="bold">Email:</span> {{ item.email }}</p>
    <p><span class="bold">Mobil:</span> {{ item.mobile }}</p>
    <p><span class="bold">Ulica:</span> {{ item.street }}</p>
    <p><span class="bold">Obec:</span> {{ item.city }}</p>
    <p><span class="bold">PSČ:</span> {{ item.zip }}</p>
    <p><span class="bold">Číslo preukazu:</span> {{ item.document_number }}</p>
    
    <hr />
    <app-territory-districts 
      (submitNewDistricts)="onSubmitNewDistricts($event)" 
      (cancelSubmission)="onCancelSubmission()" 
      (editIconClick)="onEditIconClick()"
      [isEditMode]="!isVerificationFinished" 
      [canEdit]="!isVerificationFinished" 
      [allDistricts]="allDistricts" 
      [userDistricts]="selectedDistricts" 
      [isLoading]="false"
    ></app-territory-districts>
    <hr />
    
    <div *ngIf="hasUserAdminPermission()" id="options-parent" style="overflow: hidden; padding-bottom: 10px;">
      <div *ngFor="let option of options">
        <mat-checkbox [(ngModel)]="option.checked">{{ option.name }}</mat-checkbox>
      </div>
    </div>
    <div id="verification-result-text" *ngIf="isVerificationFinished" style="padding-top: 15px;">
      <span class="bold">{{ isVerified ? 'Schválené' : 'Zamietnuté' }}</span> používateľom {{ item.authority_ambassador.name }} {{ item.authority_ambassador.surname }}.
    </div>
    <div class="buttons">
      <div *ngIf="hasUserAuthorityAmbassadorPermission() || hasUserOfficerPermission()" class="col-4 col-sm-6 col-xs-12 sub-buttons">
        <button mat-raised-button color="primary" [disabled]="isAmbassadorApproveButtonDisabled" (click)="onAmbassadorButtonClick(true)" class="full-width"><i class="material-icons app-btn-icon">gavel</i> {{ isVerified === true  && isVerificationFinished ? 'Registrácia schválená' : 'Schváliť registráciu'}}</button>
        <button [style.marginTop.px]="10" mat-raised-button color="warn" [disabled]="isAmbassadorRefuseButtonDisabled" (click)="onAmbassadorButtonClick(false)" class="full-width"><i class="material-icons app-btn-icon">cancel</i> {{ isVerified === false && isVerificationFinished ? 'Registrácia zamietnutá' : 'Zamietnuť registráciu' }}</button>
        <app-app-spinner *ngIf="isLoadingAmbassadorData"></app-app-spinner>
      </div>
      <div *ngIf="hasUserAdminPermission()" class="col-4 col-sm-6 col-xs-12 sub-buttons">
        <button mat-raised-button color="primary" [disabled]="isAdminApproveButtonDisabled" (click)="onAdminAllowClick()" class="full-width"><i class="material-icons app-btn-icon">done_all</i> {{ isAdminSuccess ? 'Registrácia dokončená' : 'Dokončiť registráciu'}}</button>
        <button [style.marginTop.px]="10" mat-raised-button color="warn" [disabled]="isLoadingAdminData || isAdminSuccess" (click)="onAdminDisallowClick()" class="full-width"><i class="material-icons app-btn-icon">delete</i> {{ isAdminSuccess ? 'Registrácia zamietnutá' : 'Odstrániť registráciu'}}</button>
        <app-app-spinner *ngIf="isLoadingAdminData"></app-app-spinner>
      </div>
    </div>
    
    </mat-card-content>
</mat-card>