<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Overenie osoby</h1>
    <h3>Zadajte rodné číslo alebo meno osoby:</h3>
    <div class="check-input-wrapper">
      <app-smart-input [control]="inputControl" (keyup)="onKey($event)" (change)="onInputChange($event)" name="personalOrName" placeholder="Rodné číslo alebo meno"></app-smart-input>
    </div>
    <div class="col-3 col-sm-6">
      <button mat-raised-button color="primary" [disabled]="!inputControl.valid || isLoadingData" (click)="onClick()" class="full-width"><i class="material-icons app-btn-icon">search</i>Overiť osobu</button>
      <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    </div>
  </mat-card-content>
</mat-card>

<div class="check-reults" *ngIf="isResultsToShow">
  <mat-card>
    <mat-card-content>
      <h1>Nájdené priestupky</h1>
      <div *ngFor="let result of checkResults">
        <app-check-result [record]="result"></app-check-result>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="check-reults" *ngIf="shouldShowNoResults">
  <mat-card>
    <mat-card-content>
      <h1>Pre kritérium "{{ inputControl.value }}" neboli nájdené žiadne osoby.</h1>
    </mat-card-content>
  </mat-card>
</div>
