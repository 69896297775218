import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TransformTypes } from '../../inputs/smart-input/smart-input.component';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;

  transformTypes = TransformTypes;
  constructor() { }

  ngOnInit() {
  }

}
