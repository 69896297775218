<div *ngIf="visit.records != null">
  <h2>{{ visit.date | date:'d.M.y'}} {{ visit.protectedArea | recordEnumsPipe: 'protectedArea' | async }}</h2>
  <p><span class="bold">Okres:</span> {{ visit.district | recordEnumsPipe: 'district' | async }}</p>
  <p [innerHTML]="visit.description"></p>
  <h4>Pripojené priestupky:</h4>
  <div *ngFor="let record of visit.records">
    <app-record-list-tile [record]="record"></app-record-list-tile>
  </div>
  <div *ngIf="visit.records.length == 0">
    <div *ngIf="officerWatching && !officerLoadedRecords && !loadingRecords" class="clickable load" (click)="onLoadRecordsClick()"><i class="material-icons app-btn-icon">get_app</i><p>Načítať priestupky.</p></div>
    <p class="none-label" *ngIf="!officerWatching || officerLoadedRecords">Žiadne.</p>
    <app-app-spinner *ngIf="loadingRecords"></app-app-spinner>
  </div>

  <hr />

  <h4 class="rangers-label">Zúčastnení strážcovia:</h4>
  <div *ngFor="let ranger of visit.rangers" class="user-parent">
    <i class="material-icons app-btn-icon">person</i>
    <p>{{ ranger.ranger.name + ' ' + ranger.ranger.surname }}</p>
  </div>
  <div *ngIf="visit.rangers.length == 0">
    <p>Žiadni.</p>
  </div>
</div>
<app-app-spinner *ngIf="visit.records == null"></app-app-spinner>
