<app-back-button></app-back-button>

<h1>Prehľad mojich priestupkov {{ isUserActiveCoordinator ? 'a priestupkov v správach:' : '' }}</h1>

<div *ngIf="isUserActiveCoordinator" class="pam-list">
    <ng-container *ngFor="let pam of user.coordinators_parent_p_a_organ">
      <br>
      <span class="bold"> - {{ pam.name }}</span>
    </ng-container>  
  </div>

<app-records-listing-table
    [protectedAreas]="protectedAreas"
    [deliquencyTypes]="deliquencyTypes"
    [records]="records"
    [tableConfig]="historyTableConfig"
></app-records-listing-table>


