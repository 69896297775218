import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { Record } from '../../types/types';
import { HistoryService, RecordDetailOrigin } from '../../services/history.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { downloadPdfFile } from '../../utils/utils';

@Component({
  selector: 'app-record-detail-page',
  templateUrl: './record-detail-page.component.html',
  styleUrls: ['./record-detail-page.component.css']
})
export class RecordDetailPageComponent implements OnInit {
  record: Record;
  origin: RecordDetailOrigin;
  isLoadingPdf: boolean = false;

  get showSpinner() { return this.record == null; }
  get backButtonText() { 
    switch (this.origin) {
      case RecordDetailOrigin.HomePage:
        return 'Späť na prehľad priestupkov';
      case RecordDetailOrigin.CheckResultPage:
        return 'Späť na výsledok overenia';
      case RecordDetailOrigin.ProtectedAreaDetailPage:
        return 'Späť na detail územia';
      default:
        throw new Error('No origin found for ' + this.origin);
    }
  }
  get rangerName() { return this.record.ranger != null ? `${this.record.ranger.name} ${this.record.ranger.surname}` : 'Nedostupné'}
  constructor(
    private route: ActivatedRoute,
    private historyService: HistoryService,
    private router: Router,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  ngOnInit() {
    const recordId = this.route.snapshot.params['id'];
    this.dataService.getRecordDetail(recordId).then((record: Record) => {
      this.record = record;
    });
    this.origin = this.historyService.recordDetailOrigin;
  }

  backButtonClick() {
    switch (this.origin) {
      case RecordDetailOrigin.HomePage:
        this.router.navigate(['records']);
        break;
      case RecordDetailOrigin.CheckResultPage:
        this.router.navigate(['check-person']);
        break;
      case RecordDetailOrigin.ProtectedAreaDetailPage:
        this.router.navigate(['protected-areas/' + this.historyService.protectedAreaDetailId]);
        break;
      default:
        throw new Error('No origin found for ' + this.origin);
    }
  }

  reportNoticeClick() {
    this.isLoadingPdf = true;
    this.dataService.downloadRecordNoticePdf(this.record.id).subscribe((data) => {
      const fullYear = (new Date(this.record.date)).getFullYear();
      const noticeId = `${fullYear}-${this.record.ranger.id}-${this.record.id}`;
      const fileName = `podnet-${noticeId}.pdf`;
      downloadPdfFile(data, fileName);
      this.isLoadingPdf = false;
    });
  }

}
