<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Prehľad odoslaných pozvánok na registráciu</h1>

    <div class="col-6 col-sm-8 col-xs-12">
      <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
      <div *ngIf="!isLoadingData">
        <mat-card *ngFor="let invitation of sentInvitations" class="invitation-parent" [style.backgroundColor]=" invitation.validHoursRemaining > 0 ? 'lightgreen' : 'lightcoral'">
          <mat-card-content>
            <h3>{{ invitation.email }}</h3>
            <p>Čas odoslania: {{ invitation.dateSent | date:'d.M.y o H:mm' }}</p>
            <p>Koniec platnosti za: {{ invitation.validHoursRemaining | number:'1.2-2'}} hod.</p>
            <p>Odoslal: {{ invitation.sentBy }}</p>
            <div class="controls-wrapper">
              <div class="col-6 col-xs-8">
                <button [disabled]="cancelBtnDisabled && proccessingId==invitation.id" mat-raised-button color="warn" class="btn-margin full-width" (click)="onCancelClick(invitation.id)"><i class="material-icons app-btn-icon">cancel</i>Zrušiť pozvánku</button>
                <button [disabled]="refreshBtnDisabled && proccessingId==invitation.id"mat-raised-button color="primary" class="btn-margin full-width" (click)="onRefreshClick(invitation.id)"><i class="material-icons app-btn-icon">refresh</i>Obnoviť pozvánku</button>
              </div>
              <div class="col-6 col-xs-4">
                <app-app-spinner size="50" *ngIf="(refreshBtnDisabled || cancelBtnDisabled) && proccessingId==invitation.id"></app-app-spinner>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <h3 *ngIf="sentInvitations.length == 0">V súčasnoti nie sú žiadne odoslané pozvánky na registráciu.</h3>
      </div>
    </div>
  </mat-card-content>
</mat-card>
  