import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IDataService, IDataserviceToken } from './i-data-service';

@Injectable()
export class NotLoggedGuard implements CanActivate {

  constructor(
    private router: Router,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }
  
  canActivate() {
    if (this.dataService.isUserLoggedIn()) {
      this.router.navigate(['home']);
    } else {
      return true;
    }
  }
}