import { Component, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export enum TransformTypes {
  capitalize = 'capitalize',
  uppercase = 'uppercase'
}

@Component({
  selector: 'app-smart-input',
  templateUrl: './smart-input.component.html',
  styleUrls: ['./smart-input.component.css'],
  providers: [{ provide: MatFormFieldControl, useExisting: SmartInputComponent }],
})
export class SmartInputComponent implements OnDestroy, MatFormFieldControl<string> {
  private static readonly errorMessages = {
    'required': () => 'Toto pole je povinné',
    'minlength': (params) => 'Minimálny počet znakov je ' + params.requiredLength,
    'maxlength': (params) => 'Maximálny počet znakov je ' + params.requiredLength,
    'pattern': (params) => 'The required pattern is: ' + params.requiredPattern,
    'validStreet': () => 'Zadajte názov ulice a číslo',
    'matDatepickerParse': () => 'Nesprávny formát dátumu, použi dd.mm.yyyy',
    'validPersonalId': () => 'Nesprávne zadané rodné číslo',
    'email': () => 'Nesprávne zadaný email'
  };
  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  ngControl = null;
  errorState = false;
  controlType = 'my-tel-input';

  @Input() control: AbstractControl;
  @Input() name: string;
  @Input() maxLength: number;
  @Input() transformFunc: TransformTypes | any;
  @Input() type = 'text';

  @Output() onFocus: EventEmitter<any> = new EventEmitter(); 
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  onFocusEvent() {
    this.onFocus.emit();
  }

  get empty() {
    return this.control.value == null;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  id = `my-tel-input-${SmartInputComponent.nextId++}`;

  describedBy = '';

  @Input()
  get placeholder() { return this._placeholder; }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required() { return this._required; }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled() { return this._disabled; }
  set disabled(dis) {
    if (!this.control) return;
    this._disabled = coerceBooleanProperty(dis);
    if (dis) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    return this.control.value;
  }
  set value(tel: string | null) {
    if (!this.control) return;
    this.control.setValue(tel);
    this.stateChanges.next();
  }

  constructor(fb: FormBuilder, public fm: FocusMonitor, public elRef: ElementRef) {

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  shouldShowErrors(control: AbstractControl): boolean {
    return control &&
      control.errors &&
      (control.dirty || control.touched);
  }

  listOfErrors(control: AbstractControl): string[] {
    try {
      return Object.keys(control.errors)
        .map(field => this.getMessage(field, control.errors[field]));
    } catch (Exception) {
      console.log(Exception, control.errors);
      return ['Neznama chyba'];
    }

  }

  onChange() {
    if (this.transformFunc == null) {
      this.change.emit(this.control.value);
      return;
    }

    switch (this.transformFunc) {
      case TransformTypes.capitalize:
        this.control.setValue(this.control.value.charAt(0).toUpperCase() + this.control.value.slice(1));
        break;
      case TransformTypes.uppercase:
        this.control.setValue(this.control.value.toUpperCase());
        break;
      default:
        this.control.setValue(this.transformFunc(this.control.value));
        break;
    }

    this.control.setValue(this.control.value.charAt(0).toUpperCase() + this.control.value.slice(1));
    this.change.emit(this.control.value);
  }

  private getMessage(type: string, params: any) {
    return SmartInputComponent.errorMessages[type](params);
  }

}
