<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Prehľad číselníkov</h1>
    <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    <div *ngIf="!isLoadingData">
      <mat-card *ngFor="let item of enumData" class="parent clickable" routerLink="/enum-detail/{{item}}"> 
        <mat-card-content>
          <div class="info-wrapper">
            <i class="material-icons app-btn-icon">list</i>
            <div class="data-wrapper">
              <h3>{{item}}</h3>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
