import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TransformTypes } from '../../inputs/smart-input/smart-input.component';
import { UniModel } from '../../../types/types';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.css']
})
export class VehicleFormComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() vehicleTypeOptions: UniModel[];
  transformTypes = TransformTypes;
  constructor() { }

  ngOnInit() { }

}
