import { Injectable, Inject } from '@angular/core';
import { UniModel, User, Record, SafeRangerInfo, PendingRegistration, Visit, Token, SentUserInvitation, Guard, PAManagingOrgan, EnumGenericType, CoordinatorWithPAMs } from '../types/types';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { map } from 'rxjs/operators';
import { formatProtectedAreaName, mapBERecord, mapBEVisit } from '../utils/utils';
import { MessageType, MessageService } from './message.service';
import { ConfigService } from './config.service';
import 'rxjs/Rx' ;
import { LogType } from './logging.service';
import { IDataService } from './i-data-service';
import { ILoggingServiceToken, ILoggingService } from './i-logging-service';

export const REGION = 'region';
export const SOLVING_WAY = 'solvingWay';
export const VEHICLE_TYPE = 'vehicleType';
export const DISTRICT = 'district'
export const PROTECTED_AREA = 'protectedArea';
export const PROTECTION_LEVEL = 'protectionLevel';
export const DELIQUENCY_TYPE = 'deliquencyType';
export const ID_CARD_TYPE = 'idCardType';

interface RecordEnumsStorage {
  [key: string]: UniModel[]
  pams?: PAManagingOrgan[];
} 

@Injectable()
export class DataService implements IDataService {
  private errorMessageId: number;
  private recordEnumsStorage: RecordEnumsStorage = {};
  private _isLoggingIn: boolean = false;
  private _loggingInError: string = '';
  private _user: User;

  public isOffline: boolean;
  public isTokenLogin: boolean;

  // BASE_URL = ConfigService.settings.apiBaseUrl; //'http://localhost:8000/api/'; 

  CHECK_PERSON_URL = this.BASE_URL + 'records/filter';
  LOGIN_URL = this.BASE_URL + 'auth/login';
  USER_URL = this.BASE_URL + 'user';
  RECORDS_URL = this.BASE_URL + 'records';
  RECORD_DETAIL_URL = this.BASE_URL + 'record-detail';
  STORE_RECORD_URL = this.BASE_URL + 'records/store';

  REGIONS_URL = this.BASE_URL + 'regions';
  SOLVING_WAYS_URL = this.BASE_URL + 'solving-ways';
  VEHICLE_TYPES_URL = this.BASE_URL + 'vehicle-types';
  DISTRICTS_URL = this.BASE_URL + 'districts';
  PROTECTED_AREAS_URL = this.BASE_URL + 'protected-areas';
  PROTECTION_LEVELS_URL = this.BASE_URL + 'protection-levels';
  DELIQUENCY_TYPES_URL = this.BASE_URL + 'deliquency-types';
  ID_CARD_TYPES_URL = this.BASE_URL + 'id-card-types';

  PERSON_URL = this.BASE_URL + 'person';
  RANGERS_URL = this.BASE_URL + 'ranger-names';
  USERS_URL = this.BASE_URL + 'users';
  USER_BY_ID = this.BASE_URL + 'userById';
  UPDATE_ACTIVATION_URL = this.BASE_URL + 'update-activation';
  UPDATE_PERMISSION_URL = this.BASE_URL + 'update-permission';
  UPDATE_TERRITORIY_SCOPE_URL = this.BASE_URL + 'update-territory-scope';
  UPDATE_COORDINATORS_PAMS_URL = this.BASE_URL + 'update-coordinators-pams';
  GET_OFFICERS_URL = this.BASE_URL + 'officers';
  UPDATE_MULTI_USER_PAM = this.BASE_URL + 'update-multi-user-pam';

  SEND_NEW_USER_INVITE_URL = this.BASE_URL + 'send-user-invite';
  GET_PENDING_REGISTRATIONS_URL = this.BASE_URL + 'get-pending-registrations';
  ALLOW_FINISHING_REGISTRATION_URL = this.BASE_URL + 'allow-registration';
  DISALLOW_FINISHING_REGISTRATION_URL = this.BASE_URL + 'disallow-registration';
  VERIFY_REGISTRATION_URL = this.BASE_URL + 'verify-registration';
  SEND_REGISTRATION_INVITATION_URL = this.BASE_URL + 'send-registration-application';
  GET_INVITATION_EMAIL_URL = this.BASE_URL + 'get-invitation-email';
  SEND_PASSWORD_RESET_MAIL_URL = this.BASE_URL + 'send-password-reset';
  GET_EMAIL_FOR_RESET_PASSWORD_URL = this.BASE_URL + 'get-password-reset-email';
  STORE_NEW_PASSWORDS_URL = this.BASE_URL + 'store-new-passwords';
  CREATE_PASSWORD_URL = this.BASE_URL + 'create-user';
  
  GET_GUARD_FOR_VISIT = this.BASE_URL + 'get-guard-for-visit';
  STORE_VISIT_URL = this.BASE_URL + 'store-visit';
  GET_VISITS_URL = this.BASE_URL + 'visits';
  GET_ALL_VISITS_URL = this.BASE_URL + 'all-visits';
  GET_RECORDS_FOR_VISITS_URL = this.BASE_URL + 'records-for-visit';

  GET_PROTECTED_AREAS_WITH_DISTRICTS_URL = this.BASE_URL + 'protected-areas-district';
  GET_PROTECTED_AREA_BY_ID_URL = this.BASE_URL + 'protected-area';
  GET_RECORDS_FOR_AREA_URL = this.BASE_URL + 'records-for-area';
  GET_VISITS_FOR_AREA_URL = this.BASE_URL + 'visits-for-area';
  UPDATE_VISIT_DESCRIPTION = this.BASE_URL + 'update-visit-description';

  DOWNLOAD_ACTIVITY_REPORT_URL = this.BASE_URL + 'pdf';
  GET_SENT_USER_INVITATIONS_URL = this.BASE_URL + 'get-sent-invitations';
  CANCEL_USER_INVITATION_URL = this.BASE_URL + 'cancel-user-invitation';
  REFRESH_USER_INVITATION_URL = this.BASE_URL + 'refresh-user-invitation';
  HEALTH_CHECK_URL = this.BASE_URL + 'health-check';

  DOWNLOAD_RECORD_NOTICE_URL = this.BASE_URL + 'record-notice';
  PAM_ORGANS_URL = this.BASE_URL + 'pam-organs';
  COORDINATORS_URL = this.BASE_URL + 'coordinators-with-pams';
  DISTRICTS_FOR_PAM_URL = this.BASE_URL + 'districts-for-pam';

  LOGS_URL = this.BASE_URL + 'logs';

  ENUMS_URL = this.BASE_URL + 'enums';
  ENUM_DETAIL_URL = this.BASE_URL + 'enum-detail';
  EDIT_ENUM_DATA_URL = this.BASE_URL + 'edit-enum-data';
  DELETE_ENUM_DATA_URL = this.BASE_URL + 'delete-enum-data';
  CREATE_ENUM_DATA_URL = this.BASE_URL + 'create-enum-data';

  DELETE_VISIT_URL = this.BASE_URL + 'delete-visit';

  public get user() {
    if (this._user) {
      return this._user;
    }
  }
  public set user(user: User) { this._user = user};

  public get isLoggingIn() { return this._isLoggingIn; }
  private get BASE_URL() {return ConfigService.settings.apiBaseUrl; }

  clearLoggingError() {
    this.messageService.removeMessage(this.errorMessageId);
    this.errorMessageId = null;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private messageService: MessageService,
    @Inject(ILoggingServiceToken) private ls: ILoggingService
  ) {}

  updateMultiUserPam(pamId: string, userIds: string[]): Promise<boolean> {
    return this.http.post<boolean>(this.UPDATE_MULTI_USER_PAM, {pamId: pamId, userIds: userIds}).toPromise();
  }

  getEnums(): Promise<EnumGenericType[]> {
    return this.http.post<EnumGenericType[]>(this.ENUMS_URL, {}).toPromise();
  }
  getEnumDetail(enumType: string): Promise<EnumGenericType[]> {
    return this.http.post<EnumGenericType[]>(this.ENUM_DETAIL_URL, {enumType: enumType}).toPromise();
  }
  editEnumData(enumData: EnumGenericType, enumType: string) {
    return this.http.post<any>(this.EDIT_ENUM_DATA_URL, {enumData: enumData, enumType: enumType}).toPromise();
  }
  deleteEnumData(enumDataId: string, enumType: string) {
    return this.http.post<any>(this.DELETE_ENUM_DATA_URL, {enumDataId: enumDataId, enumType: enumType}).toPromise();
  }
  createEnumData(enumData: EnumGenericType, enumType: string) {
    return this.http.post<any>(this.CREATE_ENUM_DATA_URL, {enumData: enumData, enumType: enumType}).toPromise();
  }

  doOfflineCheck() {
    return this.http.get<any>(this.HEALTH_CHECK_URL).toPromise().then(
      (response) => {
        return false;
      }, 
      (err) => {
        return true;
      });
  }

  isUserLoggedIn(): boolean {
    return this.user != null;
  }

  private getCahceStorageEnum(key: string, dataUrl: string): Promise<UniModel[]> {
    if (this.recordEnumsStorage[key] == null) {
      return this.http.post<UniModel[]>(dataUrl, {}).toPromise().then((data) => {
        this.recordEnumsStorage[key] = data;
        return data;
      });
    } else {
      return new Promise<UniModel[]>((resolve) => {
        return resolve(this.recordEnumsStorage[key]);
      });
    }
  }

  getRegionOptions(userId?: string): Promise<UniModel[]> {
    return userId !== undefined 
      ? this.http.post<UniModel[]>(this.REGIONS_URL, {userId: userId}).toPromise()
      : this.getCahceStorageEnum(REGION, this.REGIONS_URL);
  }

  getAllPAMOrgans(): Promise<PAManagingOrgan[]> {
    if (this.recordEnumsStorage.pams == null) {
      return this.http.post<PAManagingOrgan[]>(this.PAM_ORGANS_URL, {})
      .toPromise().then((res) => {
        this.recordEnumsStorage.pams = res;
        return res;
      });
    } else {
      return new Promise<PAManagingOrgan[]>((resolve) => {
        return resolve(this.recordEnumsStorage.pams);
      });
    }





    return this.http.post<PAManagingOrgan[]>(this.PAM_ORGANS_URL, {}).toPromise();
  }

  getCoordinatorsWithPams(): Promise<CoordinatorWithPAMs[]> {
    return this.http.post<CoordinatorWithPAMs[]>(this.COORDINATORS_URL, {}).toPromise();
  }

  getSolvingWayOptions(): Promise<UniModel[]> {
    return this.getCahceStorageEnum(SOLVING_WAY, this.SOLVING_WAYS_URL);
  }

  getVehicleTypeOptions(): Promise<UniModel[]> {
    return this.getCahceStorageEnum(VEHICLE_TYPE, this.VEHICLE_TYPES_URL);
  }

  getDistrictOptions(regionId?: string, userId?: string): Promise<UniModel[]> {
    return regionId == null 
      ? this.getCahceStorageEnum(DISTRICT, this.DISTRICTS_URL)
      : this.http.post<UniModel[]>(this.DISTRICTS_URL, {regionId: regionId, userId: userId}).toPromise();
  }

  getProtectedAreaOptions(districtId?: string, effectiveDate?: Date): Promise<UniModel[]> {
    if (districtId != null) {
      return this.fetchAndProcessProtectedAreaOptions(false, districtId, effectiveDate);
    } 
    if (this.recordEnumsStorage[PROTECTED_AREA] == null) {
      return this.fetchAndProcessProtectedAreaOptions(true);
    } else {
      return new Promise<UniModel[]>((resolve) => {
        return resolve(this.recordEnumsStorage[PROTECTED_AREA]);
      });
    }
  }

  private fetchAndProcessProtectedAreaOptions(saveIntoChache: boolean, districtId?: string, effectiveDate?: Date): any {
    return this.http.post<any>(this.PROTECTED_AREAS_URL, {districtId: districtId, effectiveDate: effectiveDate})
      .pipe(
        map((val) => { return val.map((dType) => { return {id: dType.id, name: formatProtectedAreaName(dType) }})})
      )
      .toPromise().then((res) => {
        if (saveIntoChache) {
          this.recordEnumsStorage[PROTECTED_AREA] = res;
        }
        return res;
      });
  }

  getProtectionLevelOptions(protectedAreaId?: string): Promise<UniModel[]> {
    return protectedAreaId == null 
      ? this.getCahceStorageEnum(PROTECTION_LEVEL, this.PROTECTION_LEVELS_URL)
      : this.http.post<UniModel[]>(this.PROTECTION_LEVELS_URL, {protectedAreaId: protectedAreaId}).toPromise();
  }

  getDeliquencyTypelOptions(protectionLevel?: string, effectiveDate?: Date): Promise<UniModel[]> {
    if (protectionLevel != null) {
      return this.fetchAndProcessDeliquencyTypeOptions(false, protectionLevel, effectiveDate);
    } 
    if (this.recordEnumsStorage[DELIQUENCY_TYPE] == null) {
      return this.fetchAndProcessDeliquencyTypeOptions(true);
    } else {
      return new Promise<UniModel[]>((resolve) => {
        return resolve(this.recordEnumsStorage[DELIQUENCY_TYPE]);
      });
    }
  }

  private fetchAndProcessDeliquencyTypeOptions(saveIntoChache: boolean, protectionLevel?: string, effectiveDate?: Date): any {
    return this.http.post<any>(this.DELIQUENCY_TYPES_URL, {protectionLevel: protectionLevel, effectiveDate: effectiveDate})
      .pipe(
        map((val) => { return val.map((dType) => { return {id: dType.id, name: dType.description, law_part_bonded: dType.law_part_bonded}})})
        ) 
      .toPromise().then((res) => {
        if (saveIntoChache) {
          this.recordEnumsStorage[DELIQUENCY_TYPE] = res;
        }
        return res;
      });
  }

  getIdCardTypesOptions(): Promise<UniModel[]> {
    if (this.recordEnumsStorage[ID_CARD_TYPE] == null) {
      return this.http.post<any>(this.ID_CARD_TYPES_URL, {})
      .pipe(
        map((val) => { return val.map((dType) => { return {id: dType.id, name: dType.document_type }})})
      )
      .toPromise().then((res) => {
        this.recordEnumsStorage[ID_CARD_TYPE] = res;
        return res;
      });
    } else {
      return new Promise<UniModel[]>((resolve) => {
        return resolve(this.recordEnumsStorage[ID_CARD_TYPE]);
      });
    }
  }

  getGenderOptions(): Promise<UniModel[]> {
    const enums = [
      {
        id: '1',
        name: 'Muž'
      },
      {
        id: '2',
        name: 'Žena'
      },
      {
        id: null,
        name: ''
      }
    ];
    return new Promise<UniModel[]>((resolve) => {
      return resolve(enums);
    });
  }

  doTokenLogin(token: Token, redirectUrl: string) {
    this._isLoggingIn = true;
    this.isTokenLogin = true;
    this.user = new User();
    this.user.token = token;
    return this.http.post<User>(this.USER_URL, {}).subscribe(userResponse => {
      this.user = userResponse;
      this.user.token = new Token();
      this.user.token.token = token.token;
      this.user.token.expirationDate = new Date(token.expirationDate);
      this.setLogOutTimeOut(this.user.token.expirationDate);
      this.getInitialData();
      this._isLoggingIn = false;
      this.messageService.replaceFirstMessage('Vitajte, ' + userResponse.name + '.', MessageType.SUCCESS);
      this.router.navigate([redirectUrl]);
    }, (error) => {
      // just "ignore" the error, reset initial state, and let user log in old-fashioned way
      this._isLoggingIn = false;
      this.user = null;
      this.isTokenLogin = false;
      localStorage.removeItem('tokenObj');
    });
  }

  doLogin(loginName: string, pass: string, errorCallback?: any, redirectUrl?: string) {
    this._isLoggingIn = true;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.post<any>(this.LOGIN_URL, { email: loginName, password: pass }, options).subscribe((response) => {
      this.user = new User();

      if (response.success == false && response.message == 'not-activated') {
        const msg = 'Do tohto účtu nie je možné sa prihlásiť, kontaktujte administrátora.';
        this.errorMessageId = this.messageService.replaceFirstMessage(msg, MessageType.ERROR);
        this._isLoggingIn = false;
        if (errorCallback != null) {
          errorCallback(new Error(response.message));
        }
        return null;
      }
      
      this.user.token.token = response.token;
      return this.http.post<User>(this.USER_URL, {}).subscribe(userResponse => {
        this.user = userResponse;
        this.user.token = new Token();
        this.user.token.token = response.token;
        this.user.token.expirationDate = new Date(response.expiry * 1000);
        localStorage.setItem('lastLogin', loginName);
        localStorage.setItem('tokenObj', JSON.stringify(this.user.token));
        this.setLogOutTimeOut(this.user.token.expirationDate);
        this.getInitialData();
        this._isLoggingIn = false;
        this.messageService.replaceFirstMessage('Vitajte, ' + userResponse.name + '.', MessageType.SUCCESS);
        const rUrl = redirectUrl ? redirectUrl : 'home';
        this.router.navigate([rUrl]);
      });
    },
    (error) => {
      switch (error.status) {
        case 422:
          const msgPass = 'Nesprávne meno alebo heslo.';
          this.errorMessageId = this.messageService.replaceFirstMessage(msgPass, MessageType.ERROR);
          break;
      
        default:
        const msg = 'Prihlásenie sa nepodarilo, skúste to, prosím, neskôr.';
          this.ls.logError(msg, error);
          this.errorMessageId = this.messageService.replaceFirstMessage(msg, MessageType.ERROR);
          break;
      }
      this._isLoggingIn = false;
      if (errorCallback != null) {
        errorCallback(error);
      }
    });
  }

  doLogout(automaticLogout?: boolean, message?: string) {
    if (automaticLogout == false) {
      this.ls.log(LogType.MANUAL_LOGOUT, 'User logs out', this.user.id);
    }
    
    if (message == null) {
      message = 'Odhlásenie prebehlo úspešne.';
    }
    this.user = null;
    localStorage.removeItem('tokenObj');
    this.messageService.replaceFirstMessage(message, MessageType.INFO);
    this.router.navigate(['login']);
    // TODO invalidate token on BE
  }

  setLogOutTimeOut(logOutAt: Date) {
    const now = new Date();
    const difference = logOutAt.getTime() - now.getTime();
    console.log(`Will automatically log out after ${difference / 60000} minutes.`);
    setTimeout(
      () => {
        console.log('Logging out after token expiration...');
        this.ls.log(LogType.AUTOMATIC_LOGOUT, 'User have been logged out automatically', this.user.id);
        this.doLogout(true, 'Prebehlo automatické odhlásenie zo systému.');
      },
      difference
    );
  }

  checkPerson(personalOrName: string): any {
    return this.http.post<any>(this.CHECK_PERSON_URL, {criteria: personalOrName});
    // TODO process result data here, not in check result component
  }

  storeRecord(record: Record): Promise<boolean> {
    return this.http.post<boolean>(this.STORE_RECORD_URL, {record: record}).toPromise();
    // return new Promise<boolean>((resolve) => {
    //   return resolve(true);
    // });
  }

  getPerson(personId: string): Promise<any> {
    // TODO process result data here
    return this.http.post<any>(this.PERSON_URL, {personId: personId}).toPromise();
  }

  getRecords(): any {

    return this.http.post(this.RECORDS_URL, {});
    // TODO process results here, not in listing component
  }

  getRecordDetail(id: string): Promise<Record> {
    return this.http.post<any>(this.RECORD_DETAIL_URL, {id: id}).toPromise().then((beRecord) => {
      return mapBERecord(beRecord);
    });
  }

  getRangerNames(districtId: string): Promise<SafeRangerInfo[]> {
    return this.http.post<SafeRangerInfo[]>(this.RANGERS_URL, {districtId: districtId}).toPromise();
  }

  sendNewUserEmailInvite(emailAddress: string, isOfficer: boolean, managingPamID: string) {
    return this.http.post(this.SEND_NEW_USER_INVITE_URL, {email: emailAddress, isOfficer: isOfficer, managingPamID: managingPamID}).toPromise();
  }

  getUsers() {
    return this.http.post<User[]>(this.USERS_URL, {}).toPromise();
  }

  updateActivationStatusOfUser(userId: string, activated: boolean) {
    return this.http.post<any>(this.UPDATE_ACTIVATION_URL, {userId: userId, activated: activated}).toPromise();
  }

  updatePermissionOfUser(userId: string, newPermission: number) {
    return this.http.post<any>(this.UPDATE_PERMISSION_URL, {userId: userId, newPermission: newPermission}).toPromise();
  }

  updateTerritoryScopeForUser(userId: string, newDistrictIds: string[]) {
    return this.http.post<boolean>(this.UPDATE_TERRITORIY_SCOPE_URL, {userId: userId, newDistrictIds: newDistrictIds}).toPromise();
  }
  
  updateCoordinatorsPams(userId: string, newPamIds: string[]) {
    return this.http.post<boolean>(this.UPDATE_COORDINATORS_PAMS_URL, {userId: userId, newPamIds: newPamIds}).toPromise();
  }
  getPendingRegistrations(): Promise<PendingRegistration[]> {
    return this.http.post<PendingRegistration[]>(this.GET_PENDING_REGISTRATIONS_URL, {}).toPromise();
  }
  getOfficers(): Promise<User[]> {
    return this.http.post<User[]>(this.GET_OFFICERS_URL, {}).toPromise(); 
  }

  allowFinishingOfRegistration(registrationId: string, authRank: string) {
    return this.http.post<boolean>(this.ALLOW_FINISHING_REGISTRATION_URL, {id: registrationId, authRank: authRank}).toPromise();
  }
  
  disallowFinishingOfRegistration(registrationId: string) {
    return this.http.post<boolean>(this.DISALLOW_FINISHING_REGISTRATION_URL, {id: registrationId}).toPromise();
  }
  
  verifyRegistration(registrationId: string, approve: boolean, districtIds: string[]) {
    return this.http.post<boolean>(this.VERIFY_REGISTRATION_URL, {id: registrationId, approve: approve, districtIds: districtIds}).toPromise();
  }

  createUser(user: User): Promise<{success: boolean, message: string}> {
    return this.http.post<{success: boolean, message: string}>(this.CREATE_PASSWORD_URL, {user: user}).toPromise();
  } 

  sendRegistrationApplication(registration: PendingRegistration): Promise<boolean> {
    return this.http.post<boolean>(this.SEND_REGISTRATION_INVITATION_URL, {registration: registration}).toPromise();
  }

  getInvitationEmailForToken(token: string) {
    return this.http.post<any>(this.GET_INVITATION_EMAIL_URL, {token: token}).toPromise();
  }

  sendResetPasswordLink(email: string) {
    return this.http.post<any>(this.SEND_PASSWORD_RESET_MAIL_URL, {email: email}).toPromise();
  }

  getEmailForResetPasswordToken(token: string) {
    return this.http.post<{success: boolean; email: string}>(this.GET_EMAIL_FOR_RESET_PASSWORD_URL, {token: token}).toPromise();
  }
  
  getSentUserInvitations(): Promise<SentUserInvitation[]> {
    return this.http.post<any>(this.GET_SENT_USER_INVITATIONS_URL, {}).toPromise()
    .then((response) => {
      return response.invitations.map((beInv) => {
        let dateSent: Date = new Date(beInv.updated_at);
        const now = new Date();
        let validHoursRemaining = response.validHours - Math.abs(dateSent.getTime() - now.getTime()) / 3600000;
        return {
          id: beInv.id,
          email: beInv.email,
          dateSent: dateSent,
          validHoursRemaining: validHoursRemaining,
          sentBy: `${beInv.invitator.name} ${beInv.invitator.surname}`
      }
      });
     
    });
  }

  updateVisitDescription(visitId: string, newDescription: string) {
    return this.http.post<any>(this.UPDATE_VISIT_DESCRIPTION, {visitId: visitId, newDescription: newDescription}).toPromise();
  }

  cancelRegistrationInvitation(id: string) {
    return this.http.post<any>(
      this.CANCEL_USER_INVITATION_URL,
      {
        id: id,
      }
    ).toPromise();
  }

  refreshRegistrationInvitation(id: string) {
    return this.http.post<any>(
      this.REFRESH_USER_INVITATION_URL,
      {
        id: id,
      }
    ).toPromise();
  }

  sendNewPasswords(token: string, password: string, repeatPassword: string) {
    return this.http.post<{success: boolean; email?: string; message?: string}>(
      this.STORE_NEW_PASSWORDS_URL,
      {
        token: token, 
        password: password, 
        repeatPassword: repeatPassword
      }
    ).toPromise();
  }

  getGuardForVisit(date: Date, district: string, protectedArea: string) {
    return this.http.post<Guard[]>(
      this.GET_GUARD_FOR_VISIT, 
      {
        date: date,
        protectedArea: protectedArea,
        district: district
      }
    ).toPromise();
  }

  storeVisit(visit: Visit) {
    return this.http.post<boolean>(this.STORE_VISIT_URL, { visit: visit }).toPromise();
  }
  
  getVisits(userId?: string) {
    return this.http.post<any>(this.GET_VISITS_URL, {userId: userId}).toPromise().then((result) =>{
      return {
        visits: result.visits.map((vis) => { 
          return mapBEVisit(vis)
        }),
        responseUser: result.responseUser  
        }
    });
  }

  getAllVisits() {
    return this.http.post<any>(this.GET_ALL_VISITS_URL, {}).toPromise().then((result) =>{
      return result.map((vis) => { 
          return mapBEVisit(vis)
        });
    });
  }

  getRecordsForVisit(visitIds: string[]) {
    return this.http.post<any>(this.GET_RECORDS_FOR_VISITS_URL, {visitIds: visitIds}).toPromise().then((result) =>{
      return result.map((vis) => { 
        return mapBERecord(vis);
      });
    });
  }

  getProtectedAreasWithDistrict() {
    return this.http.post<any>(this.GET_PROTECTED_AREAS_WITH_DISTRICTS_URL, {}).toPromise().then((result) =>{
      return result.map((pArea) => { 
        let ds = pArea.districts.map(district => district.name);
        return {
          id: pArea.id,
          name: formatProtectedAreaName(pArea),
          district: ds.join(', '),
          isLarge: pArea.protected_area_type.is_large == 1,
          validFrom: pArea.valid_from_incl,
          validTo: pArea.valid_until_incl,
        };
      });
    });
  }

  getProtectedAreaById(id: string) {
    return this.http.post<any>(this.GET_PROTECTED_AREA_BY_ID_URL, {id: id}).toPromise().then((result) =>{
      return result;
    });
  }

  getRecordsForProtectedArea(id: string) {
    return this.http.post<any>(this.GET_RECORDS_FOR_AREA_URL, {id: id}).toPromise().then((result) =>{
      return result.map((vis) => { 
        return mapBERecord(vis);
      });
    });
  }
  
  getVisitsForProtectedArea(id: string) {
    return this.http.post<any>(this.GET_VISITS_FOR_AREA_URL, {id: id}).toPromise().then((result) =>{
      return result.map((vis) => { 
        return mapBEVisit(vis)
      });
    });
  }

  downloadActivityReportPdf(user: User | SafeRangerInfo, ids: string[], dateFrom: Date, dateTo: Date) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.post(this.DOWNLOAD_ACTIVITY_REPORT_URL, {userId: user.id, ids: ids, dateFrom: dateFrom, dateTo: dateTo}, { headers: headers, responseType: 'arraybuffer' });
  }

  getLogs(daysBack: number) {
    return this.http.post<any>(this.LOGS_URL, {daysBack: daysBack}).toPromise();
  }

  downloadRecordNoticePdf(recordId: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.post(this.DOWNLOAD_RECORD_NOTICE_URL, {recordId: recordId}, { headers: headers, responseType: 'arraybuffer' });
  }

  getUserById(userId: string): Promise<User> {
    return this.http.post<User>(this.USER_BY_ID, {userId: userId}).toPromise();
  }

  getDistrictsForPam(id: string): Promise<UniModel[]> {
    return this.http.post<UniModel[]>(this.DISTRICTS_FOR_PAM_URL, {id: id}).toPromise();
  }

  deleteVisit(visitId: string, guardId?: string): Promise<boolean> {
    return this.http.post<boolean>(this.DELETE_VISIT_URL, {visitId: visitId, guardId: guardId}).toPromise();
  }


  private getInitialData() {
    this.getRegionOptions();
    this.getSolvingWayOptions();
    this.getVehicleTypeOptions();
    this.getDistrictOptions();
    this.getProtectedAreaOptions();
    this.getProtectionLevelOptions();
    this.getDeliquencyTypelOptions();
    this.getIdCardTypesOptions();
    this.getAllPAMOrgans();
  }
}
