import { Component, Inject, OnInit } from '@angular/core';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { EnumGenericType } from '../../types/types';

@Component({
  selector: 'app-show-enums-page',
  templateUrl: './show-enums-page.component.html',
  styleUrls: ['./show-enums-page.component.css']
})
export class ShowEnumsPageComponent implements OnInit {

  isLoadingData = false;
  enumData: EnumGenericType[] = [];

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) {}

  ngOnInit() {
    this.isLoadingData = true;
    this.dataService.getEnums().then((data) => {
      this.enumData = data;
      this.isLoadingData = false;
    });
  }

}
