import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UniModel } from '../../../types/types';
import { removeDiacritics } from '../../../utils/utils';

@Component({
  selector: 'app-smart-autocomplete',
  templateUrl: './smart-autocomplete.component.html',
  styleUrls: ['./smart-autocomplete.component.css']
})
export class SmartAutocompleteComponent implements OnChanges {
  @Input() placeholder: string = "Vyberte hodnotu";
  @Input() allOptions: UniModel[] = [];
  @Input() clearAfterSelect: boolean = false;
  @Input() control: FormControl = null;
  @Input() required: boolean = true;
  
  @Output() changeEvent: EventEmitter<UniModel> = new EventEmitter();

  formControl: FormControl;
  filteredOptions: UniModel[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl == null) {
      const validators = this.required ? [Validators.required] : [];
      this.formControl = this.control == null ? new FormControl('', validators) : this.control;
    }
    this.filteredOptions = changes.allOptions.currentValue;
    if (this.allOptions == null || this.allOptions.length == 0) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  onOptionSelected(event) {
    this.changeEvent.emit(this.formControl.value);
    if (this.clearAfterSelect) {
      this.formControl.reset();
      this.filteredOptions = this.allOptions.slice();
    }
  }

  onChange(event) {
    const option = event.target.value;
    this.filteredOptions = option ? this._filterOptions(option) : this.allOptions.slice()
  }

  displayFn(option): string {
    return option ? option.name : option;
  } 

  private _filterOptions(value): UniModel[] {
    const filterValue = removeDiacritics(value.toLowerCase());
    return this.allOptions.filter(option => removeDiacritics(option.name.toLowerCase()).indexOf(filterValue) > -1);
  }

}
