import { SEX, Address, Person, Record, CarInfo, Visit, SafeRangerInfo, ValueInTime, User, EnumGenericType } from "../types/types";
import { LogType } from "../services/logging.service";

export function getDateFromPersonal(personalId) {
    if (personalId == null) {
        return null;
    }
    const strPersonal: string = personalId.toString();

    if (strPersonal.toString().length < 6) {
        return null;
    }

    let year = strPersonal.substr(0, 2);
    const intYear = Number.parseInt(year);
    if (intYear < 100) {
        year = '19' + year;
    } else {
        year = '20' + year;
    }

    let month = strPersonal.substr(2, 2);
    const firstMonthChar = month.substr(0, 1);
    let intMonth = Number.parseInt(month);
    if (Number.parseInt(firstMonthChar) >= 5) { // female
        intMonth = intMonth - 50;
        month = (intMonth).toString();
    }
    if (month.length === 1) {
        month = '0' + month;
    }

    const day = strPersonal.substr(4, 2);
    const intDay = Number.parseInt(day);

    if (intMonth > 12 || intDay > 31) {
        return null;
    }

    const date = new Date();
    date.setDate(intDay);
    date.setMonth(intMonth - 1);
    date.setFullYear(Number.parseInt(year));

    return date;
}

export function getPersonalIdFromDate(date: Date, sex: SEX): string {
    if (date == null) {
        return '';
    }

    let result;
    result = date.getFullYear().toString().substr(2, 2);

    let monthInt = (date.getMonth() + 1);
    const constMonth = sex == SEX.MALE ? 0 : 50;
    monthInt += constMonth;
    const month = monthInt.toString();

    result += month.length === 1 ? '0' + month : month;
    const day = date.getDate().toString();
    result += day.length === 1 ? '0' + day : day;

    result += '0000';
    return result;
}

export function getSexFromPersonalId(personalId: number): SEX {
    return Number.parseInt(personalId.toString().charAt(2), 10) >= 5 ? SEX.FEMALE : SEX.MALE;
}

export function isObjectEmpty(obj: any): boolean {
    if (obj == null) {
        return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getHours(time: string): number {
    const hours = time.split(':')[0];
    return Number.parseInt(hours);
}

export function getMinutes(time: string): number {
    const hours = time.split(':')[1];
    return Number.parseInt(hours);
}

export function getDateTime(date: Date, time: string): Date {
    const resultDateTime: Date = date;
    resultDateTime.setHours(getHours(time));
    resultDateTime.setMinutes(getMinutes(time));
    return resultDateTime;
}

export function datesAreEqual(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear()
     && date1.getMonth() === date2.getMonth()
     && date1.getDate() === date2.getDate();
  }

export function mapBERecord(beRecord: any): Record {
    const carInfo:CarInfo = beRecord.vehicle_info 
    ? { 
        licenceNumber: beRecord.vehicle_info.licence_number,
        vehicleType: beRecord.vehicle_info.vehicle_type_id,
        color: beRecord.vehicle_info.color
    } 
    : null;

    return {
        id: beRecord.id,
        person: mapBEPerson(beRecord.person),
        date: beRecord.date,
        region: beRecord.district.region_id,
        district: beRecord.district_id,
        protectedArea: beRecord.protected_area_id,
        protectionLevel: beRecord.protection_level,
        deliquencyType: beRecord.delinquency_type_id,
        description: beRecord.delinquency_description,
        solvingWay: beRecord.sovling_way_type_id,
        sanctionAmount: beRecord.sanction_amount,
        carInfo: carInfo,
        feeTicketNumber: beRecord.fee_ticket_number,
        ranger: { name: beRecord.user.name, surname: beRecord.user.surname, id: beRecord.user.id },
    };
}

export function mapBEPerson(bePerson: any): Person {
    const address: Address = {
        street: bePerson.street,
        city: bePerson.city,
        zip: bePerson.zip,
        country: bePerson.country
    }
    return {
        id: bePerson.id,
        firstName: bePerson.name,
        lastName: bePerson.surname,
        personalId: bePerson.personal_id,
        birthDate: getDateFromPersonal(bePerson.personal_id),
        gender: null,
        nationality: bePerson.nationality,
        idCardType: bePerson.id_card_type_id,
        idCardNumber: bePerson.id_card_number,
        address: address,
    }
}

export function mapBEVisit(beVisit: any): Visit {
    let rangers: {ranger: SafeRangerInfo, description: string, visitId: string}[] = beVisit.visits.map((vis) => { return {ranger: vis.user, visitId: vis.id, description: (vis.description == null || vis.description == '' ? 'Bez popisu.' : vis.description)}});
    // all descriptions from all users will be displayed
    let description = '';
    beVisit.visits.forEach(vis => {
        description += vis.user.name + ' ' + vis.user.surname + ': ' + (vis.description == null || vis.description == '' ? 'Bez popisu.' : vis.description) + '<br>';
    });    
    
    let visitIds = beVisit.visits.map(visit => visit.id);
    
    return {
        id: null,
        visitIds: visitIds,
        guardId: beVisit.id,
        date: beVisit.date,
        protectedArea: beVisit.protected_area.id,
        district: beVisit.district.id,
        recordIds: [],
        userIds: [],
        rangers: rangers,
        description: description
    }
}

export function isUserManagedByMe(user: User | SafeRangerInfo, me: User) {
  if (me == null || user == null || user.managed_by == null) {
    return false;
  }
  let pams = me.coordinators_parent_p_a_organ;
  if (pams == null || pams.length == 0) {
    return false;
  }

  let pamIDs = pams.map(pam => pam.id);
  return pamIDs.includes(user.managed_by);
}

export function sortVisits(visits: Visit[]): Visit[] {
    return visits.sort((visA, visB) => {
      const visATime = new Date(visA.date).getTime();
      const visBTime = new Date(visB.date).getTime();
      return visBTime - visATime;
    });
}

export function sortRecords(records: Record[]): Record[] {
    return records.sort((recA, recB) => {
      const recATime = new Date(recA.date).getTime();
      const recBTime = new Date(recB.date).getTime();
      return recBTime - recATime;
    });
}

export function formatProtectedAreaName(beProtectedArea: any): string {
    return beProtectedArea.name + ' (' + beProtectedArea.protected_area_type.code + ')';
}

export function removeDiacritics(text: string): string {
    text = replaceAll(text, 'Á', 'A');
    text = replaceAll(text, 'Ä', 'A');
    text = replaceAll(text, 'Č', 'C');
    text = replaceAll(text, 'Ď', 'D');
    text = replaceAll(text, 'É', 'E');
    text = replaceAll(text, 'Í', 'I');
    text = replaceAll(text, 'Ĺ', 'L');
    text = replaceAll(text, 'Ľ', 'L');
    text = replaceAll(text, 'Í', 'N');
    text = replaceAll(text, 'Ň', 'N');
    text = replaceAll(text, 'Ó', 'O');
    text = replaceAll(text, 'Ô', 'O');
    text = replaceAll(text, 'Ŕ', 'R');
    text = replaceAll(text, 'Ř', 'R');
    text = replaceAll(text, 'Š', 'S');
    text = replaceAll(text, 'Ť', 'T');
    text = replaceAll(text, 'Ú', 'U');
    text = replaceAll(text, 'Ý', 'Y');
    text = replaceAll(text, 'Ź', 'Z');
    text = replaceAll(text, 'Ž', 'Z');

    text = replaceAll(text, 'á', 'a');
    text = replaceAll(text, 'ä', 'a');
    text = replaceAll(text, 'č', 'c');
    text = replaceAll(text, 'ď', 'd');
    text = replaceAll(text, 'é', 'e');
    text = replaceAll(text, 'í', 'i');
    text = replaceAll(text, 'ĺ', 'l');
    text = replaceAll(text, 'ľ', 'l');
    text = replaceAll(text, 'ń', 'n');
    text = replaceAll(text, 'ň', 'n');
    text = replaceAll(text, 'ó', 'o');
    text = replaceAll(text, 'ô', 'o');
    text = replaceAll(text, 'ŕ', 'r');
    text = replaceAll(text, 'ř', 'r');
    text = replaceAll(text, 'š', 's');
    text = replaceAll(text, 'ť', 't');
    text = replaceAll(text, 'ú', 'u');
    text = replaceAll(text, 'ý', 'y');
    text = replaceAll(text, 'ź', 'z');
    text = replaceAll(text, 'ž', 'z');
    return text;
}

export function downloadPdfFile(data, fileName: string) {
    var blob = new Blob([data], { type: 'application/pdf' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
     } else {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
     }
  }

export function groupDatesByDay(dates: string[]) {
    let map: { [s:string] : number} = {};

    dates.forEach((date) => {
        const day = getDayDate(new Date(date));
        if (map[day] == null) {
            map[day] = 0;
        }
        map[day]++;
    });
    
    return map;
}

export function getDayDate(date: Date): string {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

export function filterLogsToValueInTime(beLogs: any[], logType: LogType) : ValueInTime {
    return {
        valueName: logType,
        occurences: beLogs
            .filter((log) => {
                return (logType == LogType.ALL || logType == log.type) ? true : false;
            })
            .map((log) => {
                return log.created_at;
            })
      }
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

export function mapBEEnums(beEnum: any): EnumGenericType {
    return {
        id: beEnum.id,
        description: beEnum.description,
        lawPartBonded: beEnum.law_part_bonded,
        lawPartOffense: beEnum.law_part_offense,
        code: beEnum.code,
        name: beEnum.name,
        protectionLevel: beEnum.protection_level,
        validFromIncl: beEnum.valid_from_incl,
        validUntilIncl: beEnum.valid_until_incl,
        insideId: beEnum.inside_id,
        protectedAreaTypeId: beEnum.protected_area_type_id,
        protectiveZone: beEnum.protective_zone,
        city: beEnum.city,
        street: beEnum.street,
        zip: beEnum.zip,
        documentType: beEnum.document_type,
        shortcut: beEnum.shortcut,
        regionId: beEnum.region_id,
        managingOrganId: beEnum.managing_organ_id,
        districtId: beEnum.district_id,
        paManagingOrganId: beEnum.p_a_managing_organ_id,
        protectedAreaId: beEnum.protected_area_id,
        isLarge: beEnum.is_large,
    };
}

export function mapFEEnums(feEnum: any): any {
    return {
        id: feEnum.id,
        description: feEnum.description,
        law_part_bonded: feEnum.lawPartBonded,
        law_part_offense: feEnum.lawPartOffense,
        code: feEnum.code,
        name: feEnum.name,
        protection_level: feEnum.protectionLevel,
        valid_from_incl: feEnum.validFromIncl,
        valid_until_incl: feEnum.validUntilIncl,
        inside_id: feEnum.insideId,
        protected_area_type_id: feEnum.protectedAreaTypeId,
        protective_zone: feEnum.protectiveZone,
        city: feEnum.city,
        street: feEnum.street,
        zip: feEnum.zip,
        document_type: feEnum.documentType,
        shortcut: feEnum.shortcut,
        region_id: feEnum.regionId,
        managing_organ_id: feEnum.managingOrganId,
        district_id: feEnum.districtId,
        p_a_managing_organ_id: feEnum.paManagingOrganId,
        protected_area_id: feEnum.protectedAreaId,
        is_large: feEnum.isLarge,
    };
}