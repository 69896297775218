<mat-card class="parent">
  <mat-card-content>
    <div class="info-wrapper">
      <app-app-icon name="person" class="icon" [clickable]="false"></app-app-icon>
      <div class="data-wrapper">
        <h3>{{ record.person.firstName }} {{ record.person.lastName }}</h3>
        <p class="bold">{{ record.deliquencyType | recordEnumsPipe: 'deliquencyType' | async }}</p>
        <p>{{ record.date | date:'d.M.y o H:mm' }}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
