import { Component, OnInit, Input } from '@angular/core';
import { Message, MessageType } from '../../services/message.service';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.css']
})
export class MessageItemComponent implements OnInit {
  @Input() message: Message;
  constructor() { }

  ngOnInit() {
  }

  getClass() {
    let custClass = '';
    switch (this.message.type) {
      case MessageType.SUCCESS:
        custClass = 'success-message';  
        break;
      case MessageType.ERROR:
        custClass = 'error-message';  
        break;
      case MessageType.INFO:
        custClass = 'info-message';
        break;  
      default:
        throw new Error('Unsupported message type for message' + this.message);
    }

    return 'message ' + custClass;
  }

}
