import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { TransformTypes } from '../../inputs/smart-input/smart-input.component';
import { UniModel } from '../../../types/types';
import { IDataService, IDataserviceToken } from '../../../services/i-data-service';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.css']
})
export class PersonFormComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  transformTypes = TransformTypes;

  options: UniModel[] = [];

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
    this.dataService.getIdCardTypesOptions().then((options) => {
      this.options = options;
    }) 
  }

}
