import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd } from '../../../node_modules/@angular/router';
import { ILoggingServiceToken, ILoggingService } from './i-logging-service';

export enum MessageType {
  SUCCESS,
  ERROR,
  INFO
}

export interface Message {
  id: number;
  text: string;
  ttl: number;
  type: MessageType;
  hidden?: boolean;
}

@Injectable()
export class MessageService {
  private static counter: number = 1;
  private _messages: Message[] = [];
  startAccent: string;

  public get messages() { return this._messages; }

  constructor(private router: Router, @Inject(ILoggingServiceToken) private ls: ILoggingService) { 
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.handleRouteChange();
        }
  });
  }

  addMessage(text: string, type: MessageType, ttl:number = 1, hideAfter:number = 3000): number {
    const id = MessageService.counter++;
    this._messages.push({
      id: id,
      text: text,
      type: type,
      ttl: ttl,
      hidden: false
    });

    this.hideMessage(id, hideAfter);

    return id;
  }

  removeMessage(id: number) {
    this._messages = this._messages.filter(message => message.id != id);
  }

  hideMessage(id: number, hideAfter: number) {
    let msg = this._messages.find(el => el.id == id);
    if (hideAfter != null && hideAfter > 0 && msg.type == MessageType.SUCCESS) {
      setTimeout(
        () => {
          msg.hidden = true;
        }, 
        hideAfter)
    }
  }

  clearMessages() {
    this._messages = [];
  }

  replaceFirstMessage(text: string, type: MessageType, ttl = 1, hideAfter:number = 3000) {
    let id;
    if (this._messages.length == 0) {
      return this.addMessage(text, type, ttl, hideAfter);
    };

    this._messages[0].text = text;
    this._messages[0].ttl = ttl;
    this._messages[0].type = type;
    this._messages[0].hidden = false;
    this.startAccent = 'out';
    setTimeout(
      () => { 
        this.startAccent = 'in'; 
        setTimeout(() => { this.startAccent = ''; this.hideMessage(this._messages[0].id, hideAfter) }, 400)
      },
      200
    );
    return this._messages[0].id;
  }

  handleRouteChange() {
    this._messages = this._messages.filter((message) => {
      message.ttl--;
      return message.ttl >= 0 ? true : false;
    })
  }

}
