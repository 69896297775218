<h1 mat-dialog-title>Úprava oprávnenia používateľa {{ data.rangerName }}</h1>
<div mat-dialog-content>
  <div style="overflow: hidden;">
    <div *ngFor="let option of data.options">
      <mat-checkbox [(ngModel)]="option.checked">{{ option.name }}</mat-checkbox>
    </div>
  </div>
  
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Zrušiť </button>
  <button mat-button [mat-dialog-close]="data.options" cdkFocusInitial>Ok</button>
</div>