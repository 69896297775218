import { Component, OnInit, Inject } from '@angular/core';
import { LogMessage } from '../../types/types';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { removeDiacritics } from '../../utils/utils';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-show-logs-page',
  templateUrl: './show-logs-page.component.html',
  styleUrls: ['./show-logs-page.component.css']
})
export class ShowLogsPageComponent implements OnInit {
  inputControl: FormControl = new FormControl('');
  filterValue = '';
  logs: LogMessage[];
  filteredLogs: LogMessage[];
  isLoadingData;
  constructor(@Inject(IDataserviceToken) private ds: IDataService) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.ds.getLogs(30).then((response) => {
      this.isLoadingData = false;
      this.logs = response;
      this.filteredLogs = response;
    });

    this.inputControl.valueChanges.subscribe((val) => {
      this.onFilterChange(val);
    });
  }

  onFilterChange(filterValue: string) {
    this.filterValue = filterValue;
    this.applyFilters();
  }

  applyFilters() {
    // this.historyService.manageUsersFilter = filterValue;
    // this.filterRunning = true;
    let result = this.logs;
    if (this.filterValue != '') {
      const filter = removeDiacritics(this.filterValue.toLowerCase());
      result = result.filter(log => this.unifyLog(log).indexOf(filter) > -1);
    }

    // const dateFrom: Date = this.dateFrom.value;
    // const dateTo: Date = this.dateTo.value;
    // if (dateFrom != null && dateTo != null) {
    //   result = result.filter((visit: Visit) => {
    //     return new Date(visit.dateFrom) >= dateFrom && new Date(visit.dateFrom) <= dateTo;
    //   });
    // }

    // setTimeout(
    //   () => { 
    //     this.filterRunning = false;
    //     this.filteredVisits = this.sortVisits(result);
    //   },
    //   500)

    this.filteredLogs = result;
  }

  private unifyLog(log: LogMessage) {
    console.log(removeDiacritics(`${log.message} ${log.type} ${log.created_at} ${log.user != null ? log.user.name + ' ' + log.user.surname : ''} ${JSON.stringify(log.data)}`.toLowerCase()));
    return removeDiacritics(`${log.message} ${log.type} ${log.created_at} ${log.user != null ? log.user.name + ' ' + log.user.surname : ''} ${JSON.stringify(log.data)}`.toLowerCase());
  }

}
