import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CoordinatorWithPAMs, PAManagingOrgan, UniModel, User } from '../../../app/types/types';
import { IDataService, IDataserviceToken } from '../../../app/services/i-data-service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { removeDiacritics } from '../../utils/utils';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MessageService, MessageType } from '../../services/message.service';

@Component({
  selector: 'app-manage-coordinators-page',
  templateUrl: './manage-coordinators-page.component.html',
  styleUrls: ['./manage-coordinators-page.component.css']
})
export class ManageCoordinatorsPageComponent implements OnInit {
  allCoordinators: CoordinatorWithPAMs[] = [];
  allPams: PAManagingOrgan[] = [];
  filteredPams: Observable<PAManagingOrgan[]>;
  selectedPams: PAManagingOrgan[] = [];
  isLoadingData;
  isLoading;
  isEditMode: boolean = false;
  isAddingNew = false;
  filteredUsers: User[];
  allOfficers: User[];
  shouldExpand = {};
  
  pamCtrl = new FormControl();
  newUser: User;
  autocompleteOfficers: UniModel[] = [];
  @ViewChild('pamInput') pamInput: ElementRef<HTMLInputElement>;

  constructor(private messageService: MessageService, @Inject(IDataserviceToken) private dataService: IDataService) {
    this.filteredPams = this.pamCtrl.valueChanges.pipe(
      startWith(null),
      map((district: string | null) => district ? this._filter(district) : this.allPams.slice()));
  }

  ngOnInit(): void {
    this.isLoadingData = true;
    this.dataService.getCoordinatorsWithPams().then((ccs) => {
      this.allCoordinators = ccs;
      this.isLoadingData = false;
    });

    this.dataService.getAllPAMOrgans().then((pams) => {
      this.allPams = pams;
    });

    this.dataService.getOfficers().then((ofcs) => {
      this.allOfficers = ofcs; 
      this.autocompleteOfficers = this.allOfficers.map((user: User) => {
        return {
          id: user.id,
          name: `${user.surname} ${user.name}`
        }
      })
    });
  }

  onUserChange(user: UniModel) {
    this.newUser = this.allOfficers.find((officer: User) => {
      return officer.id == user.id;
    });
  }

  onAddNewClick() {

    this.isAddingNew = false;
    this.newUser.coordinators_parent_p_a_organ = [];
    this.allCoordinators.unshift(this.newUser);
    this.shouldExpand[this.newUser.id] = true;
    this.onOpen(this.newUser);
    setTimeout(() => {
      this.onEditIconClick(this.newUser);
    });

    this.messageService.replaceFirstMessage('Nezabudnite pridať priradené správy.', MessageType.INFO, 0);
  }

  onCancelNewClick () {
    this.isAddingNew = false;
  }

  onOpen(user: CoordinatorWithPAMs) {
    this.selectedPams = Array.from(user.coordinators_parent_p_a_organ);
    this.isEditMode = false;
  }

  onEditIconClick(user: CoordinatorWithPAMs) {
    this.isEditMode = true;
  }

  onCancel(user: CoordinatorWithPAMs) {
    this.isEditMode = false;
    this.selectedPams = Array.from(user.coordinators_parent_p_a_organ);
  }

  onSubmit(user: CoordinatorWithPAMs) {
    this.isEditMode = false;
    this.isLoading = true;
    let uniquePams: PAManagingOrgan[] = [];
    this.selectedPams.forEach((pam) => {
      let u = uniquePams.find(uD => uD.id == pam.id);
      if (u == null) {
        uniquePams.push(pam);
      }
    });
    this.dataService.updateCoordinatorsPams(user.id, uniquePams.map(p => p.id)).then(() => {
      this.isLoading = false;
      user.coordinators_parent_p_a_organ = uniquePams;
      this.messageService.replaceFirstMessage('Úspešne zmenené.', MessageType.SUCCESS, 0);
    });
  }

  remove(user: CoordinatorWithPAMs, indx): void {
    this.selectedPams.splice(indx, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedPams.push(event.option.value);
    this.pamInput.nativeElement.value = '';
    this.pamCtrl.setValue(null);
  }

  private _filter(value: any): PAManagingOrgan[] {
    if (value.name == null) {
      const filterValue = removeDiacritics(value.toLowerCase());
      return this.allPams.filter(pam => removeDiacritics(pam.name.toLowerCase()).includes(filterValue));
    } else {
      return this.allPams.filter(pam => pam.id === value.id);
    }
    
  }

}
