import { Component, OnInit } from '@angular/core';
import { SmartInputComponent } from '../smart-input/smart-input.component';

@Component({
  selector: 'app-smart-text-area',
  templateUrl: './smart-text-area.component.html',
  styleUrls: ['./smart-text-area.component.css']
})
export class SmartTextAreaComponent extends SmartInputComponent {

}
