import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Record } from '../../types/types';
import { mapBERecord } from '../../utils/utils';
import { HistoryService, RecordDetailOrigin } from '../../services/history.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-check-person-page',
  templateUrl: './check-person-page.component.html',
  styleUrls: ['./check-person-page.component.css']
})
export class CheckPersonPageComponent implements OnInit {

  inputControl = new FormControl(null, [Validators.required]);
  checkResults: Record[] = undefined;
  isLoadingData = false;
  changedAfterEmptySearch: boolean = false;

  public get isResultsToShow() { return this.checkResults != null && this.checkResults.length > 0 };
  public get shouldShowNoResults() { return this.checkResults != null && this.checkResults.length === 0 && !this.changedAfterEmptySearch};

  constructor(private historyService: HistoryService, @Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
    if (this.historyService.checkResults.length > 0) {
      this.checkResults = this.historyService.checkResults;
      this.inputControl.setValue(this.historyService.checkCriteria);
    }
  }

  onClick() {
    this.isLoadingData = true;
    this.dataService.checkPerson(this.inputControl.value).subscribe((checkResults) => {
      this.checkResults = checkResults.map((beResult) => { return mapBERecord(beResult)});
      this.historyService.checkResults = this.checkResults;
      this.historyService.checkCriteria = this.inputControl.value;
      this.historyService.recordDetailOrigin = RecordDetailOrigin.CheckResultPage;
      this.isLoadingData = false;
      this.changedAfterEmptySearch = false;
    }, (err) => {console.log(err)});
  }

  onInputChange(event) {
    if (this.checkResults != null && this.checkResults.length === 0) {
      this.changedAfterEmptySearch = true;
    }
  }

  onKey(event) {
    if (event.code == 'Enter') {
      this.onClick();
    }
  }
}