<app-back-button backRouterLink="/manage-protected-areas" backButtonText="Späť na správu území"></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Detail chráneného územia</h1>
    <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    <div *ngIf="!isLoadingData">
      <h1>{{ protectedArea.protected_area_type.name }} {{ protectedArea.name }}</h1>

      <mat-accordion>
        <mat-expansion-panel class="visit-item">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>Priestupkny z územia</h3>
            </mat-panel-title>
            <mat-panel-description>
              <h3>{{ protectedArea.name }}</h3> 
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngFor="let record of records" class="clickable" (click)="onRecordTileClick(record.id)">
            <app-record-list-tile [record]="record"></app-record-list-tile>
          </div>

          <div *ngIf="records.length == 0">
            <h3>Žiadne.</h3>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel class="visit-item">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>Návštevy územia</h3>
            </mat-panel-title>
            <mat-panel-description>
              <h3>{{ protectedArea.name }}</h3> 
            </mat-panel-description>
          </mat-expansion-panel-header>

            <mat-card class="visit-item" *ngFor="let visit of visits">
              <mat-card-content>
                <app-visit [visit]="visit" officerWatching="true"></app-visit>
              </mat-card-content>
            </mat-card>

            <div *ngIf="visits.length == 0">
              <h3>Žiadne.</h3>
            </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card-content>
  </mat-card>