<mat-card>
  <mat-card-content>
    <h2>Údaje o priestupku</h2>
    <div [formGroup]="parentFormGroup">
      <app-date-time-input [dateControl]="parentFormGroup.controls.date"></app-date-time-input>
      <div *ngIf="enums">
        <app-smart-select [control]="parentFormGroup.controls.region" placeholder="Kraj" [options]="enums.regionOptions"></app-smart-select>
        <app-smart-select [control]="parentFormGroup.controls.district" placeholder="Okres" [options]="enums.districtOptions"></app-smart-select>
        <app-smart-select [control]="parentFormGroup.controls.protectedArea" placeholder="Chránené územie" [options]="enums.protectedAreaOptions"></app-smart-select>
        <app-smart-select [control]="parentFormGroup.controls.protectionLevel" placeholder="Stupeň ochrany" [options]="enums.protectionLevelOptions"></app-smart-select>
        <app-smart-select [control]="parentFormGroup.controls.deliquencyType" placeholder="Typ priestupku" [options]="enums.deliquencyTypeOptions"></app-smart-select>
        <app-vehicle-form *ngIf="shouldShowVehicleForm" [parentFormGroup]="parentFormGroup.get('vehicleForm')" [vehicleTypeOptions]="vehicleTypeOptions"></app-vehicle-form>
        <app-smart-select [control]="parentFormGroup.controls.solvingWay" placeholder="Spôsob vyriešenia priestupku" [options]="enums.solvingWayOptions"></app-smart-select>
      </div>
      <app-smart-text-area [control]="parentFormGroup.controls.description" placeholder="Popis priestupku" [transformFunc]="transformTypes.capitalize" name="description"></app-smart-text-area>
      <app-smart-input [control]="parentFormGroup.controls.sanctionAmount" placeholder="Výška uloženej pokuty" type="number" name="sanctionAmount"></app-smart-input>
      <div *ngIf="shouldShowFeeTicketNumberInput">
        <app-smart-input [control]="parentFormGroup.controls.feeTicketNumber" placeholder="Séria a číslo pokutového bloku" name="feeTicketNumber"></app-smart-input>
      </div>

    </div>
  </mat-card-content>
</mat-card>