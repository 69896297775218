import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { HistoryService } from '../../services/history.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { EnumGenericType } from '../../types/types';
import { mapBEEnums } from '../../utils/utils';

@Component({
  selector: 'app-enum-detail-page',
  templateUrl: './enum-detail-page.component.html',
  styleUrls: ['./enum-detail-page.component.css']
})
export class EnumDetailPageComponent implements OnInit {

  enumData: any[] = [];
  enumType: string;

  get historyTableConfig() { return this.historyService.recordsListingPageConfig; }

  constructor(
    private historyService: HistoryService,
    private route: ActivatedRoute,
    @Inject(IDataserviceToken) private dataService: IDataService) {
      this.enumType = this.route.snapshot.params['enumType'];
    }

  ngOnInit() {
    //probably should add if statement for the succesful function execution
    const enumType = this.route.snapshot.params['enumType'];
    this.dataService.getEnumDetail(enumType).then((beEnumData) => {
      this.enumData = beEnumData.map(data => mapBEEnums(data));
    });
    
  }

}
