
import {tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { MessageService, MessageType } from './message.service';
import { Router } from '../../../node_modules/@angular/router';
import { IDataService, IDataserviceToken } from './i-data-service';
import { ILoggingServiceToken, ILoggingService } from './i-logging-service';
 
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private ms: MessageService, 
        private router: Router, 
        @Inject(ILoggingServiceToken) private ls: ILoggingService,
        @Inject(IDataserviceToken) private dataService: IDataService    
    ) { };
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.dataService.isUserLoggedIn()) {
            const token = this.dataService.user.token.token;
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {}, (err: any) => {
            // do not log error if there is error logging an error...
            if (err.url != null && err.url.endsWith('log-message')) {
                return;
            }
            if (err instanceof HttpErrorResponse) {
                
                // do not show error message on unsuccessfull token login
                if (err.status != 500 || !err.url.endsWith('user') || !this.dataService.isTokenLogin) {
                    console.error(err); 
                    this.ms.replaceFirstMessage('Došlo k neznámej chybe.', MessageType.ERROR);
                }
                
                if (err.status != 0) {
                    this.router.navigate(['home']);
                }
                // do not log incorect name or password
                if (err.status != 422 && err.url != null && !err.url.endsWith('login')) {
                    this.ls.logError(err.message, err);
                }
            } else {
                this.ls.logError(err.message, err);
            }
          }));
    }
}