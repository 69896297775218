<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Prehľad chránených území</h1>
    <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    <div *ngIf="!isLoadingData">
      <mat-form-field class="date-filter">
        <input matInput [matDatepicker]="picker" placeholder="Dátum" (dateInput)="onDateInputChange()" (click)="picker.open()" [formControl]="date" id="date" name="date" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi="true"></mat-datepicker>
      </mat-form-field>
      <div class="check-input-wrapper">
        <app-smart-input [control]="inputControl" name="filter" placeholder="Filter"></app-smart-input>
      </div>
      <div *ngFor="let pArea of filteredAreas">
          <mat-card class="parent clickable" routerLink="/protected-areas/{{pArea.id}}"> 
            <mat-card-content>
              <div class="info-wrapper">
                <app-app-icon [name]="pArea.isLarge ? 'landscape' : 'nature'" class="icon"></app-app-icon>
                <div class="data-wrapper">
                  <h3>{{ pArea.name}}</h3>
                  <p>Okres: <span class="bold">{{ pArea.district }}</span></p>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>