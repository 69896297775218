import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, ViewChild, Input, OnChanges, Output, EventEmitter, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { UniModel } from '../../types/types';
import { removeDiacritics } from '../../../app/utils/utils';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-territory-districts',
  templateUrl: './territory-districts.component.html',
  styleUrls: ['./territory-districts.component.css']
})
export class TerritoryDistrictsComponent implements OnChanges {
  @Input() isEditMode = false;
  selectable = false;
  separatorKeysCodes: number[] = [];
  districtCtrl = new FormControl();
  filteredDistricts: Observable<UniModel[]>;
  regions: UniModel[] = [];
  regionCtrl = new FormControl(null);

  pams: UniModel[] = [];
  pamCtrl = new FormControl(null);

  @Input() allDistricts: UniModel[] = [];
  @Input() userDistricts: UniModel[] = [];
  @Input() isLoading: boolean;
  @Input() canEdit: boolean;
  @Output() submitNewDistricts: EventEmitter<UniModel[]> = new EventEmitter();
  @Output() cancelSubmission: EventEmitter<void> = new EventEmitter();
  @Output() editIconClick: EventEmitter<void> = new EventEmitter();
  @Output() isEditModeChange: EventEmitter<boolean> = new EventEmitter();

  districts: UniModel[] = [];

  @ViewChild('districtInput') districtInput: ElementRef<HTMLInputElement>;

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) {
    this.filteredDistricts = this.districtCtrl.valueChanges.pipe(
        startWith(null),
        map((district: string | null) => district ? this._filter(district) : this.allDistricts.slice()));
  }

  ngOnInit() {
    this.dataService.getRegionOptions().then(regs => this.regions = regs);
    this.dataService.getAllPAMOrgans().then(pams => this.pams = pams);
  }

  ngOnChanges() {
    if (this.userDistricts) {
      this.districts = Array.from(this.userDistricts);
    }
  }

  onSubmit() {
    this.isEditMode = false;
    let uniqueDistricts: UniModel[] = [];
    this.districts.forEach((district) => {
      let u = uniqueDistricts.find(uD => uD.id == district.id);
      if (u == null) {
        uniqueDistricts.push(district);
      }
    })
    this.submitNewDistricts.emit(uniqueDistricts);
    this.isEditModeChange.emit(this.isEditMode);
  }

  onCancel() {
    this.isEditMode = false;
    this.districts = Array.from(this.userDistricts);
    this.cancelSubmission.emit();
    this.isEditModeChange.emit(this.isEditMode);
  }

  onEditIconClick() {
    this.isEditMode = true;
    this.editIconClick.emit();
    this.isEditModeChange.emit(this.isEditMode);
  }

  remove(district: UniModel, indx): void {
    this.districts.splice(indx, 1);
  }

  selected(values: UniModel[]): void {
    this.districts.push(...values);
    this.districtInput.nativeElement.value = '';
    this.districtCtrl.setValue(null);
  }

  onAddRegionIconClick() {
    if (this.regionCtrl.value) {
      this.dataService.getDistrictOptions(this.regionCtrl.value).then((dis) => {
        this.selected(dis)
        this.regionCtrl.setValue(null);
      });
      
    }
   
  }

  onAddPAMIconClick() {
    if (this.pamCtrl.value) {
      this.dataService.getDistrictsForPam(this.pamCtrl.value).then((dis) => {
        this.selected(dis);
        this.pamCtrl.setValue(null);
      });
    }
  }

  onTrashIconClick() {
    this.districts = [];
  }

  private _filter(value: any): UniModel[] {
    if (value.name == null) {
      const filterValue = removeDiacritics(value.toLowerCase());
      return this.allDistricts.filter(district => removeDiacritics(district.name.toLowerCase()).includes(filterValue));
    } else {
      return this.allDistricts.filter(district => district.id === value.id);
    }
    
  }
}
