import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { SmartInputComponent } from '../smart-input/smart-input.component';
import { FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import * as moment from 'moment';
import { getHours, getMinutes, getDateTime } from '../../../utils/utils';
import { DatePipe } from '../../../../../node_modules/@angular/common';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.css']
})
export class DateTimeInputComponent extends SmartInputComponent implements OnInit {
  @Input() dateControl: AbstractControl;
  @Input() placeholderSufix = 'priestupku';
  @ViewChild('picker', { static: true }) datePicker: any;
  nowTime: string;

  timeControl = new FormControl(null, [Validators.required]);

  constructor(fb: FormBuilder, public fm: FocusMonitor, public elRef: ElementRef, private dp: DatePipe) {
    super(fb, fm, elRef);
    const now = new Date();
    this.nowTime = this.dp.transform(now, 'H:mm');
    this.timeControl.setValue(this.nowTime);
  }

  ngOnInit() { }

  onDateChange() {
    this.setControlDateTime(this.timeControl.value);
  }

  onDateInputChange() {
    if (this.dateControl.value == null) {
      this.dateControl.setValue(null);
    }

    this.datePicker.open();
  }

  onTimeChange(time) {
    this.setControlDateTime(time);
  }

  private setControlDateTime(time: string) {
    try {
      this.dateControl.setValue(getDateTime(this.dateControl.value, time));
    } catch (Exception) {
      console.log('Cannot parse date time:', this.dateControl.value, time);
    }
  }

}
