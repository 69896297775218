import { AbstractControl, FormGroup } from "@angular/forms";
import { isObjectEmpty } from "../utils/utils";

export function StreetValidator(control: AbstractControl) {
  const regex = /^(\S+\s*)+\d+\S*\s*$/;
  const isValid = regex.test(control.value);
  return isValid ? null : { 'validStreet': false };
}

// POC of using multiple bonded controls
export function matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
  return (group: FormGroup): { [key: string]: any } => {
    let password = group.controls[passwordKey];
    let confirmPassword = group.controls[confirmPasswordKey];

    if (password.value !== confirmPassword.value) {
      let errors = password.errors ? Object.assign(password.errors, {mismatchedPasswords: true}) : {mismatchedPasswords: true};
      let errors2 = confirmPassword.errors ? Object.assign(confirmPassword.errors) : {mismatchedPasswords: true};
      group.controls[passwordKey].setErrors(errors);
      group.controls[confirmPasswordKey].setErrors(errors2);
      return {
        mismatchedPasswords: true
      };
    } else {
      if (password.errors) {
        if (password.errors['mismatchedPasswords']) {
          delete password.errors.mismatchedPasswords;
        }
        if (isObjectEmpty(password.errors)) {
          password.setErrors(null);
        }
      }

      if (confirmPassword.errors) {
        if (confirmPassword.errors['mismatchedPasswords']) {
          delete confirmPassword.errors.mismatchedPasswords;
        }

        if (isObjectEmpty(password.errors)) {
          confirmPassword.setErrors(null);
        }
      }
    }
  }
}

export function personalIdValidator(control: AbstractControl): {[key: string]: any} {
  const invalid = {
    'validPersonalId': true
  };
  const intVal = control.value;
  const strVal: string = control.value ? control.value.toString() : '';

  // empty value is validated separately
  if (strVal === '') {
    return null;
  }

  if (strVal.length < 9) {
    return invalid;
  }

  if (intVal % 11 !== 0) {
    return invalid;
  }

  const monthRegex = /^0[1-9]|1[0-2]|5[1-9]|6[0-2]$/;
  const month = strVal.substr(2, 2);
  if (!monthRegex.test(month)) {
    return invalid;
  }

  const day = Number.parseInt(strVal.substr(4, 2));
  if (day > 31 || day < 0) {
    return invalid;
  }

  return null;
}
