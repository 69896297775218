import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { MessageService, MessageType } from '../../services/message.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { ILoggingServiceToken, ILoggingService } from '../../services/i-logging-service';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css']
})
export class ResetPasswordPageComponent implements OnInit {

  form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  isLoadingData = false;
  isSuccess;
  lastLoginName: string;
  get shouldDisableButton() { return this.form.invalid || this.isLoadingData || this.isSuccess;}

  constructor(
    private messageService: MessageService, 
    @Inject(ILoggingServiceToken) private ls: ILoggingService,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  ngOnInit() {
    this.lastLoginName = localStorage.getItem('lastLogin') || '';
  }

  onClick() {
    this.isLoadingData = true;
    const email = this.form.controls['email'].value;
    this.dataService.sendResetPasswordLink(email).then(
      (success) => {
        this.isSuccess = success;
        this.isLoadingData = false;
        if (success == true) {
          const msg = `Link na obnovu hesla bol odoslaný na email ${this.form.controls['email'].value}.`;
          this.messageService.addMessage(msg, MessageType.SUCCESS, 1, 0);
        } else {
          const msg = 'Nepodarilo sa odoslať link, skúste to, prosím, neskôr.';
          this.ls.logError('Server responded success == false - ' + msg);
          this.messageService.addMessage(msg, MessageType.ERROR);
        }
        
      },
      (error) => {
        this.isSuccess = false;
        this.isLoadingData = false;
        const msg = 'Nepodarilo sa odoslať link, skúste to, prosím, neskôr.'
        this.ls.logError(msg, error);
        this.messageService.addMessage(msg, MessageType.ERROR)
      }
    )
  }

}
