<mat-card>
  <mat-card-content style="height: 500px;">
    <ng-container *ngIf="displayData != null">
      <h2>{{ valuesInTime.valueName }}</h2>
      <ngx-charts-heat-map
        [view]="[1200, 400]"
        [scheme]="colorScheme"
        [results]="displayData"
        gradient=false
        xAxis=true
        yAxis=true
        legend=true
        showXAxisLabel=false
        showYAxisLabel=false>
      </ngx-charts-heat-map>
    </ng-container>
    <app-app-spinner *ngIf="displayData == null"></app-app-spinner>
  </mat-card-content>
</mat-card>
