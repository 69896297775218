<nav class="navigation">
  <a class="nav-item d-flex" routerLink="/home"><i class="material-icons app-btn-icon">home</i>Domov</a>
  <div class="nav-right" *ngIf="isLoggedIn">
    <button mat-icon-button [matMenuTriggerFor]="menu" class="nav-item">
      <i class="material-icons app-btn-icon">perm_identity</i>
      <span>{{ dataService.user.name }}</span>
    </button>
    <mat-menu #menu="matMenu">
      
      <button mat-menu-item routerLink="/user-profile">
        <mat-icon>person</mat-icon>
        <span>Môj profil</span>
      </button>

      <button mat-menu-item (click)="logOutClick()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Odhlásiť sa</span>
      </button>
    </mat-menu>
  </div>
</nav>
