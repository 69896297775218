import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { groupDatesByDay, getDayDate } from '../../../utils/utils';
import { ValueInTime } from '../../../types/types';

const weekdayName = new Intl.DateTimeFormat('en-us', { weekday: 'short' });

@Component({
  selector: 'app-values-over-time',
  templateUrl: './values-over-time.component.html',
  styleUrls: ['./values-over-time.component.css']
})
export class ValuesOverTimeComponent implements OnChanges {
  @Input() valuesInTime: ValueInTime;

  displayData = null;
  ngOnChanges() {
    if (this.valuesInTime != null) {
      this.displayData = getCalendarData(this.valuesInTime);
    }
    
  }

  colorScheme = {
    domain: ['#e0e0e0', '#0a7ea1', '#a1970a', '#c48506', '#A10A28']
  };

  constructor(private datepipe: DatePipe) {
  }

}

export function getCalendarData(valuesInTime: ValueInTime): any[] {
  const occurencesMap = groupDatesByDay(valuesInTime.occurences);
  // today
  const now = new Date();
  const todaysDay = now.getDate();
  const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

  // Monday
  const thisMonday = new Date(thisDay.getFullYear(), thisDay.getMonth(), todaysDay - thisDay.getDay() + 1);
  const thisMondayDay = thisMonday.getDate();
  const thisMondayYear = thisMonday.getFullYear();
  const thisMondayMonth = thisMonday.getMonth();

  // 52 weeks before monday
  const calendarData = [];
  const getDate = d => new Date(thisMondayYear, thisMondayMonth, d);
  for (let week = -52; week <= 0; week++) {
    const mondayDay = thisMondayDay + (week * 7);
    const monday = getDate(mondayDay);

    // one week
    const series = [];
    for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
      const date = getDate(mondayDay - 1 + dayOfWeek);

      // skip future dates
      if (date > now) {
        continue;
      }

      // value
      const dateDay = getDayDate(date);
      const value = occurencesMap[dateDay] != null ? occurencesMap[dateDay] : 0;

      series.push({
        date,
        name: weekdayName.format(date),
        value
      });
    }

    calendarData.push({
      name: monday,
      series
    });
  }

  return calendarData;
}
