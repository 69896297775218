import { UniModel, SafeRangerInfo, PendingRegistration, SentUserInvitation, Visit, User, Record, Guard, Token, PAManagingOrgan, EnumGenericType, CoordinatorWithPAMs } from "../types/types";

export const IDataserviceToken = 'IDataServiceToken';
export interface IDataService {
    isOffline: boolean;
    user: User;
    isLoggingIn: boolean;
    isTokenLogin: boolean;

    clearLoggingError();
    doOfflineCheck();
    isUserLoggedIn(): boolean;
    getRegionOptions(userId?: string): Promise<UniModel[]>;
    getSolvingWayOptions(): Promise<UniModel[]>;
    getVehicleTypeOptions(): Promise<UniModel[]>;
    getDistrictOptions(regionId?: string, userId?: string): Promise<UniModel[]>;
    getProtectedAreaOptions(districtId?: string, effectiveDate?: Date): Promise<UniModel[]>;
    getProtectionLevelOptions(protectedAreaId?: string): Promise<UniModel[]>;
    getDeliquencyTypelOptions(protectionLevel?: string, effectiveDate?: Date): Promise<UniModel[]>;
    getIdCardTypesOptions(): Promise<UniModel[]>;
    getGenderOptions(): Promise<UniModel[]>;
    doLogin(loginName: string, pass: string, errorCallback?: any, redirectUrl?: string);
    doLogout(automaticLogout?: boolean, message?: string);
    setLogOutTimeOut(logOutAt: Date);
    checkPerson(personalOrName: string): any;
    storeRecord(record: Record): Promise<boolean>;
    getPerson(personId: string): Promise<any>;
    getRecords(): any;
    getRecordDetail(id: string): Promise<Record>;
    getRangerNames(districtId: string): Promise<SafeRangerInfo[]>;
    sendNewUserEmailInvite(emailAddress: string, isOfficer: boolean, managingPamID: string);
    getUsers();
    updateActivationStatusOfUser(userId: string, activated: boolean);
    updatePermissionOfUser(userId: string, newPermission: number);
    updateTerritoryScopeForUser(userId: string, newDistrictIds: string[]): Promise<boolean>;
    getPendingRegistrations(): Promise<PendingRegistration[]>;
    allowFinishingOfRegistration(registrationId: string, authRank: string): Promise<boolean>;
    disallowFinishingOfRegistration(registrationId: string): Promise<boolean>;
    verifyRegistration(registrationId: string, approve: boolean, districtIds: string[]): Promise<boolean>;
    sendRegistrationApplication(registration: PendingRegistration): Promise<boolean>; 
    getInvitationEmailForToken(token: string): Promise<{success: boolean; email?: string; isOfficer?: boolean}>;
    sendResetPasswordLink(email: string);
    getEmailForResetPasswordToken(token: string): Promise<{success: boolean; email: string}>;
    getSentUserInvitations(): Promise<SentUserInvitation[]>;
    updateVisitDescription(visitId: string, newDescription: string);
    cancelRegistrationInvitation(id: string);
    refreshRegistrationInvitation(id: string);
    sendNewPasswords(token: string, password: string, repeatPassword: string): Promise<{success: boolean; email?: string; message?: string}>;
    getGuardForVisit(date: Date, district: string, protectedArea: string): Promise<Guard[]>;
    storeVisit(visit: Visit): Promise<boolean>;
    getVisits(userId?: string);
    getAllVisits();
    getRecordsForVisit(visitIds: string[]);
    getProtectedAreasWithDistrict();
    getProtectedAreaById(id: string);
    getRecordsForProtectedArea(id: string);
    getVisitsForProtectedArea(id: string);
    downloadActivityReportPdf(user: User | SafeRangerInfo, ids: string[], dateFrom: Date, dateTo: Date);
    downloadRecordNoticePdf(recordId: string);
    getLogs(daysBack: number);
    getUserById(userId: string): Promise<User>;
    doTokenLogin(token: Token, redirectUrl: string);
    getAllPAMOrgans(): Promise<PAManagingOrgan[]>;
    getEnums(): Promise<EnumGenericType[]>;
    getEnumDetail(enumType: string): Promise<EnumGenericType[]>;
    editEnumData(enumData: EnumGenericType, enumType: string);
    deleteEnumData(enumDataId: string, enumType: string);
    createEnumData(enumData: EnumGenericType, enumType: string);
    getCoordinatorsWithPams(): Promise<CoordinatorWithPAMs[]>;
    updateCoordinatorsPams(userId: string, newPamIds: string[]): Promise<boolean>;
    getOfficers(): Promise<User[]>;
    createUser(user: User): Promise<{success: boolean, message: string}>;
    getDistrictsForPam(id: string): Promise<UniModel[]>;
    updateMultiUserPam(pamId: string, userIds: string[]): Promise<boolean>;
    deleteVisit(visitId: string, guardId?: string): Promise<boolean>;
}