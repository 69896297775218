import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '../../../../node_modules/@angular/forms';
import { UniModel, Visit, Guard } from '../../types/types';
import { removeDiacritics } from '../../utils/utils';
import { MessageType, MessageService } from '../../services/message.service';
import { Router } from '../../../../node_modules/@angular/router';
import { TransformTypes } from '../../components/inputs/smart-input/smart-input.component';
import { distinctUntilChanged } from '../../../../node_modules/rxjs/operators';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { ILoggingServiceToken, ILoggingService } from '../../services/i-logging-service';

@Component({
  selector: 'app-create-visit-page',
  templateUrl: './create-visit-page.component.html',
  styleUrls: ['./create-visit-page.component.css']
})
export class CreateVisitPageComponent implements OnInit {
  @ViewChild('picker', { static: true }) datePicker: any;
  previousDescriptionValue = '';
  transformTypes = TransformTypes;
  form: FormGroup;
  regionOptions: UniModel[] = [];
  districtOptions: UniModel[] = [];
  protectedAreaOptions: UniModel[] = [];
  isLoadingData = false;
  isSuccess;
  addNextVisit: boolean = false;
  userVisitExists: boolean = false;
  showAddUsers: boolean = false;
  visitExistsId: string = null;

  guard: Guard;
  isLoadingGuard = false;

  guardFormCheckbox;
  filteredUsers: UniModel[];
  users: UniModel[] = [];
  selectedUsers: UniModel[] = [];

  get shouldShowGuardArea() { return this.form.valid && !this.userVisitExists; }
  get shouldDisableButton() { return !this.form.valid || this.isLoadingData || this.isLoadingGuard; }
  constructor(
    private fb: FormBuilder, 
    private messageService: MessageService, 
    private router: Router,
    @Inject(ILoggingServiceToken) private ls: ILoggingService,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  ngOnInit() {
    this.createForm();
    this.dataService.getRegionOptions(this.dataService.user.id).then((regionOptions: UniModel[]) => {
      this.regionOptions = regionOptions;
    });

    this.form.get('region').valueChanges.subscribe(() => {
      this.handleDependentControl('region', 'district');
      this.handleDependentControl('district', 'protectedArea');
    });

    this.form.get('district').valueChanges.subscribe(() => {
      this.handleDependentControl('district', 'protectedArea');

      const districtId = this.form.controls['district'].value;
      this.dataService.getRangerNames(districtId).then((data) => {
        this.users = data.map((user) =>  {
          return {
             id: user.id, 
             name: user.name + ' ' + user.surname
          }
        });
        this.filteredUsers = this.users;
        this.selectedUsers = [];
      });

    });

    this.form.valueChanges.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))).subscribe((data) => {
      if (this.form.valid && !this.isLoadingData) {
        if (data.description != this.previousDescriptionValue) {
          this.previousDescriptionValue = data.description;
          return;
        }
        this.guardFormCheckbox = undefined; 
        this.guard = null;

        this.showAddUsers = false;
        this.userVisitExists = false;
        this.isLoadingGuard = true;

        const formDate: Date = this.form.controls['dateFrom'].value;
        let date: Date = new Date(Date.UTC(formDate.getFullYear(), formDate.getMonth(), formDate.getDate(), 0, 0, 1)); 
        this.dataService.getGuardForVisit(
          date,
          this.form.controls['district'].value,
          this.form.controls['protectedArea'].value).then((res) => {
            this.isLoadingGuard = false;
            let isEmpty = res.length === 0 || res === undefined;

            if (res == null || isEmpty) {
              this.showAddUsers = true;
              this.messageService.clearMessages();
              this.form.controls['description'].setValue('');
            } else {
              loop:
              for (let guardEvent of Object.values(res)){
                for (let visit of guardEvent['visits']){
                  this.guardFormCheckbox = { id: guardEvent.id, checked: true};
                  this.guard = guardEvent;
                  if (visit['user_id'] === this.dataService.user.id){
                    this.userVisitExists = true;
                    this.messageService.replaceFirstMessage('Pre dané územie už v tento deň máte návštevu územia. Môžete aktualizovať popis tejto návštevy.', MessageType.INFO);
                    this.visitExistsId = visit['id'];
                    this.form.controls['description'].setValue(visit['description']);
                    break loop;
                  }
                }
              }
            }
        });
      }
    });
  }


  onOptionSelected(option: UniModel) {
    this.selectedUsers.push(option);
    // do not offer a user to be select, which is already selected
    this.filteredUsers = this.filteredUsers.filter(user => user.id !== option.id);
  }

  onCloseClick(user: UniModel) {
    this.selectedUsers = this.selectedUsers.filter(selUser => selUser.id !== user.id);
    // allow the removed user to be selected again 
    this.filteredUsers.push(user);
    // to workaround change detection on smart-autocomplete...
    this.filteredUsers = this.filteredUsers.slice(); 
  }


  onSubmit() {
    this.isLoadingData = true;

    if (this.userVisitExists) {
      this.dataService.updateVisitDescription(this.visitExistsId, this.form.controls['description'].value).then((res) => {
        this.isLoadingData = false;
        if (res == true) {
          this.redirectOrNextVisit('Popis návštevy bol úspešne aktualizovaný.');
        }
      });
    }
    else {
      const date = new Date(this.form.controls['dateFrom'].value);
      date.setHours(5);
      const visit: Visit = {
        id: null,
        date: date,
        visitIds: [],
        district: this.form.controls['district'].value,
        protectedArea: this.form.controls['protectedArea'].value,
        guardId: (this.guardFormCheckbox != null && this.guardFormCheckbox.checked) ? this.guardFormCheckbox.id : null,
        userIds: this.selectedUsers.map(user => user.id),
        description: this.form.controls['description'].value
      }
      this.dataService.storeVisit(visit).then(
        (successResponse) => {
          this.isLoadingData = false;
          this.redirectOrNextVisit('Záznam bol uložený.');
        },
        (error) => {
          this.isLoadingData = false;
          const msg = 'Nastala chyba a záznam nebol uložený, skúste to, prosím, neskôr.';
          this.ls.logError(msg, error);
          this.messageService.addMessage(msg, MessageType.ERROR);
        }
      );
    }
  }

  private handleDependentControl(masterControlName: string, dependentControlName: string) { // && this.form.get(dependentControlName).disabled
    if (this.form.get(masterControlName).value) {
      this.form.get(dependentControlName).enable({emitEvent:false});
      this.form.get(dependentControlName).setValue(null, {emitEvent:false})
      this.getFilteredOptionsForControl(dependentControlName);
    } else if (!this.form.get(masterControlName).value) { // && this.form.get(dependentControlName).enabled
      this.form.get(dependentControlName).disable({emitEvent:false});
      this.form.get(dependentControlName).setValue(null, {emitEvent:false});
    }
  }

  private getFilteredOptionsForControl(controlName: string) {
    switch (controlName) {
      case 'district':
        this.dataService.getDistrictOptions(this.form.get('region').value, this.dataService.user.id).then((districtOptions: UniModel[]) => {
          this.districtOptions = districtOptions;
        });
        break;

      case 'protectedArea':
        this.dataService.getProtectedAreaOptions(this.form.get('district').value).then((protectedAreaOptions: UniModel[]) => {
          this.protectedAreaOptions = protectedAreaOptions;
        });
        break;
    }
  }

  onDateInputChange() {
    if (this.form.controls['dateFrom'].value == null) {
      this.form.controls['dateFrom'].setValue(null);
    }

    this.datePicker.open();
  }

  private createForm() {
    this.form = this.fb.group({
      dateFrom: ['', Validators.required],
      // dateTo: ['', Validators.required],
      region: ['', Validators.required],
      district: ['', Validators.required],
      protectedArea: ['', Validators.required],
      description: ['']
    });
  }

  private _filterUsers(value): UniModel[] {
    const filterValue = removeDiacritics(value.toLowerCase());
    return this.users.filter(user => removeDiacritics(user.name.toLowerCase()).indexOf(filterValue) > -1);
  }
  
  private redirectOrNextVisit(messageText: string){
    if (this.addNextVisit) {
      this.messageService.replaceFirstMessage(messageText, MessageType.SUCCESS);
      this.form.controls['district'].setValue("");
      this.form.controls['protectedArea'].setValue("");
    } else {
      this.messageService.replaceFirstMessage(messageText, MessageType.SUCCESS);
      this.router.navigate(['/visits']);
    }
  }
}
