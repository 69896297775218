import { DatePipe } from '../../../../node_modules/@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TableConfig } from '../../services/history.service';
import { EnumGenericType } from '../../types/types';
import { mapFEEnums, removeDiacritics } from '../../utils/utils';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { MessageService, MessageType } from '../../services/message.service';



interface TableDataItem {
  id: string;
  protection_level: string;
  name: string;
  shortcut: string;

  description: string;
  law_part_bonded: string;
  law_part_offense: string;
  valid_from_incl: string;
  valid_until_incl: string;

  region_id: string;
  managing_organ_id: string;

  district_id: string;
  p_a_managing_organ_id: string;
  protected_area_id: string;

  document_type: string;

  city: string;
  street: string;
  zip: string;

  inside_id: string;
  protected_area_type_id: string;
  protective_zone: string;

  code: string;
  is_large: string;
}

@Component({
  selector: 'app-enum-detail-table',
  templateUrl: './enum-detail-table.component.html',
  styleUrls: ['./enum-detail-table.component.css']
})
export class EnumDetailTableComponent implements OnInit, OnChanges {
  @Input() enumData: EnumGenericType[];
  @Input() tableConfig: TableConfig;

  allColumns: string[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<TableDataItem>;
  public innerWidth: any;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  private mapItemToTableData(enumDataItem: EnumGenericType): TableDataItem {
    return {
      id: enumDataItem.id,
      description: enumDataItem.description,
      law_part_bonded: enumDataItem.lawPartBonded,
      law_part_offense: enumDataItem.lawPartOffense,
      code: enumDataItem.code,
      name: enumDataItem.name,
      protection_level: enumDataItem.protectionLevel,
      valid_from_incl: this.datePipe.transform(enumDataItem.validFromIncl, 'd.M.y H:mm:ss'),
      valid_until_incl: this.datePipe.transform(enumDataItem.validUntilIncl, 'd.M.y H:mm:ss'),
      inside_id: enumDataItem.insideId,
      protected_area_type_id: enumDataItem.protectedAreaTypeId,
      protective_zone: enumDataItem.protectiveZone,
      city: enumDataItem.city,
      street: enumDataItem.street,
      zip: enumDataItem.zip,
      document_type: enumDataItem.documentType,
      shortcut: enumDataItem.shortcut,
      region_id: enumDataItem.regionId,
      managing_organ_id: enumDataItem.managingOrganId,
      district_id: enumDataItem.districtId,
      p_a_managing_organ_id: enumDataItem.paManagingOrganId,
      protected_area_id: enumDataItem.protectedAreaId,
      is_large: enumDataItem.isLarge,
    }
  }

  constructor(
    private ref: ChangeDetectorRef,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    public ms: MessageService,
    @Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.enumData) {
      var enumData = this.enumData.map(enumDataItem => this.mapItemToTableData(enumDataItem));
      if (enumData.length) {
        enumData.forEach(item => {
          Object.keys(item).forEach(key => (item[key] === undefined || item[key] === null) && delete item[key]);
        })
        this.allColumns = Object.keys(enumData[0]);
        Object.assign(this.displayedColumns, this.allColumns);
        this.displayedColumns.push("action");
      }
      this.dataSource = new MatTableDataSource(enumData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.filterPredicate = (data: TableDataItem, filter: string) => {
        var dataText = "";
        this.allColumns.forEach(key => { dataText += data[key]; })
        const dataArray = removeDiacritics(dataText.trim().toLowerCase());
        const res = dataArray.indexOf(filter);
        return res > -1;
      }

      this.innerWidth = window.innerWidth;
      this.applyFilter(this.tableConfig.filterCriteria);
      this.ref.detectChanges();
    }
  }

  ngOnInit() {
    //this is only for spinner to occur while mapping the table
    this.dataSource = null;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = removeDiacritics(filterValue.trim().toLowerCase());

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  convertStringToDate(dateString: string): Date {
    let dateTime = dateString.split(" ");
    let date = dateTime[0].split(".");
    let time = dateTime[1].split(":");

    return new Date(+date[2], +date[1] - 1, +date[0], +time[0], +time[1], +time[2]);
  }

  onDeleteEnumDataClick(enumData: TableDataItem) {
    this.openDeleteDialog(enumData['id']);
  }

  openDeleteDialog(enumDataId: string): void {
    const enumType = this.route.snapshot.params['enumType'];
    let dialogRef = this.dialog.open(DeleteEnumDataDialog, {
      data: {
        message: "Chcete vymazať záznam s ID " + enumDataId + "?",
        confirmButtonText: "Áno",
        cancelButtonText: "Zrušiť",
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.dataService.deleteEnumData(enumDataId, enumType).then(res => {
          if (res == true) {
            this.router.navigate(['show-enums']);
            this.ms.replaceFirstMessage('Záznam bol úspešne vymazaný', MessageType.SUCCESS);
          }
        });
      }
    });
  }

  onEditEnumDataClick(enumData: TableDataItem) {
    let event = Object.assign({}, enumData);
    this.openEditDialog(event);
  }

  openEditDialog(event: TableDataItem): void {
    let dialogRef = this.dialog.open(EditEnumDataDialog, {
      width: '600px',
      data: event,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const enumType = this.route.snapshot.params['enumType'];
        let enumData = result;

        if (enumData.valid_from_incl) enumData.valid_from_incl = this.datePipe.transform(this.convertStringToDate(enumData.valid_from_incl), 'yyyy-MM-dd HH:mm:ss');
        if (enumData.valid_until_incl) enumData.valid_until_incl = this.datePipe.transform(this.convertStringToDate(enumData.valid_until_incl), 'yyyy-MM-dd HH:mm:ss');

        this.dataService.editEnumData(enumData, enumType).then(res => {
          if (res == true) {
            this.router.navigate(['show-enums']);
            this.ms.replaceFirstMessage('Záznam bol úspešne zmenený', MessageType.SUCCESS);
          }
        });
      }
    });
  }

  onCreateEnumDataClick() {
    this.openCreateDialog();
  }

  openCreateDialog(): void {
    let keys = Object.assign([], this.allColumns);
    let index = keys.indexOf("id");
    if (index !== -1) {
      keys.splice(index, 1);
    }
    let dialogRef = this.dialog.open(CreateEnumDataDialog, {
      width: '600px',
      data: {
        keys: keys,
        enumData: {},
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const enumType = this.route.snapshot.params['enumType'];
        let enumData = result;

        if (enumData.valid_from_incl) enumData.valid_from_incl = this.datePipe.transform(this.convertStringToDate(enumData.valid_from_incl), 'yyyy-MM-dd HH:mm:ss');
        if (enumData.valid_until_incl) enumData.valid_until_incl = this.datePipe.transform(this.convertStringToDate(enumData.valid_until_incl), 'yyyy-MM-dd HH:mm:ss');

        this.dataService.createEnumData(enumData, enumType).then(res => {
          if (res == true) {
            this.router.navigate(['show-enums']);
            this.ms.replaceFirstMessage('Záznam bol úspešne vytvorený', MessageType.SUCCESS);
          }
        });
      }
    });
  }
}

@Component({
  selector: 'delete-enum-data-dialog',
  templateUrl: 'delete-enum-data-dialog.html',
})
export class DeleteEnumDataDialog {
  message: string = "Chcete vykonať akciu?";
  confirmButtonText = "Áno";
  cancelButtonText = "Zrušiť";

  constructor(
    public dialogRef: MatDialogRef<DeleteEnumDataDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message ? data.message : this.message;
    this.confirmButtonText = data.confirmButtonText ? data.confirmButtonText : this.confirmButtonText;
    this.cancelButtonText = data.cancelButtonText ? data.cancelButtonText : this.cancelButtonText;
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  onCancelClick(): void {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: 'edit-enum-data-dialog',
  templateUrl: 'edit-enum-data-dialog.html',
})
export class EditEnumDataDialog {
  constructor(
    public dialogRef: MatDialogRef<EditEnumDataDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  isValid(object) {
    let values = Object.values(object);
    return values.every((value) => value != "")
  }
  objectKeys = Object.keys;
}

@Component({
  selector: 'create-enum-data-dialog',
  templateUrl: 'create-enum-data-dialog.html',
})
export class CreateEnumDataDialog {
  constructor(
    public dialogRef: MatDialogRef<CreateEnumDataDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  countKeys(array) {
    return array.length;
  }
  isValid(object, length) {
    let values = Object.values(object);
    if (values.length == length) return values.every((value) => value != "")
    else return false;
  }
}