import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { SmartInputComponent } from '../smart-input/smart-input.component';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { getPersonalIdFromDate, getSexFromPersonalId, getDateFromPersonal } from '../../../utils/utils';
import { SEX } from '../../../types/types';
import * as moment from 'moment';

@Component({
  selector: 'app-date-and-personal-input',
  templateUrl: './date-and-personal-input.component.html',
  styleUrls: ['./date-and-personal-input.component.css']
})
export class DateAndPersonalInputComponent extends SmartInputComponent implements OnInit {

  @Input() personalControl: AbstractControl;
  @Input() dateControl: AbstractControl;
  @Input() genderControl: AbstractControl;
  @ViewChild('picker', { static: true }) datePicker: any;

  sex = SEX;

  personalDisabled: boolean;
  dateDisabled: boolean;
  sexDisabled: boolean;

  constructor(fb: FormBuilder, public fm: FocusMonitor, public elRef: ElementRef) {
    super(fb, fm, elRef);
  }

  ngOnInit() {
  }

  onPersonalChange() {
    const birthDate = getDateFromPersonal(this.personalControl.value);
    let sex: SEX;
    try {
      sex = getSexFromPersonalId(this.personalControl.value);
    } catch (exception) {
    }

    this.dateControl.setValue(birthDate);
    this.genderControl.setValue(sex);

    if (this.personalControl.value == null || this.personalControl.value == '') {
      this.dateControl.enable();
      this.genderControl.enable();
    } else {
      this.dateControl.disable();
      this.genderControl.disable();
    }

  }

  onDateChange() {
    const momentDate = moment(this.dateControl.value, "DD.MM.YYYY", true);
    if (momentDate.isValid() && this.genderControl.value != null) {
      let personalId = getPersonalIdFromDate(momentDate.toDate(), this.genderControl.value);
      this.personalControl.disable();
      this.personalControl.setValue(personalId);
    } else {
      this.personalControl.setValue(null);
      this.personalControl.enable();
    }
  }

  onDateInputChange() {
    if (this.dateControl.value == null) {
      this.dateControl.setValue(null);
    }

    this.datePicker.open();
  }

  onSexChange() {
    const momentDate = moment(this.dateControl.value, "DD.MM.YYYY", true);
    if (momentDate.isValid() && this.genderControl.value != null) {
      let personalId = getPersonalIdFromDate(momentDate.toDate(), this.genderControl.value);
      this.personalControl.disable();
      this.personalControl.setValue(personalId);
    } else {
      this.personalControl.setValue(null);
      this.personalControl.enable();
    }
  }

}
