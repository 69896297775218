import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { MessageService, MessageType } from '../../services/message.service';
import { Router } from '../../../../node_modules/@angular/router';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { ILoggingServiceToken, ILoggingService } from '../../services/i-logging-service';
import { PAManagingOrgan, UniModel } from '../../../app/types/types';
import { removeDiacritics } from '../../../app/utils/utils';

@Component({
  selector: 'app-invite-new-user-page',
  templateUrl: './invite-new-user-page.component.html',
  styleUrls: ['./invite-new-user-page.component.css']
})
export class InviteNewUserPageComponent implements OnInit {
  inputControl = new FormControl('', [Validators.required, Validators.email]);
  isLoadingData = false;
  isOfficer = false;
  pams: PAManagingOrgan[] = [];
  autocompletePams: UniModel[] = [];
  selectedPam: PAManagingOrgan;

  get btnDisabledState() { return !this.inputControl.valid || this.selectedPam == null || this.isLoadingData }
  constructor(
    private messageService: MessageService, 
    private router: Router, 
    @Inject(ILoggingServiceToken) private ls: ILoggingService,
    @Inject(IDataserviceToken) private dataService: IDataService  
  ) { }

  ngOnInit() {
    this.dataService.getAllPAMOrgans().then((pams) => {
        this.pams = pams; 
        this.autocompletePams = this.pams.map((pam: PAManagingOrgan) => {
          return {
            id: pam.id,
            name: `[${pam.id}] - ${pam.name}`
          }
        })
    });
  }

  onClick() {
    this.isLoadingData = true;
    this.dataService.sendNewUserEmailInvite(this.inputControl.value, this.isOfficer, this.selectedPam.id).then((response: any) => {
      this.isLoadingData = false;
      if (response.success) {
        this.messageService.replaceFirstMessage('Pozvánka bola odoslaná.', MessageType.SUCCESS);
        this.router.navigate(['home']);
      } else if (response.message == 'isDuplicate'){
        this.messageService.replaceFirstMessage('Zadaná emailová adresa už existuje v systéme.', MessageType.INFO, 0);
      } else {
        const msg = 'Nepodarilo sa odoslať pozvánku, skúste to, prosím, neskôr.';
        this.ls.logError(msg);
        this.messageService.replaceFirstMessage(msg, MessageType.ERROR, 0);
      }
    });
  }

  onPamChange(newVal: UniModel) {
    this.selectedPam = this.pams.find(pam => pam.id == newVal.id);
  }

}
