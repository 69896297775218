<div class="vehicle-form-wrapper">
    <mat-card>
        <mat-card-content>
            <h3>Údaje o vozidle</h3>
            <div [formGroup]="parentFormGroup">
                <app-smart-select [control]="parentFormGroup.controls.vehicleType" placeholder="Typ vozidla" [options]="vehicleTypeOptions"></app-smart-select>
                <app-smart-input [control]="parentFormGroup.controls.licenceNumber" placeholder="ŠPZ" [transformFunc]="transformTypes.uppercase" name="licenceNumber"></app-smart-input>
            </div>
        </mat-card-content>
    </mat-card>
</div>