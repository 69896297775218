<app-back-button></app-back-button>

<mat-card>
    <mat-card-content>
        <h1>Prehľad aktivít v čase</h1>

        <ng-container *ngFor="let stat of statsToDisplay">
            <app-values-over-time [valuesInTime]="datesMap[stat]"></app-values-over-time> 
        </ng-container>
 
    </mat-card-content>
</mat-card>

