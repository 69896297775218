<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Registrácie čakajúce na schválenie</h1>
    <div *ngFor="let item of pendingRegistrations" [@simpleFadeAnimation]="'in'">
      <app-pending-registration-item [item]="item" (disallowClick)="onDisallowClick($event)" (allowClick)="onAllowClick($event)"></app-pending-registration-item>
    </div>
    <app-app-spinner *ngIf="pendingRegistrations == null"></app-app-spinner>
    <h3 *ngIf="pendingRegistrations && pendingRegistrations.length == 0">V súčasnosti niesú žiadne čakajúce registrácie</h3>
  </mat-card-content>
</mat-card>
