import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Visit, UniModel, SafeRangerInfo } from '../../types/types';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { removeDiacritics, downloadPdfFile, sortVisits, isUserManagedByMe } from '../../utils/utils';
import { PermissionsService, Permission } from '../../services/permissions.service';
import { MessageService, MessageType } from '../../services/message.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-visits-page',
  templateUrl: './visits-page.component.html',
  styleUrls: ['./visits-page.component.css']
})
export class VisitsPageComponent implements OnInit {
  @ViewChild('picker') datePickerFrom: any;
  @ViewChild('picker2') datePickerTo: any;
  inputControl = new FormControl('');
  dateFrom = new FormControl(null);
  dateTo = new FormControl(null);
  visits: Visit[] = [];
  filteredVisits: Visit[] = [];
  isLoadingData = false;
  forUser: SafeRangerInfo;
  allVisits: boolean;
  userId: string;
  pAreas: UniModel[];
  filterValue: string = '';
  filterRunning: boolean;
  loadingPdf: boolean = false;
  get user() { return this.ds.user};
  get isCoordinatingUser() { return isUserManagedByMe(this.forUser, this.user); }
  get backRouterLink() { return this.forUser == null ? '/home' : '/manage-users'; }
  get backButtonText() { return this.forUser == null ? 'Späť domov' : 'Späť na správu používateľov'; }
  get disablePdfBtn() { return this.loadingPdf || this.filteredVisits.length == 0}
  constructor(
    private route: ActivatedRoute,
    private ps: PermissionsService,
    public dialog: MatDialog,
    public ms: MessageService,
    public router: Router,
    @Inject(IDataserviceToken) private ds: IDataService  
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params['userId'] || null;
    this.isLoadingData = true;
    this.ds.getProtectedAreaOptions().then((pAreas) => {
      this.pAreas = pAreas;
      this.getVisits(pAreas);
    });
    this.inputControl.valueChanges.subscribe((value) => {
      this.onFilterChange(value);
    });

    this.dateFrom.valueChanges.subscribe(() => {this.onDateFilterChange()});
    this.dateTo.valueChanges.subscribe(() => {this.onDateFilterChange()});
  }

  onDateFilterChange() {
    if (this.dateFrom.value != null && this.dateTo.value) {
      this.applyFilters();
    }
  }

  hasUserPdfPerm() {
    return this.ps.hasUserOneOfFollowingPermissions([Permission.Ranger, Permission.Officer]);
  }

  pdfClick() {
    const user = this.forUser != null ? this.forUser : this.ds.user;
    this.loadingPdf = true;
    const formDateFrom: Date = this.dateFrom.value;
    const formDateTo: Date = this.dateTo.value;
    let dTo = null;
    let dFrom = null;
    if (formDateFrom != null && formDateTo != null) {
      dTo = new Date(Date.UTC(formDateTo.getFullYear(), formDateTo.getMonth(), formDateTo.getDate(), 0, 0, 1)); 
      dFrom = new Date(Date.UTC(formDateFrom.getFullYear(), formDateFrom.getMonth(), formDateFrom.getDate(), 0, 0, 1)); 
    }
    // let filteredVisitIds = []; 
    // this.filteredVisits.forEach((visit) => {
    //   filteredVisitIds = filteredVisitIds.concat(visit.visitIds);
    // });
    this.ds.downloadActivityReportPdf(user, this.filteredVisits.map(g => g.guardId), dFrom, dTo).subscribe((data) => {
      const fileName = `navstevy-uzemia-${user.surname}`;
      downloadPdfFile(data, fileName);
      this.loadingPdf = false;
    });
  }

  onFilterChange(filterValue: string) {
    this.filterValue = filterValue;
    this.applyFilters();
  }

  applyFilters() {
    // this.historyService.manageUsersFilter = filterValue;
    this.filterRunning = true;
    let result = this.visits;
    if (this.filterValue != '') {
      const filter = removeDiacritics(this.filterValue.toLowerCase());
      result = result.filter(visit => this.unifyVisit(visit).indexOf(filter) > -1);
    }

    const dateFrom: Date = this.dateFrom.value;
    const dateTo: Date = this.dateTo.value;
    if (dateFrom != null && dateTo != null) {
      result = result.filter((visit: Visit) => {
        return new Date(visit.date) >= dateFrom && new Date(visit.date) <= dateTo;
      });
    }

    setTimeout(
      () => { 
        this.filterRunning = false;
        this.filteredVisits = sortVisits(result);
      },
      500)
  }

  private unifyVisit(visit: Visit) {
    const str = removeDiacritics(`${visit.protectedArea} ${visit.description} ${visit.date}`.toLowerCase());
    return str;
  }

  private getVisits(pAreas: UniModel[]) {
    if (this.userId == 'all') {
      this.ds.getAllVisits().then((response) => {
        this.processVisitRespons(this.userId, pAreas, response);
      });
    } else {
      this.ds.getVisits(this.userId).then((response) => {
        this.processVisitRespons(this.userId, pAreas, response);
      });
    }
  }

  private processVisitRespons(userId: string, pAreas: UniModel[], beResponse: any) {
    const resp = (userId == 'all') ? beResponse : beResponse.visits;
    this.isLoadingData = false;
    this.visits = resp.map((visit) => {visit.protectedArea = pAreas.find(p => p.id == visit.protectedArea).name;  return visit;});
    this.applyFilters();
    this.allVisits = (userId == 'all');
    if (userId != null) {
      this.forUser = beResponse.responseUser;
    }
  }

  onPanelOpened(index) {
    if (this.visits[index].records == null) {
      this.ds.getRecordsForVisit(this.visits[index].visitIds).then((records) => {
        this.filteredVisits[index].records = records;
      });
    }
  }

  onDateInputChange() {
    if (this.dateFrom.value == null) {
      this.dateFrom.setValue(null);
    }
    this.datePickerFrom.open();
  }

  onDateInput2Change() {
    if (this.dateTo.value == null) {
      this.dateTo.setValue(null);
    }
    this.datePickerTo.open();
  }

  onEditDescriptionClick(event) {
    console.log(event);
    let actRangerObj = event.rangers.find(el => el.ranger.id == this.ds.user.id);
    this.openDialog(actRangerObj.visitId, actRangerObj.description);
  }

  openDialog(visitId: string, description: string): void {
    let dialogRef = this.dialog.open(EditVisitsDescriptionDialog, {
      width: '600px',
      data: {
        visitId: visitId,
        description: description
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        console.log(result);
        this.ds.updateVisitDescription(result.visitId, result.description).then((res) => {
          console.log(res);
          if (res == true) {
            this.router.navigate(['home']);
            this.ms.replaceFirstMessage('Popis návštevy bol úspešne zmenený', MessageType.SUCCESS);
            // let description = '';
            // result.rangers.forEach(rngObj => {
            //   let actDesc = rngObj.ranger.id == this.ds.user.id ? rngObj.description : rngObj.ranger.description;
            //   description += rngObj.ranger.name + ' ' + rngObj.rangersurname + ': ' + (actDesc == null || actDesc == '' ? 'Bez popisu.' : actDesc) + '<br>';  
            // });  
            // this.visits.
          }
        });
        console.log('closing');
      }
    });
  }

  onDeleteVisitClick(event, index: number){
    this.openDeleteDialog(event, index);
  }

  openDeleteDialog(event, index: number): void {
    let actRangerObj = event.rangers.find(el => el.ranger.id == this.ds.user.id);

    let dialogRef = this.dialog.open(DeleteVisitDialog, {
      data: {
        message: "Chcete vymazať návštevu s ID " + actRangerObj.visitId + "?",
        confirmButtonText: "Áno",
        cancelButtonText: "Zrušiť",
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        if (event.rangers.length > 1){
          this.ds.deleteVisit(actRangerObj.visitId).then(res => {
            if (res == true) {
              this.visits.splice(index, 1);
              this.ms.replaceFirstMessage('Návšteva bola úspešne vymazaná', MessageType.SUCCESS);
            }
          });
        }
        else if (event.rangers.length == 1){
          this.ds.deleteVisit(actRangerObj.visitId, event.guardId).then(res => {
            if (res == true) {
              this.visits.splice(index, 1);
              this.ms.replaceFirstMessage('Návšteva bola úspešne vymazaná', MessageType.SUCCESS);
            }
          });
        }
      }
    });
  }
}

@Component({
  selector: 'edit-visits-description-dialog',
  templateUrl: 'edit-visits-description-dialog.html',
})
export class EditVisitsDescriptionDialog {

  constructor(
    public dialogRef: MatDialogRef<EditVisitsDescriptionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'delete-visit-dialog',
  templateUrl: 'delete-visit-dialog.html',
})
export class DeleteVisitDialog {
  message: string = "Chcete vykonať akciu?";
  confirmButtonText = "Áno";
  cancelButtonText = "Zrušiť";

  constructor(
    public dialogRef: MatDialogRef<DeleteVisitDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message ? data.message : this.message;
    this.confirmButtonText = data.confirmButtonText ? data.confirmButtonText : this.confirmButtonText;
    this.cancelButtonText = data.cancelButtonText ? data.cancelButtonText : this.cancelButtonText;
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  onCancelClick(): void {
    this.dialogRef.close(false);
  }
}
