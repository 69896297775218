<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Pridanie záznamu o návšteve územia</h1>
    <div [formGroup]="form">
      <mat-form-field class="full-width">
          <input matInput [matDatepicker]="picker" placeholder="Dátum návštevy" (dateInput)="onDateInputChange()" (click)="picker.open()" [formControl]="form.controls.dateFrom" id="date" name="date" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi="true"></mat-datepicker>
          <mat-error *ngIf="form.controls.dateFrom.errors && form.controls.dateFrom.errors.required">
              <mat-error >
                  Toto pole je povinné.
              </mat-error>
          </mat-error>
      </mat-form-field>
      <app-smart-select [control]="form.controls.region" placeholder="Kraj" [options]="regionOptions"></app-smart-select>
      <app-smart-select [control]="form.controls.district" placeholder="Okres" [options]="districtOptions"></app-smart-select>
      <app-smart-select [control]="form.controls.protectedArea" placeholder="Chránené územie" [options]="protectedAreaOptions"></app-smart-select>
      <app-smart-text-area [control]="form.controls.description" placeholder="Popis" [transformFunc]="transformTypes.capitalize" name="description"></app-smart-text-area>
    </div>
    <ng-container *ngIf="showAddUsers">
        <h3>Ďalší zúčastnení strážcovia:</h3>
          <div *ngFor="let user of selectedUsers; let i=index" class="selected-users-list">
            <app-app-icon name="person" class="icon" [clickable]="false"></app-app-icon>
            <span style="width: 180px;">{{ user.name }}</span>
            <div class="close-btn-wrapper">
              <i class="material-icons app-btn-icon clickable" (click)="onCloseClick(user)">close</i>
            </div>
          </div>

          <div class="new-user-wrapper">
            <app-app-icon name="person_add" [clickable]="false" class="icon"></app-app-icon>
            <app-smart-autocomplete (changeEvent)="onOptionSelected($event)" [allOptions]="filteredUsers" [required]="false" [clearAfterSelect]="true" placeholder="Ďalší strážca..."></app-smart-autocomplete>
          </div>
  </ng-container>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="shouldShowGuardArea">
  <mat-card-content>
    <app-app-spinner *ngIf="isLoadingGuard"></app-app-spinner>
    <div *ngIf="guard != null">
      <div>
        <h3>Pridať sa k spoločnej strážnej službe:</h3>
          <div class="item-wrapper">
            <mat-checkbox class="checkbox" [(ngModel)]="guardFormCheckbox.checked" [ngModelOptions]="{standalone: true}"></mat-checkbox>
            <div class="users-wrapper">
              <div *ngFor="let visit of guard.visits">
                <h3>{{visit.user.name}} {{visit.user.surname}}</h3>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div *ngIf="guard == null && !isLoadingGuard">
      <h3>Nenašla sa žiadna spoločná strážna služba.</h3>
    </div>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <mat-checkbox [(ngModel)]="addNextVisit" [disabled]="shouldDisableButton"><p class="agree-text">Pridať ďalší záznam.</p></mat-checkbox>
    <div class="col-4 col-sm-12 submit-btn-wrapper">
      <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="shouldDisableButton" class="dashboard-btn"><i class="material-icons app-btn-icon">{{ isLoadingData === true ? 'schedule' : 'send' }}</i>{{ isLoadingData === true ? (userVisitExists === true ? 'Aktualizuje sa záznam' : 'Pridáva sa záznam') : (userVisitExists === true ? 'Aktualizovať záznam' : 'Pridať záznam') }}</button>
      <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    </div>
  </mat-card-content>
</mat-card>