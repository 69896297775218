import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { FocusMonitor } from '@angular/cdk/a11y';
import { PersonFormComponent } from './components/forms/person-form/person-form.component';
import { AddRecordFormComponent } from './components/forms/add-record-form/add-record-form.component';
import { SmartInputComponent } from './components/inputs/smart-input/smart-input.component';
import { SmartSelectComponent } from './components/inputs/smart-select/smart-select.component';
import { AddressFormComponent } from './components/forms/address-form/address-form.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RecordFormComponent } from './components/forms/record-form/record-form.component';
import { DateTimeInputComponent } from './components/inputs/date-time-input/date-time-input.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DataService } from './services/data.service';
import { VehicleFormComponent } from './components/forms/vehicle-form/vehicle-form.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { RecordsListingPageComponent } from './pages/records-listing-page/records-listing-page.component';
import { AppRoutingModule } from './app-routing.module';
import { CreateRecordPageComponent } from './pages/create-record-page/create-record-page.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { CheckPersonPageComponent } from './pages/check-person-page/check-person-page.component';
import { CheckResultComponent } from './components/check-result/check-result.component'
import { AppSpinnerComponent } from './components/app-spinner/app-spinner.component';
import { RecordDetailPageComponent } from './pages/record-detail-page/record-detail-page.component';
import { RecordsListingTableComponent } from './components/records-listing-table/records-listing-table.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { FormsModule } from '@angular/forms';
import { RecordEnumsPipe } from './services/record-enums-pipe';
import { HTTP_INTERCEPTORS, HttpClientModule } from '../../node_modules/@angular/common/http';
import { JwtInterceptor } from './services/JwtInterceptor';
import { AppInitializerService } from './services/app-initializer.service';
import { AuthErrorHandlerService } from './services/auth-error-handler.service';
import { MessageBarComponent } from './components/message-bar/message-bar.component';
import { MessageItemComponent } from './components/message-item/message-item.component';
import { MessageService } from './services/message.service';
import { HistoryService } from './services/history.service';
import { ConfigService } from './services/config.service';
import { HttpModule } from '../../node_modules/@angular/http';
import { CreateVisitPageComponent } from './pages/create-visit-page/create-visit-page.component';
import { InviteNewUserPageComponent } from './pages/invite-new-user-page/invite-new-user-page.component';
import { ManagePendingRegistrationsPageComponent } from './pages/manage-pending-registrations-page/manage-pending-registrations-page.component';
import { PendingRegistrationItemComponent } from './components/pending-registration-item/pending-registration-item.component';
import { FillRegistrationFormPageComponent } from './pages/fill-registration-form-page/fill-registration-form-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { CreatePasswordPageComponent } from './pages/create-password-page/create-password-page.component';
import { PermissionsService } from './services/permissions.service';
import { VisitsPageComponent, EditVisitsDescriptionDialog, DeleteVisitDialog } from './pages/visits-page/visits-page.component';
import { VisitDetailPageComponent } from './pages/visit-detail-page/visit-detail-page.component';
import { VisitComponent } from './components/visit/visit.component';
import { RecordListTileComponent } from './components/record-list-tile/record-list-tile.component';
import { ManageUsersPageComponent } from './pages/manage-users/manage-users-page.component';
import { UserListTileComponent, DialogOverviewExampleDialog } from './components/user-list-tile/user-list-tile.component';
import { AppIconComponent } from './components/app-icon/app-icon.component';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { ManageAreasPageComponent } from './pages/manage-areas-page/manage-areas-page.component';
import { ProtectedAreaDetailPageComponent } from './pages/protected-area-detail-page/protected-area-detail-page.component';
import { DatePipe } from '../../node_modules/@angular/common';
import { SentInvitationsPageComponent } from './pages/sent-invitations-page/sent-invitations-page.component';
import { LoggingService } from './services/logging.service';
import { ShowLogsPageComponent } from './components/show-logs-page/show-logs-page.component';
import { DateAndPersonalInputComponent } from './components/inputs/date-and-personal-input/date-and-personal-input.component';
import { MaterialModule } from './material/material.module';
import { IDataserviceToken } from './services/i-data-service';
import { ILoggingServiceToken } from './services/i-logging-service';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ValuesOverTimeComponent } from './components/stats/values-over-time/values-over-time.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AdminStatsPageComponent } from './pages/admin-stats-page/admin-stats-page.component';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { TerritoryDistrictsComponent } from './components/territory-districts/territory-districts.component';
import { ShowEnumsPageComponent } from './pages/show-enums-page/show-enums-page.component';
import { EnumDetailPageComponent } from './pages/enum-detail-page/enum-detail-page.component';
import { EnumDetailTableComponent, EditEnumDataDialog, DeleteEnumDataDialog, CreateEnumDataDialog } from './components/enum-detail-table/enum-detail-table.component';
import { ManageCoordinatorsPageComponent } from './pages/manage-coordinators-page/manage-coordinators-page.component';
import { CreateNewUserPageComponent } from './pages/create-new-user-page/create-new-user-page.component';
import { CreateUserFormComponent } from './components/forms/create-user-form/create-user-form.component';
import { SmartTextAreaComponent } from './components/inputs/smart-text-area/smart-text-area.component';
import { SmartAutocompleteComponent } from './components/inputs/smart-autocomplete/smart-autocomplete.component';

@NgModule({
  declarations: [
    AppComponent,
    PersonFormComponent,
    AddRecordFormComponent,
    SmartInputComponent,
    SmartSelectComponent,
    AddressFormComponent,
    DateAndPersonalInputComponent,
    RecordFormComponent,
    DateTimeInputComponent,
    VehicleFormComponent,
    HomePageComponent,
    RecordsListingPageComponent,
    CreateRecordPageComponent,
    TopBarComponent,
    CheckPersonPageComponent,
    CheckResultComponent,
    AppSpinnerComponent,
    RecordDetailPageComponent,
    RecordsListingTableComponent,
    LoginPageComponent,
    RecordEnumsPipe,
    MessageBarComponent,
    MessageItemComponent,
    CreateVisitPageComponent,
    InviteNewUserPageComponent,
    ManagePendingRegistrationsPageComponent,
    PendingRegistrationItemComponent,
    FillRegistrationFormPageComponent,
    ResetPasswordPageComponent,
    CreatePasswordPageComponent,
    VisitsPageComponent,
    VisitDetailPageComponent,
    VisitComponent,
    RecordListTileComponent,
    ManageUsersPageComponent,
    UserListTileComponent,
    AppIconComponent,
    DialogOverviewExampleDialog,
    EditVisitsDescriptionDialog,
    DeleteVisitDialog,
    EditEnumDataDialog,
    DeleteEnumDataDialog,
    CreateEnumDataDialog,
    UnauthorizedPageComponent,
    ManageAreasPageComponent,
    ProtectedAreaDetailPageComponent,
    SentInvitationsPageComponent,
    ShowLogsPageComponent,
    UserProfilePageComponent,
    BackButtonComponent,
    ValuesOverTimeComponent,
    AdminStatsPageComponent,
    BottomBarComponent,
    TerritoryDistrictsComponent,
    ShowEnumsPageComponent,
    EnumDetailPageComponent,
    EnumDetailTableComponent,
    ManageCoordinatorsPageComponent,
    CreateNewUserPageComponent,
    CreateUserFormComponent,
    SmartTextAreaComponent,
    SmartAutocompleteComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AmazingTimePickerModule,
    NgxChartsModule
  ],
  providers: [
    FocusMonitor, 
    { provide: MAT_DATE_LOCALE, useValue: 'sk-SK'}, 
    {
      provide: IDataserviceToken,
      useClass: DataService
    },
    AppInitializerService,
    MessageService,
    HistoryService,
    ConfigService,
    PermissionsService,
    DatePipe,
    {
      provide: ILoggingServiceToken,
      useClass: LoggingService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [AppInitializerService], multi: true },
    {
      provide: ErrorHandler, 
      useClass: AuthErrorHandlerService
    }
  ],
  entryComponents: [DialogOverviewExampleDialog, EditVisitsDescriptionDialog, EditEnumDataDialog, DeleteEnumDataDialog, CreateEnumDataDialog, DeleteVisitDialog],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInitializerFactory(provider: AppInitializerService) {
  return () => provider.load();
}
