import { Component, OnInit, Inject } from '@angular/core';
import { PendingRegistration } from '../../types/types';
import { MessageService, MessageType } from '../../services/message.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-manage-pending-registrations-page',
  templateUrl: './manage-pending-registrations-page.component.html',
  styleUrls: ['./manage-pending-registrations-page.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class ManagePendingRegistrationsPageComponent implements OnInit {
  pendingRegistrations: PendingRegistration[];
  constructor(private ms: MessageService, @Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
    this.dataService.getPendingRegistrations().then((pendingRegistrations) => {
      this.pendingRegistrations = pendingRegistrations;
    })
  }

  onAllowClick(pReg: PendingRegistration) {
    this.removeProcessedRegistration(pReg.id);
    this.ms.replaceFirstMessage('Registrácia povolená.', MessageType.SUCCESS);
  }

  onDisallowClick(pReg: PendingRegistration) {
    this.removeProcessedRegistration(pReg.id);
    this.ms.replaceFirstMessage('Registrácia zamietnutá.', MessageType.SUCCESS);
  }

  private removeProcessedRegistration(id: string) {
    this.pendingRegistrations = this.pendingRegistrations.filter((reg) => {
      return reg.id != id;
    });
  }

}
