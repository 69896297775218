import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { Person } from '../../types/types';
import { mapBEPerson } from '../../utils/utils';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-create-record-page',
  templateUrl: './create-record-page.component.html',
  styleUrls: ['./create-record-page.component.css']
})
export class CreateRecordPageComponent implements OnInit {
  prefillPerson: Person;
  canShowForm: boolean = false;

  constructor(private route: ActivatedRoute, @Inject(IDataserviceToken) private dataService: IDataService){}

  ngOnInit(){
      const prefillPersonId = this.route.snapshot.params['personId'];
      if (prefillPersonId != null) {
         this.dataService.getPerson(prefillPersonId).then((person: Person) => {
           this.prefillPerson = mapBEPerson(person);
           this.canShowForm = true;
         });
      } else {
        this.canShowForm = true;
      }
  }
}
