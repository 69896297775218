import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { PendingRegistration, UniModel } from '../../types/types';
import { PermissionsService, RANGER_PERMISSION, OFFICER_PERMISSION } from '../../services/permissions.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { MessageType, MessageService } from '../../services/message.service';

@Component({
  selector: 'app-pending-registration-item',
  templateUrl: './pending-registration-item.component.html',
  styleUrls: ['./pending-registration-item.component.css']
})
export class PendingRegistrationItemComponent implements OnInit {
  @Input() item: PendingRegistration;
  @Output() allowClick: EventEmitter<PendingRegistration> = new EventEmitter<PendingRegistration>();
  @Output() disallowClick: EventEmitter<PendingRegistration> = new EventEmitter<PendingRegistration>();
  isLoadingAdminData = false;
  isLoadingAmbassadorData = false;
  isAdminSuccess;
  isAmbassadorSuccess;
  options;
  allDistricts: UniModel[] = [];
  selectedDistricts: UniModel[] = [];

  get isVerificationFinished() { return this.item != null && this.item.authority_ambassador != null && this.item.verification_result !== null }
  get isVerified() { return this.item.verification_result == true }

  get isAdminApproveButtonDisabled() { return this.isLoadingAdminData || this.isAdminSuccess || !this.isVerificationFinished}

  get isAmbassadorApproveButtonDisabled() { 
    return this.isLoadingAmbassadorData 
      || this.isVerificationFinished
      || this.isEditModeTerr
      || this.selectedDistricts.length == 0;
  }
  get isAmbassadorRefuseButtonDisabled() { 
    return this.isLoadingAmbassadorData 
      || this.isVerificationFinished
      || this.isEditModeTerr
  }

  isEditModeTerr;
  
  constructor(private ps: PermissionsService, 
              private ms: MessageService,
              @Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() { 
    this.options = this.ps.getAllPermissinosForUser().map((perm) => { 
      perm.checked = (perm.id == RANGER_PERMISSION.id && this.item.document_number != ".") || (perm.id == OFFICER_PERMISSION.id && this.item.document_number == "."); 
      return perm;
    });

    this.dataService.getDistrictOptions().then((data) => {
      this.allDistricts = data;
    });

    if (this.item.territory_disctricts != null && this.item.territory_disctricts.length > 0) {
      this.selectedDistricts = this.item.territory_disctricts;
    }

  }

  hasUserAdminPermission() {
    return this.ps.hasUserAdminPermission();
  }

  hasUserAuthorityAmbassadorPermission() {
    return this.ps.hasUserAuthorityAmbassadorPermission();
  }

  hasUserOfficerPermission() {
    return this.ps.hasUserOfficerPermission();
  }

  onAdminAllowClick() {
    if (!this.isVerified) {
      return false;
    }
    this.isLoadingAdminData = true;
    let arr = this.options.filter(perm => perm.checked);
    let ids = arr.map(perm => perm.id);
    const newPerm = ids.join('');
    this.dataService.allowFinishingOfRegistration(this.item.id, newPerm).then((success) => {
      // todo send the whole oject
      // send also territorry
      this.isAdminSuccess = success == true;
      this.isLoadingAdminData = false;

      if (success == false) {
        this.ms.replaceFirstMessage("Registráciu sa nepodarilo dokončiť, skúste to neskôr.", MessageType.ERROR);
        return false;
      }
      this.allowClick.emit(this.item);
    });
  }

  onAdminDisallowClick() {
    this.isLoadingAdminData = true;
    this.dataService.disallowFinishingOfRegistration(this.item.id).then((success) => {
      this.isLoadingAdminData = false;
      this.isAdminSuccess = success == true;
      this.disallowClick.emit(this.item);
    });
  }

  onAmbassadorButtonClick(approve: boolean) {
    this.isLoadingAmbassadorData = true;
    this.dataService.verifyRegistration(this.item.id, approve, this.selectedDistricts.map(dist => dist.id)).then((success) => {
      this.isLoadingAmbassadorData = false;
      this.isAmbassadorSuccess = success == true;

      if (success == false) {
        this.ms.replaceFirstMessage(`Registráciu sa nepodarilo ${approve ? 'dokonciť' : 'zrušiť'}, skúste to neskôr.`, MessageType.ERROR);
        return false;
      }

      this.item.verification_result = approve;
      this.item.authority_ambassador = {
        id: this.dataService.user.id,
        name: this.dataService.user.name,
        surname: this.dataService.user.surname
      }
      
      this.ms.replaceFirstMessage(approve ? 'Registrácia schválená.' : 'Registrácia zamietnutá.', approve ? MessageType.SUCCESS : MessageType.INFO);
    });
  }

  onSubmitNewDistricts(districts: UniModel[]) {
    this.selectedDistricts = districts;
    this.isEditModeTerr = false;
  }

  onCancelSubmission() {
    this.isEditModeTerr = false;
  }

  onEditIconClick() {
    this.isEditModeTerr = true;
  }

}
