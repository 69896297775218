<div [formGroup]="form">
    <!-- <p><span class="bold">Email:</span> {{ email }}</p> -->
    <app-smart-input [control]="form.controls.name" placeholder="Meno" [transformFunc]="transformTypes.capitalize" name="name"></app-smart-input>
    <app-smart-input [control]="form.controls.surname" placeholder="Priezvisko" [transformFunc]="transformTypes.capitalize" name="surname"></app-smart-input>
    <app-smart-input [control]="form.controls.mobile" placeholder="Mobil" name="mobile"></app-smart-input>
    <div *ngIf="isOnlyOfficer == false">
        <app-smart-input [control]="form.controls.street" placeholder="Ulica a číslo" name="street" [transformFunc]="transformTypes.capitalize"></app-smart-input>
        <app-smart-input [control]="form.controls.city" placeholder="Obec" [transformFunc]="transformTypes.capitalize" name="city"></app-smart-input>
        <app-smart-input [control]="form.controls.zip" placeholder="PSČ" name="zip"></app-smart-input>
        <app-smart-input [control]="form.controls.document_number" placeholder="Číslo preukazu člena Stráže prírody" name="document_number"></app-smart-input>
    </div>
</div>