<mat-card>
  <mat-card-content [hidden]="!dataSource">
    
    <div class="example-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="tableConfig.filterCriteria" placeholder="Filter">
        </mat-form-field>
      </div>
      
      <div class="example-container mat-elevation-z8">
      
        <mat-table [dataSource]="dataSource" matSort>
      
          <ng-container matColumnDef="personalId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Rodné číslo </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.personalId}} </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Meno </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.firstName}} </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Priezvisko </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.lastName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Dátum </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="protectedArea">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Miesto </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.protectedArea }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="deliquencyType">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Typ priestupku </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.deliquencyType }} </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="ranger">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Strážca </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.ranger}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row routerLink="/records/{{row.id}}" class="table-row" *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>
      
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageIndex]="tableConfig.pageIndex"></mat-paginator>
      </div>
  </mat-card-content>
  <mat-card-content *ngIf="!dataSource">
    <app-app-spinner></app-app-spinner>
  </mat-card-content>
</mat-card>