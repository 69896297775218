import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { ConfigService } from './config.service';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { ILoggingService } from './i-logging-service';

export enum LogType {
  INFO = 'INFO',
  ERROR = 'ERROR',
  LOGIN = 'LOGIN',
  MANUAL_LOGOUT = 'MANUAL_LOGOUT',
  AUTOMATIC_LOGOUT = 'AUTOMATIC_LOGOUT',
  INVALID_MAIL_OR_PASSWORD = 'INVALID_MAIL_OR_PASSWORD',
  NON_ACTIVATED_ACCOUNT_LOGIN_ATTEMPT = '$NON_ACTIVATED_ACCOUNT_LOGIN_ATTEMPT',
  PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED',
  PASSWORD_RESET_SUCCESSFULLY = 'PASSWORD_RESET_SUCCESSFULLY',
  PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED',
  ACTIVITY_REPORT_PDF_DOWNLOADED = 'ACTIVITY_REPORT_PDF_DOWNLOADED',
  REPORT_NOTICE_PDF_DOWNLOADED = 'REPORT_NOTICE_PDF_DOWNLOADED',
  ADD_VISIT = 'ADD_VISIT',
  ADD_RECORD = 'ADD_RECORD',
  CHECK_PERSON = 'CHECK_PERSON',
  LIST_RECORDS = 'LIST_RECORDS',
  ALL = 'ALL'
}

export interface LogInterface {
  type: LogType;
  message: string;
  data?: string;
}

@Injectable()
export class LoggingService implements ILoggingService{
  private LOG_URL = this.BASE_URL + 'log-message';

  private get BASE_URL() {return ConfigService.settings.apiBaseUrl; }
  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  log(type: LogType, message: string, data?: any) {
    // <i class="material-icons">holiday_village</i>
    const log: LogInterface = {
      type: type,
      message: message,
      data: data
    }
    return this.http.post<any>(this.LOG_URL, {log: log}).subscribe((success) => {}, (error) => {}, () => {});
  }

  logInfo(message: string) {
    return this.log(LogType.INFO, message);
  }

  logError(message: string, error?: any) {
    return this.log(LogType.ERROR, message, error == null ? (new Error()).stack : error);
  }

}
