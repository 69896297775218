import { Component, OnInit, Inject } from '@angular/core';
import { IDataserviceToken, IDataService } from '../../services/i-data-service';
import { PermissionsService } from '../../services/permissions.service';

@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.css']
})
export class UserProfilePageComponent implements OnInit {

  constructor(
    private permService: PermissionsService,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  userPermissions;

  get user() { return this.dataService.user };
  get isOfficer() { return this.user.document_number == "." };
  ngOnInit() {
    this.userPermissions = this.permService.getAllPermissinosForUser(this.user);
  }

}
