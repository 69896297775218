import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { environment } from '../../environments/environment';

export interface AppConfig {
  apiBaseUrl: string;
}


@Injectable()
export class ConfigService {

  static settings: AppConfig;

    constructor(private http: Http) {}

    load() {
      return new Promise<void>((resolve, reject) => {
        const configPath = `assets/config/app-config.${environment.name}.json`;
          this.http.get(configPath).toPromise().then((response : Response) => {
            ConfigService.settings = <AppConfig>response.json();
            console.log('App settings loaded successfully!');
              resolve();
          }).catch((response: any) => {
              reject(`Could not load file '${configPath}': ${JSON.stringify(response)}`);
          });
      });
    }

}
