<div class="col-12 center">
  <h2 style="text-align: center">Čo chcete urobiť?</h2>
</div>
<div *ngIf="hasUserOneOfFollowingPermissions([permission.Ranger, permission.Officer])">
  <hr />
  <div class="col-5 col-sm-6 col-xs-12 center">
    <h3>Evidencia priestupkov</h3>
    <button mat-raised-button color="primary" routerLink="/check-person" class="dashboard-btn"><i class="material-icons app-btn-icon">fingerprint</i>Overiť osobu</button>
    <button *ngIf="ps.hasUserRangerPermission()" mat-raised-button color="primary" routerLink="/create-record" class="dashboard-btn"><i class="material-icons app-btn-icon">playlist_add</i>Pridať priestupok</button>
    <button mat-raised-button color="primary" routerLink="/records" class="dashboard-btn"><i class="material-icons app-btn-icon">storage</i>Prehliadať {{ hasUserOfficerPermission() ? '' : 'moje' }}  priestupky</button>
  </div>
</div>
<div *ngIf="hasUserOneOfFollowingPermissions([permission.Ranger, permission.Officer, permission.Admin])">
  <hr />
  <div class="col-5 col-sm-6 col-xs-12 center">
    <h3>Aktivita</h3>
    <button *ngIf="ps.hasUserRangerPermission()" mat-raised-button color="primary" routerLink="/create-visit" class="dashboard-btn"><i class="material-icons app-btn-icon">location_on</i>Pridať návštevu územia</button>
    <button *ngIf="ps.hasUserRangerPermission()" mat-raised-button color="primary" routerLink="/visits" class="dashboard-btn"><i class="material-icons app-btn-icon">nature</i>Prezerať moje návštevy územia</button>
    <button *ngIf="ps.hasUserOfficerPermission() || ps.hasUserAdminPermission()" mat-raised-button color="primary" routerLink="/visits/all" class="dashboard-btn"><i class="material-icons app-btn-icon">filter</i>Prezerať všetky návštevy územia</button>
  </div>
</div>
<div *ngIf="hasUserOneOfFollowingPermissions([permission.Admin, permission.Officer])">
  <hr />
  <div class="col-5 col-sm-6 col-xs-12 center">
    <h3>Chránené územia</h3>
    <button mat-raised-button color="primary" routerLink="/manage-protected-areas" class="dashboard-btn"><i class="material-icons app-btn-icon">landscape</i>Prezerať chránené územia</button>
  </div>
</div>
<div *ngIf="hasUserOneOfFollowingPermissions([permission.Admin, permission.Officer, permission.AuthorityAmbassador])">
  <hr />
  <div class="col-5 col-sm-6 col-xs-12 center">
    <h3>Správa používateľov</h3>
    <ng-container *ngIf="ps.hasUserAdminPermission()">
      <button mat-raised-button color="primary" routerLink="/invite-user" class="dashboard-btn"><i class="material-icons app-btn-icon">person_add</i>Prizvať nového používateľa</button>
      <button mat-raised-button color="primary" routerLink="/sent-invitations" class="dashboard-btn"><i class="material-icons app-btn-icon">watch_later</i>Odoslané pozvánky</button>
    </ng-container>
    <ng-container *ngIf="hasUserOneOfFollowingPermissions([permission.Admin, permission.AuthorityAmbassador, permission.Officer])">
      <button mat-raised-button color="primary" routerLink="/manage-pending-registrations" class="dashboard-btn"><i class="material-icons app-btn-icon">verified_user</i>Schvaľovať žiadosti o registráciu</button>
    </ng-container>
    <ng-container *ngIf="hasUserOneOfFollowingPermissions([permission.Admin, permission.Officer, permission.AuthorityAmbassador])">
      <button mat-raised-button color="primary" routerLink="/manage-users" class="dashboard-btn"><i class="material-icons app-btn-icon">people</i>Spravovať používateľov</button>
    </ng-container>
    <ng-container *ngIf="hasUserOneOfFollowingPermissions([permission.Admin, permission.AuthorityAmbassador])">
      <button mat-raised-button color="primary" routerLink="/manage-coordinators" class="dashboard-btn"><i class="material-icons app-btn-icon">add_moderator</i>Spravovať koordinátorov</button> 
    </ng-container>  
    <ng-container *ngIf="hasUserOneOfFollowingPermissions([permission.AuthorityAmbassador])">
      <button mat-raised-button color="primary" routerLink="/create-user" class="dashboard-btn"><i class="material-icons app-btn-icon">person_add</i>Vytvoriť používateľský účet</button>
    </ng-container>
  </div>
</div>
<div *ngIf="hasUserAdminPermission()">
  <hr />
  <div class="col-5 col-sm-6 col-xs-12 center">
    <h3>Beh aplikácie</h3>
    <button mat-raised-button color="primary" routerLink="/show-logs" class="dashboard-btn"><i class="material-icons app-btn-icon">list</i>Prezerať Logy</button>
    <button mat-raised-button color="primary" routerLink="/usage-stats" class="dashboard-btn"><i class="material-icons app-btn-icon">show_chart</i>Štatistika používania</button>
  </div>
</div>
<div *ngIf="hasUserContentEditorPermission()">
  <hr />
  <div class="col-5 col-sm-6 col-xs-12 center">
    <h3>Číselníky</h3>
    <button mat-raised-button color="primary" routerLink="/show-enums" class="dashboard-btn"><i class="material-icons app-btn-icon">list</i>Prezerať číselníky</button>
  </div>
</div>
