<app-back-button></app-back-button>

<mat-card>
  <mat-card-content>
    <h1>Vytvorenie nového používateľského účtu</h1>

    <h3>Zadajte email nového používateľa:</h3>
    <div class="check-input-wrapper">
      <app-smart-input [control]="emailControl" name="email" placeholder="Email"></app-smart-input>
      <div class="officer-checkbox-wrapper">
        <mat-checkbox [(ngModel)]="isRanger" (change)="onRangerChange()">Ranger</mat-checkbox>    
      </div>
      <div class="officer-checkbox-wrapper">
        <mat-checkbox [(ngModel)]="isOfficer">Officer</mat-checkbox>    
      </div>
    </div>
    
    <ng-container *ngIf="isRanger">
    <app-smart-autocomplete (changeEvent)="onPamChange($event)" [allOptions]="autocompletePams" placeholder="Domovská správa" class="full-width"></app-smart-autocomplete>
    </ng-container>

    <app-create-user-form [form]="userForm" [isOnlyOfficer]="!isRanger"></app-create-user-form>

    <ng-container *ngIf="isRanger">
        <div class="territory-wrapper">
            <app-territory-districts 
              [canEdit]="true" 
              (submitNewDistricts)="onSubmitNewDistricts($event)" 
              (isEditModeChange)="onEditModeChange($event)"
              [allDistricts]="allDistricts" 
              [userDistricts]="newUser.territory_disctricts" 
              [isLoading]="isLoadingUpdateDistricts">
            </app-territory-districts>
            <app-app-spinner *ngIf="isLoadingUpdateDistricts"></app-app-spinner>
        </div>
    </ng-container>
    
    <div class="col-4 col-sm-12 submit-btn-wrapper">
      <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="shouldDisableButton" class="dashboard-btn"><i class="material-icons app-btn-icon">{{ isLoadingData === true ? 'schedule' : 'send' }}</i>{{ isLoadingData === true ? 'Vytvára sa používateľ' : 'Vytvoriť používateľa' }}</button>
      <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
    </div>

  </mat-card-content>
</mat-card>