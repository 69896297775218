import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { ProtectedAreaTile } from '../../types/types';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { HistoryService } from '../../services/history.service';
import { removeDiacritics } from '../../utils/utils';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-manage-areas-page',
  templateUrl: './manage-areas-page.component.html',
  styleUrls: ['./manage-areas-page.component.css']
})
export class ManageAreasPageComponent implements OnInit {
  @ViewChild('picker') datePicker: any;
  isLoadingData = false;
  protectedAreas: ProtectedAreaTile[];
  filteredAreas: ProtectedAreaTile[];
  lastFilter: string;
  inputControl = new FormControl('');
  date = new FormControl(new Date());
  constructor(private historyService: HistoryService, @Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
    this.isLoadingData = true;
    this.dataService.getProtectedAreasWithDistrict().then((pAreas) => {
      this.protectedAreas = pAreas;
      this.filteredAreas = pAreas;
      this.inputControl.setValue(this.historyService.manageProtectedAreasFilter);
      this.lastFilter = this.historyService.manageProtectedAreasFilter;
      this.filteredAreas = this.protectedAreas.filter(area => this.byDate(area, this.date.value));
      this.isLoadingData = false;
    });

    this.inputControl.valueChanges.subscribe((value) => {
      this.onFilterChange(value);
    });
    this.date.valueChanges.subscribe(() => {this.onFilterChange(this.lastFilter)})
  }

  onFilterChange(filterValue: string) {
    this.historyService.manageProtectedAreasFilter = filterValue;
    if (filterValue == '') {
      this.filteredAreas = this.protectedAreas.filter(area => this.byDate(area, this.date.value));
      return;
    }
    this.lastFilter = removeDiacritics(filterValue.toLowerCase());
    this.filteredAreas = this.protectedAreas.filter(area => this.unifyArea(area).indexOf(this.lastFilter) > -1).filter(area => this.byDate(area, this.date.value));
  }

  private byDate(area: ProtectedAreaTile, date: Date) {
    const result = new Date(area.validFrom) < date && new Date(area.validTo) >= date;
    return result;
  }

  private unifyArea(area: ProtectedAreaTile) {
    const str = removeDiacritics(`${area.name} ${area.district}`.toLowerCase());
    return str;
  }

  onDateInputChange() {
    if (this.date.value == null) {
      this.date.setValue(null);
    }
    this.datePicker.open();
  }

}
