<form [formGroup]="addRecordForm">
  <app-person-form [parentFormGroup]="addRecordForm.controls.personForm"></app-person-form>
  <app-record-form [parentFormGroup]="addRecordForm.controls.recordForm"></app-record-form>
  <!-- <pre>{{ addRecordForm.valid }}</pre>
  <pre>{{ addRecordForm.value | json}}</pre>
  <pre>{{ addRecordForm.getRawValue() | json}}</pre> -->
  <div class="col-4 col-sm-12 submit-btn-wrapper">
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="shouldDisableButton" class="dashboard-btn"><i class="material-icons app-btn-icon">{{ isLoadingData === true ? 'schedule' : 'send' }}</i>{{ isLoadingData === true ? 'Pridáva sa priestupok' : 'Pridať priestupok' }}</button>
    <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
  </div>
</form>
