import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.css']
})
export class AppSpinnerComponent implements OnInit {
  @Input() size: number = 100;
  constructor() { }

  ngOnInit() {
  }

}
