<div class="form-wrapper">
  <mat-card>
      <mat-card-content>
          <app-app-spinner *ngIf="isTokenValid == null"></app-app-spinner>
          <div *ngIf="isTokenValid == true">
            <h2>Žiadosť o vytvorenie prístupu{{ isOfficer == true ? ' (zamestnanec OÚ/ŠOP SR)' : '' }} pre:</h2>
            <div *ngIf="!shouldShowSucessConfirmation">
              <div [formGroup]="form">
                <p><span class="bold">Email:</span> {{ email }}</p>
                <app-smart-input [control]="form.controls.name" placeholder="Meno" [transformFunc]="transformTypes.capitalize" name="name"></app-smart-input>
                <app-smart-input [control]="form.controls.surname" placeholder="Priezvisko" [transformFunc]="transformTypes.capitalize" name="surname"></app-smart-input>
                <app-smart-input [control]="form.controls.mobile" placeholder="Mobil" name="mobile"></app-smart-input>
                <div *ngIf="isOfficer == false">
                    <app-smart-input [control]="form.controls.street" placeholder="Ulica a číslo" name="street" [transformFunc]="transformTypes.capitalize"></app-smart-input>
                    <app-smart-input [control]="form.controls.city" placeholder="Obec" [transformFunc]="transformTypes.capitalize" name="city"></app-smart-input>
                    <app-smart-input [control]="form.controls.zip" placeholder="PSČ" name="zip"></app-smart-input>
                    <app-smart-input [control]="form.controls.document_number" placeholder="Číslo preukazu člena Stráže prírody" name="document_number"></app-smart-input>
                </div>
                </div>
                <div class="col-4 col-sm-6 col-xs-12 submit-btn-wrapper">
                  <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="shouldDisableButton" class="full-width"><i class="material-icons app-btn-icon">{{ isLoadingData === true ? 'schedule' : 'send' }}</i>{{ isLoadingData === true ? 'Odosiela sa žiadosť' : 'Odoslať žiadosť o registráciu' }}</button>
                  <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
                </div>
              </div>
            </div>
            <div *ngIf="shouldShowSucessConfirmation" class="success-container">
              <app-app-icon name="done" [style.paddingLeft.px]="10"></app-app-icon>
              <h3>Žiadosť úspešne odoslaná, po schválení žiadosti dostanete email s ďalšími inštrukciami.</h3>
            </div>
          <div *ngIf="isTokenValid == false">
            <h3>Aktivačný link zrejme vypršal</h3>
          </div>
      </mat-card-content>
  </mat-card>
</div>