import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ]),
    trigger('accentAnimation', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0.2})),
      transition(':enter', [
        animate(200)
      ]),
      // transition('in => out', [
      //   animate(200)
      // ]),
      transition('out => in', [
        animate(400)
      ]),
    ]),
  ]
})
export class MessageBarComponent implements OnInit {
  isFloating: boolean = false;
  placeholderHeight;
  get startAccent() { return this.messageService.startAccent;};
  @HostListener('window:scroll', ['$event']) 
    doSomething(event) {
      // console.debug("Scroll Event", document.body.scrollTop);
      // see András Szepesházi's comment below
      // console.log("Scroll Event", window.pageYOffset );
      this.isFloating = window.pageYOffset > 100;
    }

  get messages() { this.placeholderHeight = this.element.nativeElement.offsetHeight; return this.messageService.messages; }

  constructor(private messageService: MessageService, private element: ElementRef) { }

  ngOnInit() {
  }

  getClasses() {
    return this.isFloating ? 'floating-item col-8 col-sm-12 item' : 'item-scroll-up item';
  }

}
