import { Injectable, Inject } from '@angular/core';
import { UniModel, User } from '../types/types';
import { IDataService, IDataserviceToken } from './i-data-service';

export enum Permission {
  Admin = "0",
  Officer = "1",
  Ranger = "2",
  AuthorityAmbassador = "3",
  ContentEditor = "4",
}


export const PERMISSION_DELIMITER: string = '~';

export const ADMIN_PERMISSION: UniModel = {id: Permission.Admin, name: 'Admin'};
export const OFFICER_PERMISSION: UniModel = {id: Permission.Officer, name: 'Officer'};
export const RANGER_PERMISSION: UniModel = {id: Permission.Ranger, name: 'Ranger'};
export const AUTHORITY_AMBASSADOR: UniModel = {id: Permission.AuthorityAmbassador, name: 'AuthorityAmbassador'};
export const CONTENT_EDITOR: UniModel = {id: Permission.ContentEditor, name: 'ContentEditor'};

@Injectable()
export class PermissionsService {

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) { }

  hasUserPermission(permission: Permission | string, user: User = this.dataService.user) {
    return user.authorization_rank
      .split(PERMISSION_DELIMITER)
      .find(currentItem => currentItem == permission) != null;
  }

  hasUserOneOfFollowingPermissions(permissions: Permission[], user: User = this.dataService.user) {
    let result = false;
    permissions.forEach((permission) => {
      if (this.hasUserPermission(permission, user)) {
        result = true;
      }
    });
    return result;
  }

  getAllPermissions() {
    return [ADMIN_PERMISSION, OFFICER_PERMISSION, RANGER_PERMISSION, AUTHORITY_AMBASSADOR, CONTENT_EDITOR];
  }

  getAllPermissinosForUser(user: User = null) {
    return this.getAllPermissions().map((perm) => {
      return {
        id: perm.id,
        name: perm.name,
        checked: user == null ? false : this.hasUserPermission(perm.id, user)
      }
    });
  }

  hasUserAdminPermission() {
    return this.hasUserPermission(Permission.Admin);
  }

  hasUserOfficerPermission() {
    return this.hasUserPermission(Permission.Officer);
  }

  hasUserRangerPermission() {
    return this.hasUserPermission(Permission.Ranger);
  }

  hasUserAuthorityAmbassadorPermission() {
    return this.hasUserPermission(Permission.AuthorityAmbassador);
  }

  hasUserContentEditorPermission() {
    return this.hasUserPermission(Permission.ContentEditor);
  }
}
