import { Component, OnInit, Inject } from '@angular/core';
import { HistoryService } from '../../services/history.service';
import { PermissionsService, Permission } from '../../services/permissions.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  permission = Permission;
  constructor(
    private historyService: HistoryService, 
    private ps: PermissionsService,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  public get user() { return this.dataService.user; };

  ngOnInit() {
    this.historyService.clearHistory();
  }

  hasUserPermission(permission: Permission) {
    return this.ps.hasUserPermission(permission);
  }

  hasUserOneOfFollowingPermissions(permissions: Permission[]) {
    return this.ps.hasUserOneOfFollowingPermissions(permissions);
  }

  hasUserOfficerPermission() {
    return this.ps.hasUserOfficerPermission();
  }

  hasUserAdminPermission() {
    return this.ps.hasUserAdminPermission();
  }
  hasUserContentEditorPermission() {
    return this.ps.hasUserContentEditorPermission();
  }

}
