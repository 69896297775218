<h1 mat-dialog-title>Vytvorenie záznamu</h1>
<div mat-dialog-content>
  <div style="overflow: hidden;">
    <ng-container *ngFor="let key of data.keys">
      <mat-form-field class="full-width">
        <input matInput [placeholder]="key" [(ngModel)]="data.enumData[key]" required>
      </mat-form-field>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data.enumData" [disabled]="!isValid(data.enumData, countKeys(data.keys))">Vytvoriť</button>
  <button mat-raised-button (click)="onNoClick()">Zrušiť</button>
</div>