<h1 mat-dialog-title>Úprava záznamu</h1>
<div mat-dialog-content>
  <div style="overflow: hidden;">
    <mat-form-field class="full-width">
      <input matInput [disabled]="true" placeholder="id" [(ngModel)]="data.id">
    </mat-form-field>
    <ng-container *ngFor="let key of objectKeys(data)">
      <mat-form-field class="full-width" *ngIf="key != 'id'">
        <input matInput [placeholder]="key" [(ngModel)]="data[key]" required>
      </mat-form-field>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" [disabled]="!isValid(data)">Uložiť</button>
  <button mat-raised-button (click)="onNoClick()">Zrušiť</button>
</div>