import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateUserFormComponent } from '../../components/forms/create-user-form/create-user-form.component';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { ILoggingService, ILoggingServiceToken } from '../../services/i-logging-service';
import { MessageService, MessageType } from '../../services/message.service';
import { OFFICER_PERMISSION, Permission, PERMISSION_DELIMITER, RANGER_PERMISSION } from '../../services/permissions.service';
import { PAManagingOrgan, UniModel, User } from '../../types/types';
import { removeDiacritics } from '../../utils/utils';

@Component({
  selector: 'app-create-new-user-page',
  templateUrl: './create-new-user-page.component.html',
  styleUrls: ['./create-new-user-page.component.css']
})
export class CreateNewUserPageComponent implements OnInit {
  userForm: FormGroup;
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  pams: PAManagingOrgan[] = [];
  autocompletePams: UniModel[];
  selectedPam: PAManagingOrgan;
  
  isOfficer: boolean = false;
  isRanger: boolean = true;
  isLoadingUpdateDistricts: boolean = false;
  isLoadingData: boolean = false;

  allDistricts: UniModel[] = [];
  newUser: User = new User();
  isEditingTerritory = false;

  get shouldDisableButton() { 
    return this.isLoadingData || !this.userForm.valid
     || !this.emailControl.valid || (this.isRanger && this.selectedPam == null)
     || this.isEditingTerritory
  }

  constructor(private fb: FormBuilder, private ms: MessageService, private router: Router, 
    @Inject(IDataserviceToken) private dataService: IDataService, @Inject(ILoggingServiceToken) private ls: ILoggingService) { }

  ngOnInit(): void {
    this.userForm = CreateUserFormComponent.createForm(this.fb, !this.isRanger);
    this.dataService.getDistrictOptions().then((dis) => {
      this.allDistricts = dis;
    });
    this.dataService.getAllPAMOrgans().then((pams) => {
      this.pams = pams; 
      this.autocompletePams = this.pams.map((pam: PAManagingOrgan) => {
        return {
          id: pam.id,
          name: `[${pam.id}] - ${pam.name}`
        }
      })
  });
  }

  onRangerChange() {
    this.userForm = CreateUserFormComponent.createForm(this.fb, !this.isRanger);
    this.selectedPam = null;
  }

  onSubmitNewDistricts(districts: UniModel[]) {
    this.newUser.territory_disctricts = districts;
  }

  onEditModeChange(newIsEditMode: boolean) {
    this.isEditingTerritory = newIsEditMode;
  }

  onSubmit() {
    this.isLoadingData = true;

    this.newUser.email = this.emailControl.value;
    this.newUser.name = this.userForm.get('name').value;
    this.newUser.surname = this.userForm.get('surname').value;
    this.newUser.mobile = this.userForm.get('mobile').value;

    this.newUser.street = !this.isRanger ? '.' : this.userForm.get('street').value;
    this.newUser.city = !this.isRanger ? '.' : this.userForm.get('city').value;
    this.newUser.zip = !this.isRanger ? '.' : this.userForm.get('zip').value;
    this.newUser.document_number = !this.isRanger ? '.' : this.userForm.get('document_number').value;

    let rank = `${this.isRanger ? RANGER_PERMISSION.id : ''}${PERMISSION_DELIMITER}${this.isOfficer ? OFFICER_PERMISSION.id : ''}`;
    // remove first and last ~
    rank = rank[0] == PERMISSION_DELIMITER ? rank.substring(1) : rank;
    rank = rank[rank.length-1] == PERMISSION_DELIMITER ? rank.slice(0, -1) : rank;

    this.newUser.authorization_rank = rank;
    this.newUser.managed_by = !this.isRanger ? null : this.selectedPam.id;

    this.dataService.createUser(this.newUser).then((response) => {
      this.isLoadingData = false
      if (response.success) {
        this.ms.replaceFirstMessage(response.message, MessageType.SUCCESS);
        this.router.navigate(['home']);
      } else if (response.message == 'isDuplicate'){
        this.ms.replaceFirstMessage('Zadaná emailová adresa už existuje v systéme.', MessageType.INFO, 0);
      } else {
        const msg = 'Nepodarilo sa vytvoriť používateľa, skúste to, prosím, neskôr.';
        this.ls.logError(msg);
        this.ms.replaceFirstMessage(msg, MessageType.ERROR, 0);
      }
    });
  }

  onPamChange(newVal: UniModel) {
    this.selectedPam = this.pams.find(pam => pam.id == newVal.id);
  }

}
