<mat-form-field class="full-width">
    <input id="personalId" matInput [readonly]="personalDisabled" type="number" placeholder="Rodné číslo" (input)="onPersonalChange()" (valueChanges)="onPersonalChange()" [formControl]="personalControl"
        name="personalId" autocomplete="off">
    <mat-error *ngIf="shouldShowErrors(personalControl)">
        <mat-error *ngFor="let error of listOfErrors(personalControl)">
            {{error}}
        </mat-error>
    </mat-error>
</mat-form-field>

<p>ALEBO</p>

<mat-form-field class="full-width">
    <input matInput [readonly]="dateDisabled" [matDatepicker]="picker" placeholder="Dátum narodenia" (dateInput)="onDateInputChange()" (click)="picker.open()" (dateChange)="onDateChange()" [formControl]="dateControl" id="birthDate" name="birthDate" autocomplete="off">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker touchUi="true"></mat-datepicker>
    <mat-error *ngIf="shouldShowErrors(dateControl)">
        <mat-error *ngFor="let error of listOfErrors(dateControl)">
            {{error}}
        </mat-error>
    </mat-error>
</mat-form-field>

<mat-radio-group id="gender" [formControl]="genderControl" (change)="onSexChange()">
    <mat-radio-button [value]="sex.MALE">Muž</mat-radio-button>
    <mat-radio-button [value]="sex.FEMALE">Žena</mat-radio-button>
</mat-radio-group>
