<p><span class="bold">Obvod pôsobnosti:</span></p>
<app-app-icon *ngIf="canEdit" tooltip="Upraviť obvod pôsobnosti" name="edit" (clickEvent)="onEditIconClick()" [disabled]="isEditMode"></app-app-icon>
<ng-container *ngIf="!isEditMode && !isLoading">
  <mat-chip-list>
    <mat-chip *ngFor="let district of districts">{{ district.name }}</mat-chip>
  </mat-chip-list>
</ng-container>
<ng-container *ngIf="isEditMode || isLoading">
  <div class="col-6 dis-helper-btns">
    <p class="bold">Okresy z:</p>
    <app-smart-select [control]="regionCtrl" placeholder="Kraj" [options]="regions"></app-smart-select>
    <app-app-icon [disabled]="regionCtrl.value == null" tooltip="Pridať okresy z vybraného kraja" name="add" (clickEvent)="onAddRegionIconClick()"></app-app-icon>
  </div>

  <div class="col-6 dis-helper-btns">
    <p class="bold">Okresy z:</p>
    <app-smart-select [control]="pamCtrl" placeholder="Správa" [options]="pams"></app-smart-select>
    <app-app-icon [disabled]="pamCtrl.value == null" tooltip="Pridať okresy z vybranej správy" name="add" (clickEvent)="onAddPAMIconClick()"></app-app-icon>
  </div>

  <mat-form-field class="chip-list" appearance="fill">
    <mat-label>Okresy pôsobnosti</mat-label>
    <mat-chip-list #chipList aria-label="Výber okresov" [disabled]="isLoading">
      <mat-chip *ngFor="let district of districts; let i=index;" [selectable]="selectable" [removable]="true"
        (removed)="remove(district, i)">
        {{district.name}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input placeholder="Nový okres..." #districtInput [formControl]="districtCtrl" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected([$event.option.value])">
      <mat-option *ngFor="let district of filteredDistricts | async" [value]="district">
        {{district.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <app-app-icon [disabled]="districts.length == 0" tooltip="Vymazať všetky" name="delete" (clickEvent)="onTrashIconClick()"></app-app-icon>
  <p style="padding: 10px;">Duplicitné okresy budú vymazané pri ukladaní.</p>
</ng-container>
<div *ngIf="isEditMode" class="btn-wrapper">
  <button mat-raised-button color="warn" (click)="onCancel()" class="dashboard-btn"><i
    class="material-icons app-btn-icon">cancel</i>Zrušiť</button>
  <button mat-raised-button color="primary" (click)="onSubmit()" class="dashboard-btn"><i
      class="material-icons app-btn-icon">save</i>Uložiť</button>
</div>