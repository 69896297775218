import { Component, OnInit, Inject } from '@angular/core';
import { IDataserviceToken, IDataService } from '../../services/i-data-service';
import { LogType } from '../../services/logging.service';
import { ValueInTime } from '../../types/types';
import { filterLogsToValueInTime } from '../../utils/utils';

@Component({
  selector: 'app-admin-stats-page',
  templateUrl: './admin-stats-page.component.html',
  styleUrls: ['./admin-stats-page.component.css']
})
export class AdminStatsPageComponent implements OnInit {

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) { }

  statsToDisplay: LogType[] = [
    LogType.ALL,
    LogType.LIST_RECORDS,
    LogType.CHECK_PERSON,
    LogType.ADD_RECORD,
    LogType.ADD_VISIT,
    LogType.LOGIN,
    LogType.ACTIVITY_REPORT_PDF_DOWNLOADED
  ];
  datesMap = {};

  ngOnInit() {
  
    this.dataService.getLogs(365).then((res) => {
      this.statsToDisplay.forEach((stat) => {
        this.datesMap[stat] = filterLogsToValueInTime(res, stat);
      });

    });
  }

}
