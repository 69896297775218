import { Component, OnInit, Input } from '@angular/core';
import { Visit } from '../../types/types';

@Component({
  selector: 'app-visit-detail-page',
  templateUrl: './visit-detail-page.component.html',
  styleUrls: ['./visit-detail-page.component.css']
})
export class VisitDetailPageComponent implements OnInit {

  @Input() visit: Visit;
  constructor() { }

  ngOnInit() {
  }

}
