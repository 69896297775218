<mat-card class="child">
  <mat-card-content>
    <h2>Prihlásenie</h2>
    <div class="col-6 col-sm-12">
      <app-smart-input [control]="loginControl" type="email" name="login" [value]="lastLoginName" [disabled]="loadingData" (onFocus)="onFocus()" placeholder="Email"></app-smart-input>
      <app-smart-input [control]="passControl" type="password" name="pass" [disabled]="loadingData" (onFocus)="onFocus()" placeholder="Heslo"></app-smart-input>
    </div> 
    <div class="col-12 col-sm-12">
        <mat-checkbox [(ngModel)]="checked" [disabled]="loadingData"><p class="agree-text">Beriem na vedomie, že poskytnutie prihlasovacích údajov neoprávnenej osobne alebo zneužitie informácií môže byť trestným činom.</p></mat-checkbox>
    </div> 
    <div class="col-6 col-sm-12">
        <span class="login-spinner">
          <app-app-spinner *ngIf="loadingData" size="50"></app-app-spinner>
        </span>
      <div class="col-6 xs-full-width button-wrapper">
          <button mat-raised-button color="primary" [disabled]="disableButton" (click)="onClick()" class="full-width"><i class="material-icons">how_to_reg</i>Prihlásiť</button>
      </div>
    </div>
    <div>
      <p routerLink="/reset-password" class="clickable">Zabudnuté heslo?</p>
    </div>
  </mat-card-content>
</mat-card>
