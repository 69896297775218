import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { SmartInputComponent } from '../smart-input/smart-input.component';
import { FormBuilder } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { UniModel } from '../../../types/types';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-smart-select',
  templateUrl: './smart-select.component.html',
  styleUrls: ['./smart-select.component.css']
})
export class SmartSelectComponent extends SmartInputComponent implements OnInit {

  @Input() options: UniModel[];
  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  constructor(fb: FormBuilder, public fm: FocusMonitor, public elRef: ElementRef) {
    super(fb, fm, elRef);
  }

  ngOnInit() {
  }

  onSelectionChange(event) {
    this.selectionChange.emit(event);
  }

}
