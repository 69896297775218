import { Component, OnInit, ViewChild, HostListener, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Record, UniModel } from '../../types/types';
import { removeDiacritics } from '../../utils/utils';
import { TableConfig } from '../../services/history.service';
import { DatePipe } from '../../../../node_modules/@angular/common';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

interface TableDataRecord {
  id: string;   
  personalId: string;
  firstName: string;
  lastName: string;
  date: string;
  protectedArea: string;
  ranger: string;
  deliquencyType: string;
}

@Component({
  selector: 'app-records-listing-table',
  templateUrl: './records-listing-table.component.html',
  styleUrls: ['./records-listing-table.component.css']
})
export class RecordsListingTableComponent implements OnInit, OnChanges {
  @Input() records: Record[];
  @Input() protectedAreas: UniModel[];
  @Input() deliquencyTypes: UniModel[];
  @Input() tableConfig: TableConfig;
  allColumns: string[] = ['personalId', 'firstName', 'lastName', 'date', 'protectedArea', 'deliquencyType', 'ranger'];
  mdColumns: string[] = ['firstName', 'lastName', 'date', 'protectedArea', 'deliquencyType', 'ranger'];
  smColumns: string[] = ['firstName', 'lastName', 'date', 'protectedArea'];
  displayedColumns: string[] = this.allColumns;
  dataSource: MatTableDataSource<TableDataRecord>;
  public innerWidth: any;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.setTableColumns();
  }

  private mapRecordToTableData(record: Record, pAreas: UniModel[], dTypes: UniModel[]): TableDataRecord {
    let deliquencyType = dTypes.find((val: UniModel) => { return val.id == record.deliquencyType});
    return {
      id: record.id,
      personalId: record.person.personalId,
      firstName: record.person.firstName,
      lastName: record.person.lastName,
      date: this.datePipe.transform(record.date, 'd.M.y o H:mm'),
      protectedArea: pAreas.find((val: UniModel) => { return val.id == record.protectedArea}).name, 
      ranger: record.ranger.name + ' ' + record.ranger.surname,
      deliquencyType: deliquencyType == null ? 'N/A' : deliquencyType.name, 
    }
  }
 
  constructor(private ref: ChangeDetectorRef, private datePipe: DatePipe) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.records && this.protectedAreas && this.deliquencyTypes) {
      const records = this.records.map(record => this.mapRecordToTableData(record, this.protectedAreas, this.deliquencyTypes));
      this.dataSource = new MatTableDataSource(records);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'date': 
            const date: Date = moment(item.date, 'D.M.YYYY o HH:mm').toDate();
            return date;
          default: return item[property];
        }
      };
      this.dataSource.filterPredicate = (data: TableDataRecord, filter: string) => {
        const dataArray = removeDiacritics(`${data.firstName} ${data.lastName} ${data.personalId} ${data.date} ${data.deliquencyType} ${data.protectedArea} ${data.ranger}`.trim().toLowerCase());
        const res = dataArray.indexOf(filter);
        return res > -1;
      }

      this.innerWidth = window.innerWidth;
      this.setTableColumns();
      this.applyFilter(this.tableConfig.filterCriteria);
      this.paginator.pageIndex = this.tableConfig.pageIndex;
      this.ref.detectChanges();
    }
  }

  ngOnInit() {
    
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = removeDiacritics(filterValue.trim().toLowerCase());

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private setTableColumns() {
    switch (true) {
      case this.innerWidth > 1200:
        this.displayedColumns = this.allColumns;
        break;

      case this.innerWidth > 800:
        this.displayedColumns = this.mdColumns;
        break;

      case this.innerWidth < 800:
        this.displayedColumns = this.smColumns;
        break;
    
      default:
        break;
    }
  }

}
