<div class="wrapper">
<mat-checkbox *ngIf="showCheckbox" style="padding-right: 20px;" [ngModel]="checked" (ngModelChange)="onCheckedChange($event)"></mat-checkbox>
<mat-expansion-panel (opened)="onOpen()" class="parent" [ngClass]="getClasses()">
  <mat-expansion-panel-header class="info-wrapper">
      <!-- <div class="info-wrapper"> -->
          <app-app-icon [disabled]="!user.activated" [clickable]="false" [name]="user.activated ? 'verified_user' : 'person'" class="badge-icon"></app-app-icon>
          <div class="name-content">
            <h3 [ngClass]="{'disabled-name': !user.activated}" [style.marginBottom.px]="0" [style.marginTop.px]="0">{{ user.surname }} {{ user.name }}</h3>
            <p [ngClass]="{'disabled-name': !user.activated}" [style.marginBottom.px]="0" [style.marginTop.px]="0">{{ user.email }}<span *ngIf="hasUserAdminPermission"> | {{ user.id }}</span></p>
          </div>
          <div class="icons mobile-hidden">
            <div *ngIf="hasUserAdminPermission" class="admin-icons">
    
              <div class="spiner-wrapper" *ngIf="loadingActivation">
                <app-app-spinner size="30"></app-app-spinner>
              </div>
              <app-app-icon *ngIf="!loadingActivation" stopPropagation="true" (clickEvent)="onActivationIconClick()" [tooltip]="activationToolTipText" [name]="user.activated ? 'block' : 'check'"></app-app-icon>
              
              <div class="spiner-wrapper" *ngIf="loadingPermission">
                <app-app-spinner size="30"></app-app-spinner>
              </div>
              <app-app-icon *ngIf="!loadingPermission" stopPropagation="true" [disabled]="!user.activated" (clickEvent)="onManagePermissionClick()" tooltip="Spravovať oprávnenie" name="https"></app-app-icon>
            
            </div>
            <app-app-icon *ngIf="hasUserOfficerPermission || hasUserAdminPermission" linkTo="/manage-users/visits/{{user.id}}" tooltip="Prezerať návštevy územia" name="nature_people"></app-app-icon>
          </div>
        <!-- </div> -->

  </mat-expansion-panel-header>

  <ng-container *ngIf="isDetailLoaded !== false">

    <div class="icons not-mobile-hidden">
      <p><span class="bold">Akcie:</span></p>
      <div *ngIf="hasUserAdminPermission" class="admin-icons">

        <div class="spiner-wrapper" *ngIf="loadingActivation">
          <app-app-spinner size="30"></app-app-spinner>
        </div>
        <app-app-icon *ngIf="!loadingActivation" stopPropagation="true" (clickEvent)="onActivationIconClick()" [tooltip]="activationToolTipText" [name]="user.activated ? 'block' : 'check'"></app-app-icon>
        
        <div class="spiner-wrapper" *ngIf="loadingPermission">
          <app-app-spinner size="30"></app-app-spinner>
        </div>
        <app-app-icon *ngIf="!loadingPermission" stopPropagation="true" [disabled]="!user.activated" (clickEvent)="onManagePermissionClick()" tooltip="Spravovať oprávnenie" name="https"></app-app-icon>
      
      </div>
      <app-app-icon *ngIf="hasUserOfficerPermission || hasUserAdminPermission" linkTo="/manage-users/visits/{{user.id}}" tooltip="Prezerať návštevy územia" name="nature_people"></app-app-icon>
    </div>

   
    <p><span class="bold">Meno a priezvisko:</span> {{ user.name }} {{ user.surname }}</p>
    <p><span class="bold">Email:</span> {{ user.email }}</p>
    <p><span class="bold">Telefón:</span> {{ user.mobile }}</p>

    <ng-container *ngIf="isRanger">
        <br />
        <p><span class="bold">Ulica:</span> {{ user.street }}</p>
        <p><span class="bold">Obec:</span> {{ user.city }}</p>
        <p><span class="bold">PSČ:</span> {{ user.zip }}</p>
    
        <br />
        <p><span class="bold">Číslo preukazu:</span> {{ user.document_number }}</p> 

        <ng-container *ngIf="!isEditMode">
          <p><span class="bold">Domovská správa:</span> {{ user.managed_by | recordEnumsPipe: 'pams' | async }}
            <ng-conatiner *ngIf="hasUserAdminPermission || hasUserAuthAmbasPermission">
              <app-app-icon tooltip="Upraviť domovskú správu" name="edit" (clickEvent)="onEditManagedBy()" [disabled]="isEditMode"></app-app-icon>
            </ng-conatiner>
          </p>
        </ng-container>
        
        <ng-container *ngIf="isEditMode">
          <app-smart-autocomplete (changeEvent)="onPamChange($event)" [allOptions]="autoCompletePams" placeholder="Domovská správa" class="full-width"></app-smart-autocomplete>
          <div class="btn-wrapper">
            <button mat-raised-button color="warn" (click)="isEditMode = false" class="dashboard-btn"><i
            class="material-icons app-btn-icon">cancel</i>Zrušiť</button>
            <button mat-raised-button color="primary" (click)="onSubmitEditClick()" class="dashboard-btn" [disabled]="!selectedPam"><i
            class="material-icons app-btn-icon">save</i>Uložiť</button>
          </div>
          <app-app-spinner *ngIf="isLoadingEditManagedBy"></app-app-spinner>
        </ng-container>

        <hr />
        <div class="territory-wrapper">
          <app-territory-districts [canEdit]="canEditTerritory" (submitNewDistricts)="onSubmitNewDistricts($event)" [allDistricts]="allDistricts" [userDistricts]="user.territory_disctricts" [isLoading]="isLoadingUpdateDistricts"></app-territory-districts>
          <app-app-spinner *ngIf="isLoadingUpdateDistricts"></app-app-spinner>
        </div>
        
        <hr />
    </ng-container>
  

    <ng-container *ngIf="hasUserAdminPermission">
      <br />
      <p id="verified-by"><span class="bold">Schválené používateľom:</span> {{ user.verified_by?.name }} {{ user.verified_by?.surname }}</p>
      <p class="bold">Oprávnenia používateľa:</p>

      <div *ngFor="let perm of userPermissions ">
        <p 
          style="padding-left: 15px; margin-block-start: 0px; margin-block-end: 0px;"
        >{{ perm.name }}</p>
      </div>
    </ng-container>

  </ng-container>

  <app-app-spinner *ngIf="isDetailLoaded === false"></app-app-spinner>

</mat-expansion-panel>
</div>