import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { Record, Visit } from '../../types/types';
import { HistoryService, RecordDetailOrigin } from '../../services/history.service';
import { sortVisits, sortRecords } from '../../utils/utils';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-protected-area-detail-page',
  templateUrl: './protected-area-detail-page.component.html',
  styleUrls: ['./protected-area-detail-page.component.css']
})
export class ProtectedAreaDetailPageComponent implements OnInit {
  isLoadingData = false;
  protectedArea;
  records: Record[];
  visits: Visit[]
  constructor(
    private route: ActivatedRoute,
    private hs: HistoryService,
    private router: Router,
    @Inject(IDataserviceToken) private dataService: IDataService
  ) { }

  ngOnInit() {
    this.isLoadingData = true;
    const id = this.route.snapshot.params['id'] || null;
    this.hs.protectedAreaDetailId = id;

    let pAreaPromise =  this.dataService.getProtectedAreaById(id);
    let recordsPromise = this.dataService.getRecordsForProtectedArea(id);
    let visitsPromise = this.dataService.getVisitsForProtectedArea(id);

    Promise.all([pAreaPromise, recordsPromise, visitsPromise]).then((result) => {
      this.protectedArea = result[0];
      this.records = sortRecords(result[1]);
      this.visits = sortVisits(result[2].map((visit) => { visit.records=[]; return visit;}));
      this.isLoadingData = false;
    });

  }

  onRecordTileClick(recordId) {
    this.hs.recordDetailOrigin = RecordDetailOrigin.ProtectedAreaDetailPage;
    this.router.navigate(['records/' + recordId]);
  }

}
