import { LogType } from "../services/logging.service";
import { SafeHtml } from "../../../node_modules/@angular/platform-browser";

export const BLOCK_FINE_ID = "2";
export const VEHICLE_DELIQUENCY_IDs = [1, 4, 28, 68];

export class User {
  id: string;
  name: string;
  surname: string;
  token: Token;
  email: string;
  mobile: string;
  authorization_rank: string;
  street: string;
  city: string;
  zip: string;
  document_number: string;
  activated: boolean;
  verified_by: SafeRangerInfo;
  territory_disctricts: UniModel[];
  managed_by: string;
  coordinators_parent_p_a_organ: PAManagingOrgan[];
  constructor() {
    this.token = new Token();
    this.territory_disctricts = [];
  }
}

export class Token {
  token: string;
  expirationDate: Date;
}

export interface PAManagingOrgan {
  id: string;
  name: string;
  city: string;
  street: string;
  zip: string;
}

export interface CoordinatorWithPAMs {
  id: string;
  name: string;
  surname: string;
  email: string;
  coordinators_parent_p_a_organ: PAManagingOrgan[];
}

export interface CarInfo {
  licenceNumber: string;
  vehicleType: string;
  color: string;
}

export function createCarInfoModel(): CarInfo {
  return {
    licenceNumber: '',
    vehicleType: null,
    color: ''
  };
}

export interface SafeRangerInfo {
  id: string;
  name: string; 
  surname: string;
  managed_by?: string;
}

export interface PendingRegistration {
  id: string;
  name: string;
  surname: string;
  email: string;
  mobile: string;
  street: string;
  city: string;
  zip: string;
  document_number: string;
  authority_ambassador?: SafeRangerInfo;
  verification_result?: boolean;
  territory_disctricts?: UniModel[];
}

export interface SentUserInvitation {
  id: string;
  email: string;
  dateSent: Date;
  validHoursRemaining: number;
  sentBy: string;
}

export interface Visit {
  id: string;
  date: Date;
  protectedArea: string;
  district: string;
  description: SafeHtml;
  guardId?: string;
  visitIds: string[];
  recordIds?: string[];
  userIds: string[];
  rangers?: {ranger: SafeRangerInfo, description: string, visitId: string}[];
  records?: Record[];
}

export interface Guard {
  id: string;
  date: Date;
  protectedArea: string;
  district: string;
}

export interface ProtectedAreaTile {
  id: string;
  name: string;
  district: string;
  isLarge: boolean;
  validFrom: Date;
  validTo: Date;
}

export class Record {
  id: string;
  person: Person;
  date: Date;
  region: string;
  district: string;
  protectedArea: string;
  protectionLevel: number;
  deliquencyType: string;
  description: string;
  solvingWay: string;
  sanctionAmount: number;
  carInfo?: CarInfo;
  feeTicketNumber?: string;
  ranger: SafeRangerInfo;

  constructor() {
    this.person = new Person();
    this.date = null;
    this.region = '';
    this.district = '';
    this.protectedArea = '';
    this.protectionLevel = 0;
    this.deliquencyType = '';
    this.description = '';
    this.solvingWay = '';
    this.sanctionAmount = 0;
    this.ranger = { name: '', surname: '', id: ''};
  }
}

export class Person {
  id: string;
  personalId: string;
  birthDate: Date;
  gender: SEX;
  firstName: string;
  lastName: string;
  nationality: string;
  idCardType: string;
  idCardNumber: string;
  address: Address;

  constructor() {
    this.personalId = '';
    this.birthDate = null;
    this.gender = null;
    this.firstName = '';
    this.lastName = '';
    this.nationality = '';
    this.idCardType = null;
    this.idCardNumber = '';
    this.address = new Address();
  }
}

export class Address {
  street: string;
  city: string;
  zip?: string;
  country: string;

  constructor() {
    this.street = '';
    this.city = '';
    this.zip = '';
    this.country = '';
  }
}

export enum SEX {
  MALE = 1,
  FEMALE = 2
}

export interface UniModel {
  id: string;
  name: string;
}

export class CreateRecordEnums {
  documentTypes: UniModel[];
  regionOptions: UniModel[];
  districtOptions: UniModel[];
  protectedAreaOptions: UniModel[];
  protectionLevelOptions: UniModel[];
  deliquencyTypeOptions: UniModel[];
  solvingWayOptions: UniModel[];

  constructor () {
    this.documentTypes = [];
    this.regionOptions = [];
    this.districtOptions = [];
    this.protectedAreaOptions = [];
    this.protectionLevelOptions = [];
    this.deliquencyTypeOptions = [];
    this.solvingWayOptions = [];
  }
}

export interface LogMessage {
  type: LogType;
  message: string;
  data?: string;
  created_at: Date;
  user_id?: Number;
  user?: any;
}

export interface ValueInTime {
  valueName: string;
  occurences: string[];
}

export class EnumGenericType {
  id: string;
  protectionLevel: string;
  name: string;
  shortcut: string;

  description: string;
  lawPartBonded: string;
  lawPartOffense: string;
  validFromIncl: Date;
  validUntilIncl: Date;

  regionId: string;
  managingOrganId: string;

  districtId: string;
  protectedAreaId: string;
  paManagingOrganId: string;

  documentType: string;

  city: string;
  street: string;
  zip: string;

  insideId: string;
  protectedAreaTypeId: string;
  protectiveZone: string;

  code: string;
  isLarge: string;
}