<app-back-button></app-back-button>

<mat-card>
    <mat-card-content>
        <h1>Správa koordinátorov</h1>
       
        <button mat-raised-button color="primary" (click)="isAddingNew = true" class="dashboard-btn" [disabled]="isLoadingData"><i
          class="material-icons app-btn-icon">add</i>Pridať</button>
        <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
        <div *ngIf="isAddingNew" class="new-coord-parent">
          <app-smart-autocomplete (changeEvent)="onUserChange($event)" [allOptions]="autocompleteOfficers" placeholder="Nový koordinátor"></app-smart-autocomplete>
          <div class="btn-wrapper">
            <button mat-raised-button color="warn" (click)="onCancelNewClick()" class="dashboard-btn"><i
            class="material-icons app-btn-icon">cancel</i>Zrušiť</button>
            <button mat-raised-button color="primary" (click)="onAddNewClick()" class="dashboard-btn"><i
            class="material-icons app-btn-icon">save</i>Uložiť</button>
          </div>
          
        </div>
        <div *ngIf="!isLoadingData">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let user of allCoordinators" (opened)="onOpen(user)" class="parent" [expanded]="shouldExpand[user.id]"
                  [style.background]="user.coordinators_parent_p_a_organ.length == 0 ? 'lightblue' : 'none'">
                    <mat-expansion-panel-header class="info-wrapper">
                        <!-- <div class="info-wrapper"> -->
                            <app-app-icon [clickable]="false" name="person" class="badge-icon"></app-app-icon>
                            <div class="name-content">
                            <h3 [style.marginBottom.px]="0" [style.marginTop.px]="0">{{ user.surname }} {{ user.name }}</h3>
                            <p  [style.marginBottom.px]="0" [style.marginTop.px]="0">{{ user.email }}</p>
                            </div>
                    </mat-expansion-panel-header>
                    <app-app-icon tooltip="Upraviť správy" name="edit" (clickEvent)="onEditIconClick(user)" [disabled]="isEditMode"></app-app-icon>
                    
                    <ng-container *ngIf="!isEditMode && !isLoading">
                      <mat-chip-list>
                          <mat-chip *ngFor="let pam of user.coordinators_parent_p_a_organ">{{ pam.name }}</mat-chip>
                      </mat-chip-list>
                    </ng-container>
                    <ng-container *ngIf="isEditMode || isLoading">
                      <mat-form-field class="chip-list" appearance="fill">
                        <mat-label>Priradené správy</mat-label>
                        <mat-chip-list #chipList aria-label="Výber správ" [disabled]="isLoading">
                          <mat-chip *ngFor="let pam of selectedPams; let i=index;" [selectable]="selectable" [removable]="true"
                            (removed)="remove(pam, i)">
                            {{pam.name}}
                            <button matChipRemove>
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip>
                          <input placeholder="Nová správa..." #pamInput [formControl]="pamCtrl" [matAutocomplete]="auto"
                            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                          <mat-option *ngFor="let pam of filteredPams | async" [value]="pam">
                            {{pam.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </ng-container>

                    <div *ngIf="isEditMode" class="btn-wrapper">
                      <button mat-raised-button color="warn" (click)="onCancel(user)" class="dashboard-btn"><i
                        class="material-icons app-btn-icon">cancel</i>Zrušiť</button>
                      <button mat-raised-button color="primary" (click)="onSubmit(user)" class="dashboard-btn"><i
                          class="material-icons app-btn-icon">save</i>Uložiť</button>
                    </div>
                    <app-app-spinner *ngIf="isLoading"></app-app-spinner>
                   
                    <!-- <mat-chip-list>
                        <mat-chip *ngFor="let pam of user.coordinators_parent_p_a_organ">{{ pam.name }}</mat-chip>
                    </mat-chip-list> -->
                    
                    <!-- <app-app-spinner *ngIf="isLoadingDetail"></app-app-spinner>
                    <div *ngIf="!isLoadingDetail">

                    </div> -->
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-card-content>
</mat-card>

  