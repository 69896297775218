<mat-form-field class="full-width">
  <mat-select [placeholder]="placeholder" [formControl]="control" (selectionChange)="onSelectionChange($event)">
    <mat-option>Vyber</mat-option>
    <mat-option *ngFor="let option of options" [value]="option.id"><span class="bold">{{ option.name }}</span> {{ option.law_part_bonded }}</mat-option>
  </mat-select>
  <mat-error *ngIf="shouldShowErrors(control)">
    <mat-error *ngFor="let error of listOfErrors(control)">
      {{error}}
    </mat-error>
  </mat-error>
</mat-form-field>
