import { Injectable, Injector } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class AppInitializerService {

  constructor(private injector: Injector) { }

  load() {
    return this.configService.load();
    // return new Promise((resolve, reject) => {
    //   resolve(true);
    // })
  }

  private get configService(): ConfigService {
    return this.injector.get(ConfigService);
  } 

}
