import { Component, OnInit, Input, Inject } from '@angular/core';
import { AbstractControl, Validators, FormGroup } from '@angular/forms';
import { CreateRecordEnums, UniModel, BLOCK_FINE_ID, VEHICLE_DELIQUENCY_IDs } from '../../../types/types';
import { TransformTypes } from '../../inputs/smart-input/smart-input.component';
import { IDataService, IDataserviceToken } from '../../../services/i-data-service';

@Component({
  selector: 'app-record-form',
  templateUrl: './record-form.component.html',
  styleUrls: ['./record-form.component.css']
})
export class RecordFormComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;

  enums = new CreateRecordEnums();
  transformTypes = TransformTypes;
  vehicleTypeOptions: UniModel[]; 

  get shouldShowFeeTicketNumberInput() {
    return (this.parentFormGroup.get('sanctionAmount').value != null
      && this.parentFormGroup.get('sanctionAmount').value != ''
      && this.parentFormGroup.get('solvingWay').value == BLOCK_FINE_ID);
  }

  get shouldShowVehicleForm() {
    return VEHICLE_DELIQUENCY_IDs.indexOf(this.parentFormGroup.get('deliquencyType').value) > -1;
  }

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
    this.disableSelects();
    this.dataService.getRegionOptions(this.dataService.user.id).then((regionOptions: UniModel[]) => {
      this.enums.regionOptions = regionOptions;
    });

    this.dataService.getSolvingWayOptions().then((solvingOptions: UniModel[]) => {
      this.enums.solvingWayOptions = solvingOptions;
    });

    this.dataService.getVehicleTypeOptions().then((vehicleTypes: UniModel[]) => {
      this.vehicleTypeOptions = vehicleTypes;
    });

    this.parentFormGroup.get('region').valueChanges.subscribe(() => {
      this.handleDependentControl('region', 'district');
      this.handleDependentControl('district', 'protectedArea');
      this.handleDependentControl('protectedArea', 'protectionLevel');
      this.handleDependentControl('protectionLevel', 'deliquencyType');
    });
    this.parentFormGroup.get('district').valueChanges.subscribe(() => {
      this.handleDependentControl('district', 'protectedArea');
      this.handleDependentControl('protectedArea', 'protectionLevel');
      this.handleDependentControl('protectionLevel', 'deliquencyType');
    });
    this.parentFormGroup.get('protectedArea').valueChanges.subscribe(() => {
      this.handleDependentControl('protectedArea', 'protectionLevel');
      this.handleDependentControl('protectionLevel', 'deliquencyType');
    });
    this.parentFormGroup.get('protectionLevel').valueChanges.subscribe(() => {
      this.handleDependentControl('protectionLevel', 'deliquencyType');
    });

    this.parentFormGroup.get('sanctionAmount').valueChanges.subscribe(() => {
      this.handleFeeTicketNumberInput();
    });

    this.parentFormGroup.get('solvingWay').valueChanges.subscribe(() => {
      this.handleSanctionAmountInput();
      this.handleFeeTicketNumberInput();
    });

    this.parentFormGroup.get('deliquencyType').valueChanges.subscribe(() => {
      this.handleVehicleForm();
    });

    this.parentFormGroup.get('date').valueChanges.subscribe(() => {
      this.handleDependentControl('date', 'protectedArea');
      this.handleDependentControl('protectedArea', 'protectionLevel');
      this.handleDependentControl('protectionLevel', 'deliquencyType');
    });
  }

  private handleDependentControl(masterControlName: string, dependentControlName: string) { // && this.parentFormGroup.get(dependentControlName).disabled
    if (this.parentFormGroup.get(masterControlName).value) {
      this.parentFormGroup.get(dependentControlName).enable({emitEvent:false});
      this.parentFormGroup.get(dependentControlName).setValue(null, {emitEvent:false})
      this.getFilteredOptionsForControl(dependentControlName);
    } else if (!this.parentFormGroup.get(masterControlName).value) { // && this.parentFormGroup.get(dependentControlName).enabled
      this.parentFormGroup.get(dependentControlName).disable({emitEvent:false});
      this.parentFormGroup.get(dependentControlName).setValue(null, {emitEvent:false});
    }
  }

  private getFilteredOptionsForControl(controlName: string) {
    switch (controlName) {
      case 'district':
        this.dataService.getDistrictOptions(this.parentFormGroup.get('region').value, this.dataService.user.id).then((districtOptions: UniModel[]) => {
          this.enums.districtOptions = districtOptions;
        });
        break;

      case 'protectedArea':
        this.dataService.getProtectedAreaOptions(this.parentFormGroup.get('district').value, this.parentFormGroup.get('date').value).then((protectedAreaOptions: UniModel[]) => {
          this.enums.protectedAreaOptions = protectedAreaOptions;
        });
        break;

      case 'protectionLevel':
        this.dataService.getProtectionLevelOptions(this.parentFormGroup.get('protectedArea').value).then((protectionLevelOptions: UniModel[]) => {
          this.enums.protectionLevelOptions = protectionLevelOptions;
        });
        break;

      case 'deliquencyType':
        this.dataService.getDeliquencyTypelOptions(this.parentFormGroup.get('protectionLevel').value, new Date()).then((deliquencyTypeOptions: UniModel[]) => {
          this.enums.deliquencyTypeOptions = deliquencyTypeOptions;
        });
        break;
    }
  }

  private handleFeeTicketNumberInput() {
    const feeTicketNumberControl = this.parentFormGroup.get('feeTicketNumber');
    if (!this.shouldShowFeeTicketNumberInput) {
      feeTicketNumberControl.setValidators(null);
    } else {
      feeTicketNumberControl.setValidators(Validators.required);
    }
    feeTicketNumberControl.updateValueAndValidity();
  }

  private handleSanctionAmountInput() {
    const sanctionAmountControl = this.parentFormGroup.get('sanctionAmount');
    if (this.parentFormGroup.get('solvingWay').value != BLOCK_FINE_ID ) {
      sanctionAmountControl.setValue(0);
      sanctionAmountControl.disable();
    } else {
      sanctionAmountControl.setValue(null);
      sanctionAmountControl.enable();
    }
    sanctionAmountControl.updateValueAndValidity();
  }

  private handleVehicleForm() {
    const vehicleForm =  this.parentFormGroup.get('vehicleForm');
    const vehicleTypeControl = vehicleForm.get('vehicleType');
    const licenceNumberControl = vehicleForm.get('licenceNumber');
    if (!this.shouldShowVehicleForm) {
      vehicleTypeControl.setValidators(null);
      vehicleTypeControl.setErrors(null);
      licenceNumberControl.setValidators(null);
      licenceNumberControl.setErrors(null);
    } else {
      vehicleTypeControl.setValidators(Validators.required);
      licenceNumberControl.setValidators(Validators.required);
    }
    vehicleForm.updateValueAndValidity();
    this.parentFormGroup.updateValueAndValidity();
  }

  private disableSelects() {
    this.parentFormGroup.get('district').disable();
    this.parentFormGroup.get('protectedArea').disable();
    this.parentFormGroup.get('protectionLevel').disable();
    this.parentFormGroup.get('deliquencyType').disable();
  }

}
