
import {interval as observableInterval} from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { MessageService, MessageType } from './message.service';

import { Observable } from '../../../node_modules/rxjs';
import { IDataService, IDataserviceToken } from './i-data-service';

@Injectable()
export class AuthGuard implements CanActivate {
  offlineMessageId: number;
  offlineSub;
  constructor(
    private router: Router, 
    private ps: PermissionsService, 
    private ms: MessageService,
    @Inject(IDataserviceToken) private dataService: IDataService  
  ) {
    this.offlineSub = observableInterval(60000)
      .subscribe((val) => { 
        this.offlineCheck();
        console.debug('offlinecheck'); 
      });
    // this.offlineSub.unsubscribe();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.dataService.isUserLoggedIn()) {
      this.offlineCheck();
      return true;
    } else { 
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }})
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.canActivate(route, state) == false) {
      return false;
    }
    const permNeeded = route.data.permNeeded; 
    if (permNeeded != null) {
      if (!this.ps.hasUserPermission(permNeeded)) {
        this.redirectToUnauthorized();
        return false;
      }
    }

    const oneOfPermNeeded = route.data.oneOfPermNeeded; 
    if (oneOfPermNeeded != null) {
      if (!this.ps.hasUserOneOfFollowingPermissions(oneOfPermNeeded)) {
        this.redirectToUnauthorized();
        return false;
      }
    }

    return true;
  }

  private redirectToUnauthorized() {
    this.router.navigate(['/unauthorized']);
  }

  private offlineCheck() {
    this.dataService.doOfflineCheck().then((result) => {
      this.dataService.isOffline = result;
      console.debug(this.dataService.isOffline ? 'offline' : 'online');
      if (this.dataService.isOffline) {
        this.offlineMessageId = this.ms.replaceFirstMessage('Pracujete offline.', MessageType.INFO);
      } else {
        this.ms.removeMessage(this.offlineMessageId);
      }
    }, (err) => {
      console.debug('offline');
    })
  }
}