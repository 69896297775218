<mat-card>
  <mat-card-content [hidden]="!dataSource">

    <div class="example-header">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="tableConfig.filterCriteria" placeholder="Filter">
      </mat-form-field>
      <button mat-raised-button color="primary" style="float: right;" (click)="onCreateEnumDataClick()" *ngIf="dataSource">Vytvoriť záznam</button>
    </div>

    <div class="example-container mat-elevation-z8">

      <mat-table [dataSource]="dataSource" matSort>

        <ng-container *ngFor="let column of allColumns" [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row[column]}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header> action </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <app-app-icon name="edit" tooltip="Upraviť záznam" (clickEvent)="onEditEnumDataClick(row)"></app-app-icon>
            <app-app-icon name="delete" tooltip="Vymazať záznam" (clickEvent)="onDeleteEnumDataClick(row)"></app-app-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="table-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>

      <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageIndex]="tableConfig.pageIndex"></mat-paginator>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="!dataSource">
    <app-app-spinner></app-app-spinner>
  </mat-card-content>

</mat-card>