import { Component, OnInit, Inject } from '@angular/core';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  public get userName() { return this.dataService.user.name; }
  public get isLoggedIn() { return this.dataService.isUserLoggedIn(); }

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
  }
  
  logOutClick() {
    this.dataService.doLogout();
  }

}
