import { Component, OnInit, Input, SimpleChanges, OnChanges, Inject } from '@angular/core';
import { Visit, Record } from '../../types/types';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.css']
})
export class VisitComponent implements OnInit {
  @Input() visit: Visit
  @Input() officerWatching = false;
  records: Record;
  loadingRecords = false;
  officerLoadedRecords = false;
  constructor(@Inject(IDataserviceToken) private dataService: IDataService) { }

  ngOnInit() {
  }

  onLoadRecordsClick() {
    this.loadingRecords = true;
    this.dataService.getRecordsForVisit(this.visit.visitIds).then((records) => {
      this.visit.records = records;
      this.loadingRecords = false;
      this.officerLoadedRecords = true;
    });
  }

}
