import { Component, OnInit, Input } from '@angular/core';
import { Person, Record } from '../../types/types';

@Component({
  selector: 'app-check-result',
  templateUrl: './check-result.component.html',
  styleUrls: ['./check-result.component.css']
})
export class CheckResultComponent implements OnInit {
  @Input() record: Record;

  constructor() { }

  ngOnInit() {
  }

}
