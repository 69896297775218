<mat-form-field class="full-width">
  <input id="dateInput" matInput [matDatepicker]="picker" [placeholder]="'Dátum ' + placeholderSufix" (dateInput)="onDateInputChange()" (dateChange)="onDateChange()" (click)="picker.open()" [formControl]="dateControl" autocomplete="off">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker touchUi="true"></mat-datepicker>
  <mat-error *ngIf="shouldShowErrors(dateControl)">
      <mat-error *ngFor="let error of listOfErrors(dateControl)">
          {{error}}
      </mat-error>
  </mat-error>
</mat-form-field>

<mat-form-field class="full-width">
  <input id="timeInput" [formControl]="timeControl" [value]="nowTime" (ngModelChange)="onTimeChange($event)" matInput atp-time-picker [placeholder]="'Čas ' + placeholderSufix" name="timeInput" required/>
  <mat-error *ngIf="shouldShowErrors(timeControl)">
    <mat-error *ngFor="let error of listOfErrors(timeControl)">
        {{error}}
    </mat-error>
  </mat-error>
</mat-form-field>