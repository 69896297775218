import { Component, OnInit, Input } from '@angular/core';
import { Record } from '../../types/types';

@Component({
  selector: 'app-record-list-tile',
  templateUrl: './record-list-tile.component.html',
  styleUrls: ['./record-list-tile.component.css']
})
export class RecordListTileComponent implements OnInit {
  @Input() record: Record;
  constructor() { }

  ngOnInit() {
  }

}
