import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '../../../../node_modules/@angular/forms';
import { MessageService, MessageType } from '../../services/message.service';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { ILoggingServiceToken, ILoggingService } from '../../services/i-logging-service';

@Component({
  selector: 'app-create-password-page',
  templateUrl: './create-password-page.component.html',
  styleUrls: ['./create-password-page.component.css']
})
export class CreatePasswordPageComponent implements OnInit {
  form: FormGroup = new FormGroup({
    password: new FormControl(null, Validators.required),
    passwordRepeat: new FormControl(null, Validators.required)
  });

  isLoadingData = false;
  isSuccess;
  email;
  token;
  isTokenValid;
  get shouldDisableButton() { return this.form.invalid || this.isLoadingData || this.isSuccess;}

  constructor(
     private messageService: MessageService, 
     private activatedRoute: ActivatedRoute,
     private router: Router,
     @Inject (ILoggingServiceToken) private ls: ILoggingService,
     @Inject(IDataserviceToken) private dataService: IDataService
    ) { }

  ngOnInit() {
    const msg = 'Nie je možné vytvoriť nové heslo, kontaktujte administrátora.';
    this.token = this.activatedRoute.snapshot.queryParams['token'] || null;
    this.dataService.getEmailForResetPasswordToken(this.token).then(
      (response) => {
        this.isTokenValid = response.success;
        if (!response.success) {
          this.ls.logError(msg, response.email);
          this.messageService.addMessage('Server responded with false - ' + msg, MessageType.ERROR);
        }
        this.email = response.email;
      },
      (error) => {
        this.isTokenValid = false;
        this.ls.logError(msg, error);
        this.messageService.addMessage(msg, MessageType.ERROR);
      }
    )
  }

  onClick() {
    this.isLoadingData = true;
    this.dataService.sendNewPasswords(this.token, this.form.controls['password'].value, this.form.controls['passwordRepeat'].value).then(
      (successResponse) => {
        this.isSuccess = successResponse.success;
        this.isLoadingData = false;
        if (successResponse.success == true) {
          const msg = `Heslo bolo úspešne zmenené, môžete sa prihlásiť.`;
          this.messageService.addMessage(msg, MessageType.SUCCESS);
          this.router.navigate(['login']);
        } else {
          this.ls.logError(successResponse.message);
          this.messageService.addMessage(successResponse.message, MessageType.ERROR);
        }
        
      },
      (error) => {
        this.isSuccess = false;
        this.isLoadingData = false;
        const msg = 'Nepodarilo sa odoslať link, skúste to, prosím, neskôr.';
        this.ls.logError(msg, error);
        this.messageService.addMessage(msg, MessageType.ERROR)   
      }
    )
  }

}
