<mat-card>
    <mat-card-content>
      <app-app-spinner *ngIf="isTokenValid == null"></app-app-spinner>
      <div *ngIf="isTokenValid == true">
        <h2>Vytvorenie hesla pre {{ email }}</h2>
        <div [formGroup]="form">
            <app-smart-input [control]="form.controls.password" type="password" placeholder="Nové heslo" name="password"></app-smart-input>
            <app-smart-input [control]="form.controls.passwordRepeat" type="password" placeholder="Znovu heslo" name="password-repeat"></app-smart-input>
        </div>
        <div class="col-4 col-sm-6 col-xs-12 submit-btn-wrapper">
          <button mat-raised-button color="primary" (click)="onClick()" [disabled]="shouldDisableButton" class="full-width"><i class="material-icons app-btn-icon">{{ isLoadingData === true ? 'schedule' : 'send' }}</i>{{ isLoadingData === true ? 'Odosiela sa...' : 'Obnoviť heslo' }}</button>
          <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
        </div>
      </div>
      <div *ngIf="isTokenValid == false">
        <h3>Aktivačný link zrejme vypršal</h3>
      </div>
    </mat-card-content>
</mat-card>