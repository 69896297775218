import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransformTypes } from '../../inputs/smart-input/smart-input.component';

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.css']
})
export class CreateUserFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() isOnlyOfficer: boolean;

  
  transformTypes = TransformTypes;

  constructor() { }

  ngOnInit(): void {

  }

  static createForm(fb: FormBuilder, isOnlyOfficer: boolean): FormGroup {
    return fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      mobile: ['', Validators.required],
      street: ['', isOnlyOfficer ? null : Validators.required],
      city: ['', isOnlyOfficer ? null : Validators.required],
      zip: ['', isOnlyOfficer ? null : Validators.required],
      document_number: ['', isOnlyOfficer ? null : Validators.required],
    });
  }

}
