<mat-card>
    <mat-card-content>
      <h2>Obnova hesla</h2>
      <p>Pre obnovu hesla zadajte email.</p>
      <div [formGroup]="form">
          <app-smart-input [control]="form.controls.email" placeholder="Email" [value]="lastLoginName" name="email"></app-smart-input>
      </div>
      <div class="col-4 col-sm-6 col-xs-12 submit-btn-wrapper">
        <button mat-raised-button color="primary" (click)="onClick()" [disabled]="shouldDisableButton" class="full-width"><i class="material-icons app-btn-icon">{{ isLoadingData === true ? 'schedule' : 'send' }}</i>{{ isLoadingData === true ? 'Odosiela sa...' : 'Obnoviť heslo' }}</button>
        <app-app-spinner *ngIf="isLoadingData"></app-app-spinner>
      </div>
    </mat-card-content>
</mat-card>