import { Component, OnInit, Inject } from '@angular/core';
import { PAManagingOrgan, User } from '../../types/types';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { removeDiacritics, isUserManagedByMe } from '../../utils/utils';
import { HistoryService } from '../../services/history.service';
import { IDataService, IDataserviceToken } from '../../services/i-data-service';
import { PermissionsService, Permission } from '../../../app/services/permissions.service';
import { MessageService, MessageType } from '../../services/message.service';

@Component({
  selector: 'app-manage-users-page',
  templateUrl: './manage-users-page.component.html',
  styleUrls: ['./manage-users-page.component.css']
})
export class ManageUsersPageComponent implements OnInit {
  users: User[];
  filteredUsers: User[];
  isLoadingData;
  checkedStates = {};
  allowSelection: boolean = false;
  isChangingParentPams: boolean = false;
  isLoadingMultiPam = false;
  pamsMap = {};
  allPams: PAManagingOrgan[] = [];
  selectedPam: PAManagingOrgan;
  inputControl: FormControl = new FormControl('');
  get isSomeMultiOperationOnGoing() { return this.isChangingParentPams }
  get isSelectionEmpty() { 
    let res = true;
    Object.keys(this.checkedStates).some(userId => {
      if (this.checkedStates[userId] === true) {
        res = false;
        return true;
      }
    });  
    return res;
  }
  get hasUserAdminPermission() { return this.permissionsService.hasUserPermission(Permission.Admin); }
  get hasUserAuthAmbPermission() { return this.permissionsService.hasUserPermission(Permission.AuthorityAmbassador); }
  
  get filterPlaceholder() { 
    return this.hasUserAdminPermission ? 'Meno, priezvisko, domovská správa, email, ID' : 'Meno, priezvisko, email, domovská správa';
  }
  constructor(private historyService: HistoryService, 
    private permissionsService: PermissionsService, 
    private messageService: MessageService,
    @Inject(IDataserviceToken) private dataService: IDataService) { }

  onCheckboxClick(val) {
    this.checkedStates[val.id] = val.newState;
  }

  onMultiClick() {
    this.allowSelection = !this.allowSelection;
    this.inputControl.setValue('');
    if (!this.allowSelection) {
      this.checkedStates = {};
      this._cancelAllMultiOperations();
    }
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.dataService.getUsers().then((users) => {
      this.isLoadingData = false;
      let sortedUsers = users.sort((user1, user2) => {
        if (isUserManagedByMe(user1, this.dataService.user) && !isUserManagedByMe(user2, this.dataService.user)) return -1;
        if (!isUserManagedByMe(user1, this.dataService.user) && isUserManagedByMe(user2, this.dataService.user)) return 1;

        if (!isUserManagedByMe(user1, this.dataService.user) && user2.activated == false) return -1;
        if (!isUserManagedByMe(user2, this.dataService.user) && user1.activated == false) return 1;
        
        if (user1.surname > user2.surname) return 1;
        if (user1.surname < user2.surname) return -1;

        if (user1.name > user2.name) return 1;
        if (user1.name < user2.name) return -1;
      
      });
      this.users = sortedUsers;
      this.filteredUsers = sortedUsers;
      this.filteredUsers.forEach(user => this.checkedStates[user.id] = false);
      this.inputControl.setValue(this.historyService.manageUsersFilter);
    });

    this.dataService.getAllPAMOrgans().then((pams) => {
      pams.forEach(pam => this.pamsMap[pam.id] = pam.name);
      this.allPams = pams;
    });
    this.inputControl.valueChanges.subscribe((value) => {
      this.onFilterChange(value);
    });

  }

  onFilterChange(filterValue: string) {
    this.historyService.manageUsersFilter = filterValue;
    if (filterValue == '') {
      this.filteredUsers = this.users;
      return;
    }
    const filter = removeDiacritics(filterValue.toLowerCase());
    this.filteredUsers = this.users.filter(ranger => this.unifyRangerName(ranger).indexOf(filter) > -1);
  }

  onSubmitMultiManagedBy() {
    this.isLoadingMultiPam = true;
    if (this.selectedPam != null) {
      let userIds = [];
      Object.keys(this.checkedStates).forEach(userId => {
        if (this.checkedStates[userId] === true) {
          userIds.push(userId);
        }
      });
      if (userIds.length > 0) {
        this.dataService.updateMultiUserPam(this.selectedPam.id, userIds).then((res) => {
          this.messageService.replaceFirstMessage('Úspešne zmenené.', MessageType.SUCCESS, 0);
          this.isChangingParentPams = false;
          this.isLoadingMultiPam = false;
        });
      }
      
    }
  }

  private unifyRangerName(user: User) {

    let str = `${user.name} ${user.surname} ${user.email} ${this.pamsMap[user.id]}`;
    if (this.hasUserAdminPermission) {
      str = `${str} ${user.id}`;
    }
    const finStr = removeDiacritics(str.toLowerCase());
    return finStr;
  }

  private _cancelAllMultiOperations() {
    this.isChangingParentPams = false;
  }

}
