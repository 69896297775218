import { Pipe, PipeTransform, Inject } from '@angular/core';
import { UniModel } from '../types/types';
import { REGION, DISTRICT, SOLVING_WAY, PROTECTED_AREA, VEHICLE_TYPE, DELIQUENCY_TYPE, ID_CARD_TYPE } from './data.service';
import { IDataService, IDataserviceToken } from './i-data-service';

@Pipe({name: 'recordEnumsPipe'})
export class RecordEnumsPipe implements PipeTransform {

  constructor(@Inject(IDataserviceToken) private dataService: IDataService) { }

  transform(value: string, type: string) {
    let promise: Promise<UniModel[]>;

    switch (type) {
      case REGION:
        promise = this.dataService.getRegionOptions();
        break;

      case DISTRICT:
        promise = this.dataService.getDistrictOptions();
        break;

      case PROTECTED_AREA:
        promise = this.dataService.getProtectedAreaOptions();
        break;

      case DELIQUENCY_TYPE:
        promise = this.dataService.getDeliquencyTypelOptions();
        break;

      case SOLVING_WAY:
        promise = this.dataService.getSolvingWayOptions();
        break;

      case VEHICLE_TYPE:
        promise = this.dataService.getVehicleTypeOptions();
        break;

      case 'gender':
        promise = this.dataService.getGenderOptions();
        break;

      case ID_CARD_TYPE:
        promise = this.dataService.getIdCardTypesOptions();
        break;
      case 'pams':
        promise = this.dataService.getAllPAMOrgans();
        break;
    
      default:
        throw new Error('Unsupported record enum "' + type + '"');
    }

    return promise.then((values: UniModel[]) => {
      const resultValue = values.find((val: UniModel) => { return val.id == value || val.name == value;});
      if (resultValue != null) {
        return resultValue.name;
      } else {
        console.error('No name found for enum type "' + type + '" and id "' + value + '"');
        return '';
      }
    });
  }
}